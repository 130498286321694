import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			staticComponents: { FormLabel, Button, AlertComponent, OTPComponent, Input },
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !ndswidget) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
			'manajemen_transaksi.transaksi_menunggu_approval.transaksiMenungguApprovalMetadata',
		);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;
		const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)session_id\s*\=\s*([^;]*).*$)|^.*$/, '$1');

		function componentFactory(params) {
			class transaksiMenungguApprovalView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(this.dataContext, metadata, initialData, editUIData);
					this.state = {
						isErr: false,
						errMessage: '',
						otp_ref_number: '',
						comment1: '',
						comment2: '',
						isMPN: false,
					};
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
						isMPN: false,
					});

					try {
						var response = await this.appAction.fetchResource(_moduleId, 'method', 'dataSO', this.props._authToken, {
							tx_ref_number: params.tx_ref_number,
						});
						var rsp = response?.data[0];

						this.setState({
							...prevState,
							response: rsp,
							isMPN: rsp.tx_code == 'MPN',
						});
						this.dsMainAction.loadStore(response, 'std', serverDataMapping, true);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							isMPN: false,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents({ context: this.dataContext, dsetPath: 'main' }, vcomps);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					const handleAuthorize = async _ => {
						if (window.confirm('apakah anda yakin akan memproses transaksi ini?')) {
							try {
								var response = await this.appAction.postData(_moduleId, 'authorize', this.props._authToken, {
									tx_ref_number: params.tx_ref_number,
									product_code: this.state?.response?.product_code,
									current_workflow_state: this.state.response.current_workflow_state,
									isProcess: true,
									comment1: this.state.comment1,
									comment2: this.state.comment2,
									session_id: cookieValue,
								});
								if (response.data.require_otp == 'T') {
									await askotp(response.data.otp_ref_number);
									this.setState({
										...this.state,
										otp_ref_number: response.data.otp_ref_number,
									});
								} else {
									if (response) this.props.closeModal(true);
									AlertHandler('Proses Persetujuan berhasil', 'success');
								}
							} catch (e) {
								AlertHandler(e.message, 'error');
							}
						} else this.props.closeModal();
					};

					const handleReject = async _ => {
						if (window.confirm('apakah anda yakin akan menolak transaksi ini?')) {
							try {
								var response = await this.appAction.postData(_moduleId, 'authorize', this.props._authToken, {
									tx_ref_number: params.tx_ref_number,
									product_code: this.state?.response?.product_code,
									isProcess: false,
									comment1: this.state.comment1,
									comment2: this.state.comment2,
								});
								if (response) this.props.closeModal(true);
								AlertHandler('Proses Pembatalan berhasil', 'success');
							} catch (e) {
								AlertHandler(e.message, 'error');
							}
						} else this.props.closeModal();
					};

					const askotp = async notp_ref => {
						var otp = await this.appAction.frameAction.showModalAsync({
							contentClass: OTPComponent,
							contentProps: {
								_moduleId,
								_authToken: this.props._authToken,
								tx_ref_number: params.tx_ref_number,
								otp_ref_number: notp_ref,
								appAction: this.appAction,
							},
							size: 'small',
						});
						if (otp) {
							var { otp, otp_ref_number } = otp;
							if (!otp) return alert('Silakan isikan kode OTP.');
							var resp = await this.appAction.postData(_moduleId, 'authorize', this.props._authToken, {
								tx_ref_number: params.tx_ref_number,
								product_code: this.state?.response?.product_code,
								current_workflow_state: this.state?.response?.current_workflow_state,
								isProcess: true,
								otp,
								otp_ref_number,
							});
							if (resp.isErr) {
								throw new Error(resp.isErr);
							} else if (resp) {
								this.props.closeModal(true);
								AlertHandler('Proses persetujuan berhasil', 'success');
							}
						}
					};

					const Konfirmasi = async process => {
						await this.appAction.frameAction.showModal({
							contentClass: p => {
								return (
									<div>
										<div
											style={{
												paddingTop: '2rem',
												paddingBottom: '2rem',
												fontSize: '1.2rem',
											}}
										>
											{p.message}
										</div>
										<div>
											<FormLabel label="Catatan 1">
												<Input
													onKeyUp={e => {
														this.setState({
															...this.state,
															comment1: e.target.value,
														});
													}}
												/>
											</FormLabel>
											{/* <FormLabel label="Catatan 2">
												<Input
													onKeyUp={e => {
														this.setState({
															...this.state,
															comment2: e.target.value,
														});
													}}
												/>
											</FormLabel> */}
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												marginTop: 30,
											}}
										>
											<Button
												type="bordered"
												style={{
													marginRight: 10,
												}}
												onClick={() => {
													p.closeModal();
												}}
											>
												Batal
											</Button>
											<Button
												onClick={() => {
													p.closeModal();
													if (process) {
														p.handleAuthorize();
													} else p.handleReject();
												}}
											>
												Proses
											</Button>
										</div>
									</div>
								);
							},
							headerProps: {
								title: 'Konfirmasi' + (process ? '' : ' Tolak'),
								icon: 'FaCheck',
							},
							contentProps: {
								message: 'Apakah anda yakin akan ' + (process ? 'menyetujui' : 'menolak') + ' transaksi ini?',
								handleAuthorize: handleAuthorize,
								handleReject: handleReject,
								dsMainAction: this.dsMainAction,
								process: process,
							},
							size: 'small',
						});
					};

					const AlertHandler = async (msg, type) => {
						await this.appAction.frameAction.showModal({
							contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
							size: 'small',
						});
					};

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							<div className="row">
								<div className="col-6">
									<FormLabel label="Nomor Draft Transaksi">
										<h6>
											{this.state?.response?.tx_ref_number && this.state?.response?.tx_ref_number !== '' ? (
												<mainComps.FieldDataDisplay fieldName="tx_ref_number" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Status Workflow">
										<h6>
											{this.state?.response?.workflow_state && this.state?.response?.workflow_state !== '' ? (
												<mainComps.FieldDataDisplay fieldName="workflow_state" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Status Eksekusi">
										<h6>
											{this.state?.response?.execution_status && this.state?.response?.execution_status !== '' ? (
												<mainComps.FieldDataDisplay fieldName="execution_status" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Nomor Rekening">
										<h6>
											{this.state?.response?.account_no && this.state?.response?.account_no !== '' ? (
												<mainComps.FieldDataDisplay fieldName="account_no" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Tanggal Dibuat">
										<h6>
											{this.state?.response?.entry_datetime && this.state?.response?.entry_datetime !== '' ? (
												<mainComps.FieldDataDisplay fieldName="entry_datetime" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>
									<FormLabel label="Dibuat Oleh">
										<h6>
											{this.state?.response?.user_name && this.state?.response?.user_name !== '' ? <mainComps.FieldDataDisplay fieldName="user_name" /> : '-'}
										</h6>
									</FormLabel>
									<FormLabel label="Mode">
										<h6>{this.state?.response?.tx_mode && this.state?.response?.tx_mode !== '' ? <mainComps.FieldDataDisplay fieldName="tx_mode" /> : '-'}</h6>
									</FormLabel>
								</div>
								<div className="col-6">
									<FormLabel label="Jenis Transaksi">
										<h6>{this.state?.response?.tx_desc && this.state?.response?.tx_desc !== '' ? <mainComps.FieldDataDisplay fieldName="tx_desc" /> : '-'}</h6>
									</FormLabel>
									{this.state?.response?.tx_code == 'BLP' ? (
										<>
											<FormLabel label={
												this.state?.response?.product_code === "070001" ? 'IDPEL' : (
													this.state?.response?.product_code === '070002' ? 'Nomor Meter' : 'Nomor Registrasi'
												)
												}>
												<h6>
													{this.state?.response?.credit_account_no && this.state?.response?.credit_account_no !== '' ? (
														<mainComps.FieldDataDisplay fieldName="credit_account_no" />
													) : (
														'-'
													)}
												</h6>
											</FormLabel>
											{this.state?.response?.product_code == '070002' ? (
												<>
													<FormLabel label={'Tarif/Daya'}>
														<h6>{this.state?.response?.tx_data?.bill_info?.tarif_daya}</h6>
													</FormLabel>
												</>
											) : (
												' '
											)}
											{this.state?.response?.product_code == '070001' ? (
												<>
													<FormLabel label={'BL/TH'}>
														<h6>{this.state?.response?.tx_data?.bill_info?.bl_th}</h6>
													</FormLabel>
												</>
											) : (
												' '
											)}
										</>
									) : (
										<FormLabel
											label={
												'Nomor ' + (this.state?.response?.tx_code && this.state?.response?.tx_code !== 'TRF' ? 'Akun' : 'Rekening') + ' Tujuan Transaksi'
											}
										>
											<h6>
												{this.state?.response?.credit_account_no && this.state?.response?.credit_account_no !== '' ? (
													<mainComps.FieldDataDisplay fieldName="credit_account_no" />
												) : (
													'-'
												)}
											</h6>
										</FormLabel>
									)}

									<FormLabel
										label={
											this.state.isMPN === true
												? this.state.response?.product_code == 'DJP'
													? 'Wajib Pajak'
													: 'Wajib Bayar'
												: 'Nama Pemilik ' + (this.state?.response?.tx_code && this.state?.response?.tx_code !== 'TRF' ? 'Akun' : 'Rekening') + ' Tujuan'
										}
									>
										<h6>
											{this.state?.response?.credit_account_name && this.state?.response?.credit_account_name !== '' ? (
												<mainComps.FieldDataDisplay fieldName="credit_account_name" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>
									<FormLabel label={this.state.isMPN === true ? 'Jenis Pajak' : 'Bank Tujuan'}>
										<h6>
											<mainComps.FieldDataDisplay fieldName="bank_name" />
										</h6>
									</FormLabel>

									<FormLabel label="Keterangan">
										<h6>
											{this.state?.response?.description && this.state?.response?.description !== '' ? (
												<mainComps.FieldDataDisplay fieldName="description" />
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Jumlah">
										<h6>
											{this.state?.response?.amount && this.state?.response?.amount !== '' ? (
												<>
													IDR 
													<mainComps.FieldDataDisplay fieldName="amount" /> 
												</>
											) : (
												'-'
											)}
										</h6>
									</FormLabel>

									<FormLabel label="Fee">
										<h6> 
											{this.state?.response?.amount_fee && this.state?.response?.amount_fee !== '' ? (
												<>
													IDR  
													<mainComps.FieldDataDisplay fieldName="amount_fee" />
												</>
												
											) : (
												'-'
											)}
										</h6>
									</FormLabel>
									<div
										style={{
											display: this.state.isErr ? 'block' : 'none',
										}}
									>
										{this.state.errMessage}
									</div>
								</div>

								{this.state.response?.canprocess && (
									<div className="col-12">
										<Button onClick={() => Konfirmasi(true)} style={{ marginRight: 5 }}>
											{this.state?.response?.current_workflow_state == 'C' || this.state?.response?.current_workflow_state == 'T' ? 'Authorize' : 'Realease'}
										</Button>{' '}
										<Button onClick={() => Konfirmasi(false)} type={'bordered'} style={{ marginRight: 5 }}>
											Reject
										</Button>{' '}
										<Button onClick={_ => this.props.closeModal()} color="cancel">
											Close
										</Button>
									</div>
								)}
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return transaksiMenungguApprovalView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
