import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './pagination.scss';

const Pagination = ({
	onChangeLimit = e => {
		console.log(`limit changed into: ${e}`);
	},
	limit = 10,
	handleNext = () => console.log('next invoked...'),
	handlePrevious = () => console.log('previous invoked'),
	hasNext = false,
	hasPrev = false,
	inServerRequest = false,
	hideNavButtons = false
}) => {
	const handleChangeLimit = e => onChangeLimit(e.target.value);
	const handleGoNext = e => {
		handleNext();
	};
	const handleGoPrev = e => {
		handlePrevious();
	};

	return (
		<div className={'pagination'}>
			<div>
				Jumlah Data:{' '}
				<select
					onChange={handleChangeLimit}
					defaultValue={limit}
					disabled={inServerRequest}
				>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
				</select>
			</div>
			{hideNavButtons == true ? null :
				<div className="button-container">
					<div>
						<button
							disabled={!hasPrev || inServerRequest}
							onClick={handleGoPrev}
						>
							<FaAngleLeft />
						</button>
					</div>
					<div></div>
					<div>
						<button
							disabled={!hasNext || inServerRequest}
							onClick={handleGoNext}
						>
							<FaAngleRight />
						</button>
					</div>
				</div>}
			<div></div>
		</div>
	);
};

export default Pagination;
