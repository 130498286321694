import React from 'react';

import '../../app_styles/commons/_action-box.scss';

import Button from '../Button/Button';

const FilterActionButtons = ({ buttons = [], style }) => {
	return (
		<div className={'action-box'} style={{ ...style }}>
			{buttons.map((i, k) => {
				return (
					<Button
						type={i.type}
						key={k}
						onClick={
							i.onClick
								? i.onClick
								: () =>
									console.log(
										i.name + ' button clicked...',
									)
						}
						size={i.size}
						color={i.color}
						variant={i.variant}
					>
						{i.render ? i.render() : i.name}
					</Button>
				);
			})}
		</div>
	);
};

export default FilterActionButtons;
