import React, { useEffect } from 'react';
import { AppFrame } from '../modules/com.ihsan/appframe_minimal.js';
import { initVisualLib, setStaticComponents, initGlobalModules, useStdAppContext, FRAME_CONTAINER_PATH } from '../modules/com.ihsan/appdata.js';

import { saveAs } from 'file-saver';
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import { DateFieldDataInput } from '../modules/com.ihsan/dsetwidgetex';
import NDataBrowser from '../modules/com.ihsan/new.databrowser';
import * as NDswidget from '../modules/com.ihsan/new.dsetwidget';
import dataselector from '../modules/com.ihsan/dataselector';

// register static modules here
import { ModuleDefinition as miscSupportUI } from '../app_frames/apps_local/customer/miscSupportUI';
import { ModuleDefinition as appGlobal } from '../app_frames/apps_local/customer/appGlobal';
import { ModuleDefinition as main } from '../app_frames/apps_local/customer/main';

import { ModuleDefinition as listRekeningMetadata } from '../app_frames/apps_local/customer/manajemen_akun/list_rekening/listRekeningMetadata.js';
import { ModuleDefinition as listRekeningList } from '../app_frames/apps_local/customer/manajemen_akun/list_rekening/listRekeningList.js';
import { ModuleDefinition as listRekeningView } from '../app_frames/apps_local/customer/manajemen_akun/list_rekening/listRekeningView.js';

import { ModuleDefinition as rekeningFavoriteMetadata } from '../app_frames/apps_local/customer/manajemen_akun/rekening_favorite/rekeningFavoriteMetadata';
import { ModuleDefinition as rekeningFavoriteList } from '../app_frames/apps_local/customer/manajemen_akun/rekening_favorite/rekeningFavoriteList';
import { ModuleDefinition as rekeningFavoriteView } from '../app_frames/apps_local/customer/manajemen_akun/rekening_favorite/rekeningFavoriteView';

import { ModuleDefinition as historiTransaksiMetadata } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiMetadata.js';
import { ModuleDefinition as historiTransaksiTagsMetadata } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiTagsMetadata.js';
import { ModuleDefinition as historiTransaksiList } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiList.js';
import { ModuleDefinition as historiTransaksiView } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiView.js';
import { ModuleDefinition as historiTransaksiResi } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiResi';
import { ModuleDefinition as historiTransaksiTags } from '../app_frames/apps_local/customer/manajemen_akun/histori_transaksi/historiTransaksiTags';

import { ModuleDefinition as mutasiRekeningMetadata } from '../app_frames/apps_local/customer/manajemen_akun/mutasi_rekening/mutasiRekeningMetadata';
import { ModuleDefinition as mutasiRekeningList } from '../app_frames/apps_local/customer/manajemen_akun/mutasi_rekening/mutasiRekeningList';
import { ModuleDefinition as mutasiRekeningView } from '../app_frames/apps_local/customer/manajemen_akun/mutasi_rekening/mutasiRekeningView';

import { ModuleDefinition as listDraftTransaksiMetadata } from '../app_frames/apps_local/customer/manajemen_transaksi/list_draft_transaksi/listDraftTransaksiMetadata';
import { ModuleDefinition as listDraftTransaksiList } from '../app_frames/apps_local/customer/manajemen_transaksi/list_draft_transaksi/listDraftTransaksiList';
import { ModuleDefinition as listDraftTransaksiview } from '../app_frames/apps_local/customer/manajemen_transaksi/list_draft_transaksi/listDraftTransaksiView';
import { ModuleDefinition as transaksiMenungguApprovalMetadata } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_menunggu_approval/transaksiMenungguApprovalMetadata';
import { ModuleDefinition as transaksiMenungguApprovalList } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_menunggu_approval/transaksiMenungguApprovalList';
import { ModuleDefinition as transaksiMenungguApprovalView } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_menunggu_approval/transaksiMenungguApprovalView';
import { ModuleDefinition as transaksiTerjadwalMetadata } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_terjadwal/transaksiTerjadwalMetadata';
import { ModuleDefinition as transaksiTerjadwalList } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_terjadwal/transaksiTerjadwalList';
import { ModuleDefinition as transaksiTerjadwalView } from '../app_frames/apps_local/customer/manajemen_transaksi/transaksi_terjadwal/transaksiTerjadwalView';

import { ModuleDefinition as rekeningSendiriMetadata } from '../app_frames/apps_local/customer/transfer/rekening_sendiri/rekeningSendiriMetadata.js';
import { ModuleDefinition as rekeningSendiriKonfirmasi } from '../app_frames/apps_local/customer/transfer/rekening_sendiri/rekeningSendiriKonfirmasi.js';
import { ModuleDefinition as rekeningSendiri } from '../app_frames/apps_local/customer/transfer/rekening_sendiri/rekeningSendiri.js';

import { ModuleDefinition as foreignRemitanceMetadata } from '../app_frames/apps_local/customer/transfer/foreign_remitance/foreignRemitanceMetadata.js';
import { ModuleDefinition as foreignRemitance } from '../app_frames/apps_local/customer/transfer/foreign_remitance/foreignRemitance.js';
import { ModuleDefinition as foreignRemitanceKonfirmasi } from '../app_frames/apps_local/customer/transfer/foreign_remitance/foreignRemitanceKonfirmasi.js';

import { ModuleDefinition as rekeningSamaBankMetadata } from '../app_frames/apps_local/customer/transfer/rekening_sama_bank/rekeningSamaBankMetadata.js';
import { ModuleDefinition as rekeningSamaBank } from '../app_frames/apps_local/customer/transfer/rekening_sama_bank/rekeningSamaBank.js';
import { ModuleDefinition as rekeningSamaBankKonfirmasi } from '../app_frames/apps_local/customer/transfer/rekening_sama_bank/rekeningSamaBankKonfirmasi.js';

import { ModuleDefinition as rekeningBankLainMetadata } from '../app_frames/apps_local/customer/transfer/rekening_bank_lain/rekeningBankLainMetadata.js';
import { ModuleDefinition as rekeningBankLain } from '../app_frames/apps_local/customer/transfer/rekening_bank_lain/rekeningBankLain.js';
import { ModuleDefinition as rekeningBankLainKonfirmasi } from '../app_frames/apps_local/customer/transfer/rekening_bank_lain/rekeningBankLainKonfirmasi.js';

import { ModuleDefinition as changePasswordMetadata } from '../app_frames/apps_local/customer/application/changePasswordMetadata.js';
import { ModuleDefinition as changePassword } from '../app_frames/apps_local/customer/application/changePassword.js';

import { ModuleDefinition as monitorTasksMetadata } from '../app_frames/apps_local/customer/application/monitorTasksMetadata.js';
import { ModuleDefinition as monitorTasks } from '../app_frames/apps_local/customer/application/monitorTasks';

import { ModuleDefinition as hPPascaBayarMetadata } from '../app_frames/apps_local/customer/pembayaran/hp_pascaBayar/hPPascaBayarMetadata';
import { ModuleDefinition as hPPascaBayarEditNew } from '../app_frames/apps_local/customer/pembayaran/hp_pascaBayar/hPPascaBayarEditNew';
import { ModuleDefinition as hPPascaBayarEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/hp_pascaBayar/hPPascaBayarEditNewKonfirmasi';

import { ModuleDefinition as internetMetadata } from '../app_frames/apps_local/customer/pembayaran/internet/internetMetadata';
import { ModuleDefinition as internetEditNew } from '../app_frames/apps_local/customer/pembayaran/internet/internetEditNew';
import { ModuleDefinition as internetEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/internet/internetEditNewKonfirmasi';

import { ModuleDefinition as tVLanggananMetadata } from '../app_frames/apps_local/customer/pembayaran/tv_langganan/tVLanggananMetadata';
import { ModuleDefinition as tVLanggananEditNew } from '../app_frames/apps_local/customer/pembayaran/tv_langganan/tVLanggananEditNew';
import { ModuleDefinition as tVLanggananEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/tv_langganan/tVLanggananEditNewKonfirmasi';

import { ModuleDefinition as multiFinanceMetadata } from '../app_frames/apps_local/customer/pembayaran/multi_finance/multiFinanceMetadata';
import { ModuleDefinition as multiFinanceEditNew } from '../app_frames/apps_local/customer/pembayaran/multi_finance/multiFinanceEditNew';
import { ModuleDefinition as multiFinanceEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/multi_finance/multiFinanceEditNewKonfirmasi';

import { ModuleDefinition as topUpMetadata } from '../app_frames/apps_local/customer/pembayaran/topUp/topUpMetadata';
import { ModuleDefinition as topUpEditNew } from '../app_frames/apps_local/customer/pembayaran/topUp/topUpEditNew';
import { ModuleDefinition as topUpEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/topUp/topUpEditNewKonfirmasi';

import { ModuleDefinition as tiketMetadata } from '../app_frames/apps_local/customer/pembayaran/tiket/tiketMetadata';
import { ModuleDefinition as tiketEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/tiket/tiketEditNewKonfirmasi';
import { ModuleDefinition as tiketEditNew } from '../app_frames/apps_local/customer/pembayaran/tiket/tiketEditNew';

import { ModuleDefinition as pajakMetadata } from '../app_frames/apps_local/customer/pembayaran/pajak/pajakMetadata';
import { ModuleDefinition as pajakEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pajak/pajakEditNewKonfirmasi';
import { ModuleDefinition as pajakEditNew } from '../app_frames/apps_local/customer/pembayaran/pajak/pajakEditNew';

import { ModuleDefinition as pembayaranUmrahMetadata } from '../app_frames/apps_local/customer/pembayaran/pembayaran_umrah/pembayaranUmrahMetadata';
import { ModuleDefinition as pembayaranUmrahEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pembayaran_umrah/pembayaranUmrahEditNewKonfirmasi';
import { ModuleDefinition as pembayaranUmrahEditNew } from '../app_frames/apps_local/customer/pembayaran/pembayaran_umrah/pembayaranUmrahEditNew';

import { ModuleDefinition as pelunasanHajiMetadata } from '../app_frames/apps_local/customer/pembayaran/pelunasan_haji/pelunasanHajiMetadata';
import { ModuleDefinition as pelunasanHajiEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pelunasan_haji/pelunasanHajiEditNewKonfirmasi';
import { ModuleDefinition as pelunasanHajiEditNew } from '../app_frames/apps_local/customer/pembayaran/pelunasan_haji/pelunasanHajiEditNew';

import { ModuleDefinition as belanjaOnlineMetadata } from '../app_frames/apps_local/customer/pembayaran/belanja_online/belanjaOnlineMetadata';
import { ModuleDefinition as belanjaOnlineEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/belanja_online/belanjaOnlineEditNewKonfirmasi';
import { ModuleDefinition as belanjaOnlineEditNew } from '../app_frames/apps_local/customer/pembayaran/belanja_online/belanjaOnlineEditNew';

import { ModuleDefinition as pLNPascaBayarMetadata } from '../app_frames/apps_local/customer/pembayaran/pln_pascaBayar/pLNPascaBayarMetadata';
import { ModuleDefinition as pLNPascaBayarEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pln_pascaBayar/pLNPascaBayarEditNewKonfirmasi';
import { ModuleDefinition as pLNPascaBayarEditNew } from '../app_frames/apps_local/customer/pembayaran/pln_pascaBayar/pLNPascaBayarEditNew';

import { ModuleDefinition as pLNNonTagListMetadata } from '../app_frames/apps_local/customer/pembayaran/pln_nonTagLis/pLNNonTagLisMetadata';
import { ModuleDefinition as pLNNonTagListEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pln_nonTagLis/pLNNonTagLisEditNewKonfirmasi';
import { ModuleDefinition as pLNNonTagListEditNew } from '../app_frames/apps_local/customer/pembayaran/pln_nonTagLis/pLNNonTagLisEditNew';

import { ModuleDefinition as pembayaranVAMetadata } from '../app_frames/apps_local/customer/pembayaran/pembayaran_va/pembayaranVAMetadata';
import { ModuleDefinition as pembayaranVAEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pembayaran_va/pembayaranVAEditNewKonfirmasi';
import { ModuleDefinition as pembayaranVAEditNew } from '../app_frames/apps_local/customer/pembayaran/pembayaran_va/pembayaranVAEditNew';

import { ModuleDefinition as telkomPayMetadata } from '../app_frames/apps_local/customer/pembayaran/telkompay/telkomPayMetadata';
import { ModuleDefinition as telkomPayEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/telkompay/telkomPayEditNewKonfirmasi';
import { ModuleDefinition as telkomPayEditNew } from '../app_frames/apps_local/customer/pembayaran/telkompay/telkomPayEditNew';

import { ModuleDefinition as listTransaksiMassalMetadata } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/listTransaksiMassalMetadata';
import { ModuleDefinition as listTransaksiMassal } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/listTransaksiMassal';
import { ModuleDefinition as listTransaksiMassalEditNew } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/listTransaksiMassalEditNew';
import { ModuleDefinition as detailTransaksiMassalMetadata } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/detailTransaksiMassalMetadata';
import { ModuleDefinition as detailTransaksiMassalKonfirmasi } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/detailTransaksiMassalKonfirmasi';
import { ModuleDefinition as detailTransaksiMassalEditNew } from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/detailTransaksiMassalEditNew';

import { ModuleDefinition as pulsaMetadata } from '../app_frames/apps_local/customer/pembelian/pulsa/pulsaMetadata';
import { ModuleDefinition as pulsaEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembelian/pulsa/pulsaEditNewKonfirmasi';
import { ModuleDefinition as pulsaEditNew } from '../app_frames/apps_local/customer/pembelian/pulsa/pulsaEditNew';

import { ModuleDefinition as paketDataMetadata } from '../app_frames/apps_local/customer/pembelian/paket_data/paketDataMetadata';
import { ModuleDefinition as paketDataEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembelian/paket_data/paketDataEditNewKonfirmasi';
import { ModuleDefinition as paketDataEditNew } from '../app_frames/apps_local/customer/pembelian/paket_data/paketDataEditNew';

import { ModuleDefinition as pLNPrabayarMetadata } from '../app_frames/apps_local/customer/pembelian/pln_prabayar/pLNPrabayarMetadata';
import { ModuleDefinition as pLNPrabayarEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembelian/pln_prabayar/pLNPrabayarEditNewKonfirmasi';
import { ModuleDefinition as pLNPrabayarEditNew } from '../app_frames/apps_local/customer/pembelian/pln_prabayar/pLNPrabayarEditNew';

import { ModuleDefinition as pLNPrepaidAdviceMetadata } from '../app_frames/apps_local/customer/pembelian/pln_prepaid_advice/pLNPrepaidAdviceMetadata';
import { ModuleDefinition as pLNPrepaidAdviceEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembelian/pln_prepaid_advice/pLNPrepaidAdviceEditNewKonfirmasi';
import { ModuleDefinition as pLNPrepaidAdviceEditNew } from '../app_frames/apps_local/customer/pembelian/pln_prepaid_advice/pLNPrepaidAdviceEditNew';

import { ModuleDefinition as listVAMetadata } from '../app_frames/apps_local/customer/manajemen_va/list_va/listVAMetadata';
import { ModuleDefinition as listVAList } from '../app_frames/apps_local/customer/manajemen_va/list_va/listVAList';
import { ModuleDefinition as listVAView } from '../app_frames/apps_local/customer/manajemen_va/list_va/listVAView';

import { ModuleDefinition as listProdukVAList } from '../app_frames/apps_local/customer/manajemen_va/list_produk_va/listProdukVAList';

import { ModuleDefinition as listTagihanVAMetadata } from '../app_frames/apps_local/customer/manajemen_va/list_tagihan_va/listTagihanVAMetadata';
import { ModuleDefinition as listTagihanVAList } from '../app_frames/apps_local/customer/manajemen_va/list_tagihan_va/listTagihanVAList';
import { ModuleDefinition as listTagihanVAView } from '../app_frames/apps_local/customer/manajemen_va/list_tagihan_va/listTagihanVAView';

import { ModuleDefinition as listTransaksiVAMetadata } from '../app_frames/apps_local/customer/manajemen_va/list_transaksi_va/listTransaksiVAMetadata';
import { ModuleDefinition as listTransaksiVAList } from '../app_frames/apps_local/customer/manajemen_va/list_transaksi_va/listTransaksiVAList';
import { ModuleDefinition as listTransaksiVAView } from '../app_frames/apps_local/customer/manajemen_va/list_transaksi_va/listTransaksiVAView';

import { ModuleDefinition as reportTagsMetadata } from '../app_frames/apps_local/customer/tagging_transaksi/report_tags/reportTagsMetadata'
import { ModuleDefinition as reportTagsList } from '../app_frames/apps_local/customer/tagging_transaksi/report_tags/reportTagsList';
import { ModuleDefinition as reportTagsView } from '../app_frames/apps_local/customer/tagging_transaksi/report_tags/reportTagsView';

import { ModuleDefinition as pLNPrepaidMetadata } from '../app_frames/apps_local/customer/pembayaran/pln_prepaid/pLNPrepaidMetadata';
import { ModuleDefinition as pLNPrepaidEditNewKonfirmasi } from '../app_frames/apps_local/customer/pembayaran/pln_prepaid/pLNPrepaidEditNewKonfirmasi';
import { ModuleDefinition as pLNPrepaidEditNew } from '../app_frames/apps_local/customer/pembayaran/pln_prepaid/pLNPrepaidEditNew';

// import hoc here
import { ModuleDefinition as MainMenu } from '../app_components/_hoc/MainMenu';

// import static components here
import PageLoadingIndicator from '../app_components/PageLoadingIndicator/PageLoadingIndicator';
import Button from '../app_components/Button/Button';
import DatePicker from '../app_components/Forms/DatePicker/DatePicker';
import SearchBox from '../app_components/SearchBox/SearchBox';
import Table from '../app_components/Table/Table';
import Pagination from '../app_components/Pagination/Pagination';
import Filter from '../app_components/Filter/Filter';
import FormLabel from '../app_components/Forms/FormLabel/FormLabel';
import RadioIcon from '../app_components/RadioButtonBordered/Radiobordered';
import Radio from '../app_components/Radio/Radio';
import InputGroupButton from '../app_components/InputGroupButton/InputGroupButton';
import PageTitle from '../app_components/PageTitle/PageTitle';
import FilterActionButtons from '../app_components/Filter/FilterActionButtons';
import ModalHeader from '../app_components/Modal/ModalHeader/ModalHeader';
import ModalBody from '../app_components/Modal/ModalBody/ModalBody';
import Input from '../app_components/Forms/Input/Input';
import Upload from '../app_components/Upload/Upload';
import UploadWithPreview from '../app_components/Upload/UploadWithPreview';
import Dropdown from '../app_components/Dropdown/Dropdown';
import RadioGroup from '../app_components/Forms/RadioGroup/RadioGroup';
import Checkbox from '../app_components/Forms/Checkbox/Checkbox';
import Breadcrumbs from '../app_components/Breadcrumbs/Breadcrumbs';
import AlertComponent from '../app_components/AlertComponent/AlertComponent';
import InputGroup from '../app_components/InputGroupIcon/InputGroupIcon';
import TransaksiTerjadwal from '../app_components/TransaksiTerjadwal/TransaksiTerjadwal';
import ViewTransaksiTerjadwal from '../app_components/TransaksiTerjadwal/ViewTransaksiTerjadwal';
import RekeningFavorite from '../app_components/RekeningFavorite/RekeningFavorite';
import OTPComponent from '../app_components/_hoc/OTPComp/OTPComponent';
import * as Template from '../app_components/_assets/template';

import * as Validator from '../app_components/Validator/validatorInput';

import * as ReactIconFa from 'react-icons/fa';
import * as ReactIconSi from 'react-icons/si';
import * as ReactIconGr from 'react-icons/gr';

import * as trxAndProductCode from '../app_frames/apps_local/customer/transaksi_massal/transaksi_massal/trxAndProductCode';

import { asString } from '../utils/index.js';

// list all static modules
const staticModules = {
	MainMenu,

	appGlobal,
	miscSupportUI,
	main,

	'application.changePasswordMetadata': changePasswordMetadata,
	'application.changePassword': changePassword,

	'application.monitorTasksMetadata': monitorTasksMetadata,
	'application.monitorTasks': monitorTasks,

	'manajemen_akun.list_rekening.listRekeningMetadata': listRekeningMetadata,
	'manajemen_akun.list_rekening.listRekeningList': listRekeningList,
	'manajemen_akun.list_rekening.listRekeningView': listRekeningView,

	'manajemen_akun.rekening_favorite.rekeningFavoriteMetadata': rekeningFavoriteMetadata,
	'manajemen_akun.rekening_favorite.rekeningFavoriteList': rekeningFavoriteList,
	'manajemen_akun.rekening_favorite.rekeningFavoriteView': rekeningFavoriteView,

	'manajemen_akun.mutasi_rekening.mutasiRekeningMetadata': mutasiRekeningMetadata,
	'manajemen_akun.mutasi_rekening.mutasiRekeningList': mutasiRekeningList,
	'manajemen_akun.mutasi_rekening.mutasiRekeningView': mutasiRekeningView,

	'manajemen_akun.histori_transaksi.historiTransaksiMetadata': historiTransaksiMetadata,
	'manajemen_akun.histori_transaksi.historiTransaksiTagsMetadata': historiTransaksiTagsMetadata,
	'manajemen_akun.histori_transaksi.historiTransaksiList': historiTransaksiList,
	'manajemen_akun.histori_transaksi.historiTransaksiView': historiTransaksiView,
	'manajemen_akun.histori_transaksi.historiTransaksiResi': historiTransaksiResi,
	'manajemen_akun.histori_transaksi.historiTransaksiTags': historiTransaksiTags,

	'manajemen_transaksi.list_draft_transaksi.listDraftTransaksiMetadata': listDraftTransaksiMetadata,
	'manajemen_transaksi.list_draft_transaksi.listDraftTransaksiList': listDraftTransaksiList,
	'manajemen_transaksi.list_draft_transaksi.listDraftTransaksiView': listDraftTransaksiview,

	'manajemen_transaksi.transaksi_menunggu_approval.transaksiMenungguApprovalMetadata': transaksiMenungguApprovalMetadata,
	'manajemen_transaksi.transaksi_menunggu_approval.transaksiMenungguApprovalList': transaksiMenungguApprovalList,
	'manajemen_transaksi.transaksi_menunggu_approval.transaksiMenungguApprovalView': transaksiMenungguApprovalView,

	'manajemen_transaksi.transaksi_terjadwal.transaksiTerjadwalMetadata': transaksiTerjadwalMetadata,
	'manajemen_transaksi.transaksi_terjadwal.transaksiTerjadwalList': transaksiTerjadwalList,
	'manajemen_transaksi.transaksi_terjadwal.transaksiTerjadwalView': transaksiTerjadwalView,

	'transfer.rekening_sendiri.rekeningSendiriMetadata': rekeningSendiriMetadata,
	'transfer.rekening_sendiri.rekeningSendiriKonfirmasi': rekeningSendiriKonfirmasi,
	'transfer.rekening_sendiri.rekeningSendiri': rekeningSendiri,

	'transfer.rekening_sama_bank.rekeningSamaBankMetadata': rekeningSamaBankMetadata,
	'transfer.rekening_sama_bank.rekeningSamaBankKonfirmasi': rekeningSamaBankKonfirmasi,
	'transfer.rekening_sama_bank.rekeningSamaBank': rekeningSamaBank,

	'transfer.rekening_bank_lain.rekeningBankLainMetadata': rekeningBankLainMetadata,
	'transfer.rekening_bank_lain.rekeningBankLainKonfirmasi': rekeningBankLainKonfirmasi,
	'transfer.rekening_bank_lain.rekeningBankLain': rekeningBankLain,

	'transfer.foreign_remitance.foreignRemitanceMetadata': foreignRemitanceMetadata,
	'transfer.foreign_remitance.foreignRemitanceKonfirmasi': foreignRemitanceKonfirmasi,
	'transfer.foreign_remitance.foreignRemitance': foreignRemitance,

	'pembayaran.hp_pascaBayar.hPPascaBayarMetadata': hPPascaBayarMetadata,
	'pembayaran.hp_pascaBayar.hPPascaBayarEditNewKonfirmasi': hPPascaBayarEditNewKonfirmasi,
	'pembayaran.hp_pascaBayar.hPPascaBayarEditNew': hPPascaBayarEditNew,

	'pembayaran.internet.internetMetadata': internetMetadata,
	'pembayaran.internet.internetEditNewKonfirmasi': internetEditNewKonfirmasi,
	'pembayaran.internet.internetEditNew': internetEditNew,

	'pembayaran.tv_langganan.tVLanggananMetadata': tVLanggananMetadata,
	'pembayaran.tv_langganan.tVLanggananEditNewKonfirmasi': tVLanggananEditNewKonfirmasi,
	'pembayaran.tv_langganan.tVLanggananEditNew': tVLanggananEditNew,

	'pembayaran.multi_finance.multiFinanceMetadata': multiFinanceMetadata,
	'pembayaran.multi_finance.multiFinanceEditNewKonfirmasi': multiFinanceEditNewKonfirmasi,
	'pembayaran.multi_finance.multiFinanceEditNew': multiFinanceEditNew,

	'pembayaran.topUp.topUpMetadata': topUpMetadata,
	'pembayaran.topUp.topUpEditNewKonfirmasi': topUpEditNewKonfirmasi,
	'pembayaran.topUp.topUpEditNew': topUpEditNew,

	'pembayaran.tiket.tiketMetadata': tiketMetadata,
	'pembayaran.tiket.tiketEditNewKonfirmasi': tiketEditNewKonfirmasi,
	'pembayaran.tiket.tiketEditNew': tiketEditNew,

	'pembayaran.pajak.pajakMetadata': pajakMetadata,
	'pembayaran.pajak.pajakEditNewKonfirmasi': pajakEditNewKonfirmasi,
	'pembayaran.pajak.pajakEditNew': pajakEditNew,

	'pembayaran.pembayaran_umrah.pembayaranUmrahMetadata': pembayaranUmrahMetadata,
	'pembayaran.pembayaran_umrah.pembayaranUmrahEditNewKonfirmasi': pembayaranUmrahEditNewKonfirmasi,
	'pembayaran.pembayaran_umrah.pembayaranUmrahEditNew': pembayaranUmrahEditNew,

	'pembayaran.pelunasan_haji.pelunasanHajiMetadata': pelunasanHajiMetadata,
	'pembayaran.pelunasan_haji.pelunasanHajiEditNewKonfirmasi': pelunasanHajiEditNewKonfirmasi,
	'pembayaran.pelunasan_haji.pelunasanHajiEditNew': pelunasanHajiEditNew,

	'pembayaran.belanja_online.belanjaOnlineMetadata': belanjaOnlineMetadata,
	'pembayaran.belanja_online.belanjaOnlineEditNewKonfirmasi': belanjaOnlineEditNewKonfirmasi,
	'pembayaran.belanja_online.belanjaOnlineEditNew': belanjaOnlineEditNew,

	'pembayaran.pln_pascaBayar.pLNPascaBayarMetadata': pLNPascaBayarMetadata,
	'pembayaran.pln_pascaBayar.pLNPascaBayarEditNewKonfirmasi': pLNPascaBayarEditNewKonfirmasi,
	'pembayaran.pln_pascaBayar.pLNPascaBayarEditNew': pLNPascaBayarEditNew,

	'pembayaran.pln_nonTagLis.pLNNonTagLisMetadata': pLNNonTagListMetadata,
	'pembayaran.pln_nonTagLis.pLNNonTagLisEditNewKonfirmasi': pLNNonTagListEditNewKonfirmasi,
	'pembayaran.pln_nonTagLis.pLNNonTagLisEditNew': pLNNonTagListEditNew,

	'pembayaran.telkompay.telkomPayMetadata': telkomPayMetadata,
	'pembayaran.telkompay.telkomPayEditNewKonfirmasi': telkomPayEditNewKonfirmasi,
	'pembayaran.telkompay.telkomPayEditNew': telkomPayEditNew,

	'pembayaran.pembayaran_va.pembayaranVAMetadata': pembayaranVAMetadata,
	'pembayaran.pembayaran_va.pembayaranVAEditNewKonfirmasi': pembayaranVAEditNewKonfirmasi,
	'pembayaran.pembayaran_va.pembayaranVAEditNew': pembayaranVAEditNew,

	// transaksi massal ========================================================
	'transaksi_massal.transaksi_massal.listTransaksiMassalMetadata': listTransaksiMassalMetadata,
	'transaksi_massal.transaksi_massal.listTransaksiMassal': listTransaksiMassal,
	'transaksi_massal.transaksi_massal.listTransaksiMassalEditNew': listTransaksiMassalEditNew,
	'transaksi_massal.transaksi_massal.detailTransaksiMassalMetadata': detailTransaksiMassalMetadata,
	'transaksi_massal.transaksi_massal.detailTransaksiMassalKonfirmasi': detailTransaksiMassalKonfirmasi,
	'transaksi_massal.transaksi_massal.detailTransaksiMassalEditNew': detailTransaksiMassalEditNew,
	// transaksi massal =========================================================

	'pembelian.pulsa.pulsaMetadata': pulsaMetadata,
	'pembelian.pulsa.pulsaEditNewKonfirmasi': pulsaEditNewKonfirmasi,
	'pembelian.pulsa.pulsaEditNew': pulsaEditNew,

	'pembelian.paket_data.paketDataMetadata': paketDataMetadata,
	'pembelian.paket_data.paketDataEditNewKonfirmasi': paketDataEditNewKonfirmasi,
	'pembelian.paket_data.paketDataEditNew': paketDataEditNew,

	'pembelian.pln_prabayar.pLNPrabayarMetadata': pLNPrabayarMetadata,
	'pembelian.pln_prabayar.pLNPrabayarEditNewKonfirmasi': pLNPrabayarEditNewKonfirmasi,
	'pembelian.pln_prabayar.pLNPrabayarEditNew': pLNPrabayarEditNew,

	'pembelian.pln_prepaid_advice.pLNPrepaidAdviceMetadata': pLNPrepaidAdviceMetadata,
	'pembelian.pln_prepaid_advice.pLNPrepaidAdviceEditNewKonfirmasi': pLNPrepaidAdviceEditNewKonfirmasi,
	'pembelian.pln_prepaid_advice.pLNPrepaidAdviceEditNew': pLNPrepaidAdviceEditNew,

	'manajemen_va.list_va.listVAMetadata': listVAMetadata,
	'manajemen_va.list_va.listVAList': listVAList,
	'manajemen_va.list_va.listVAView': listVAView,

	'manajemen_va.list_produk_va.listProdukVAList': listProdukVAList,
	'manajemen_va.list_tagihan_va.listTagihanVAMetadata': listTagihanVAMetadata,
	'manajemen_va.list_tagihan_va.listTagihanVAList': listTagihanVAList,
	'manajemen_va.list_tagihan_va.listTagihanVAView': listTagihanVAView,

	'manajemen_va.list_transaksi_va.listTransaksiVAMetadata': listTransaksiVAMetadata,
	'manajemen_va.list_transaksi_va.listTransaksiVAList': listTransaksiVAList,
	'manajemen_va.list_transaksi_va.listTransaksiVAView': listTransaksiVAView,

	'tagging_transaksi.report_tags.reportTagsMetadata': reportTagsMetadata,
	'tagging_transaksi.report_tags.reportTagsList': reportTagsList,
	'tagging_transaksi.report_tags.reportTagsView': reportTagsView,
	
	'pembayaran.pln_prepaid.pLNPrepaidMetadata': pLNPrepaidMetadata,
	'pembayaran.pln_prepaid.pLNPrepaidEditNewKonfirmasi': pLNPrepaidEditNewKonfirmasi,
	'pembayaran.pln_prepaid.pLNPrepaidEditNew': pLNPrepaidEditNew,
};

const staticComponents = {
	Button,
	DatePicker,
	SearchBox,
	Table,
	Pagination,
	Filter,
	FormLabel,
	RadioIcon,
	Radio,
	InputGroupButton,
	PageTitle,
	FilterActionButtons,
	ModalHeader,
	ModalBody,
	Input,
	Dropdown,
	ReactIconFa,
	ReactIconSi,
	Upload,
	UploadWithPreview,
	ReactIconGr,
	RadioGroup,
	Checkbox,
	Validator,
	Breadcrumbs,
	AlertComponent,
	dataselector,
	InputGroup,
	TransaksiTerjadwal,
	Template,
	trxAndProductCode,
	RekeningFavorite,
	OTPComponent,
	saveAs,
	ViewTransaksiTerjadwal,
	asString,
};

setStaticComponents(staticComponents);

initVisualLib({
	Menu,
	SubMenu,
	MenuItem,
	Divider,
});
initGlobalModules({
	dswidgetex: { DateFieldDataInput },
	ndswidget: NDswidget,
	NDataBrowser,
});

function AppFormContainer(props) {
	return (
		<div>
			<AppFrame treeName={FRAME_CONTAINER_PATH} headerComponent={() => ''} sender="menu" />
		</div>
	);
}

function AppPage(props) {
	const [state, setState] = React.useState({
		isError: false,
		isLoading: true,
		errMsg: '',
		mainAppForm: undefined,
	});

	const [, appAction] = useStdAppContext();

	useEffect(() => {
		(async function () {
			// register static pages / modules
			await appAction.registersStaticModules(staticModules);

			// loading main module
			setState({ isError: false, isLoading: true });
			try {
				const mainAppForm = await appAction.fetchAndExecModule('main');
				setState({ isError: false, isLoading: false, mainAppForm });
			} catch (err) {
				setState({
					isError: true,
					isLoading: false,
					errMsg: err.message,
				});
			}
		})();
	}, []);

	return (
		<div>
			{state.isError ? (
				<span>{state.errMsg}</span>
			) : state.mainAppForm ? (
				<state.mainAppForm frameInstanceName={props.frameInstanceName} AppFormContainer={AppFormContainer} />
			) : (
				<PageLoadingIndicator />
			)}
		</div>
	);
}

export default AppPage;
