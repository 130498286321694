import React, { useRef } from 'react';
import moment from 'moment';

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			NDataBrowser,
			staticComponents: {
				AlertComponent,
				Button,
				FormLabel,
				Upload,
				ReactIconFa: { FaCloudDownloadAlt, FaCloudUploadAlt, FaPlusCircle, FaRetweet, FaRegFilePdf },
				RadioGroup,
				Template,
				trxAndProductCode: { trx_code, metode, listTrx, listProduct, purchaseList, shown },
				Filter,
				FilterActionButtons,
				Input,
				OTPComponent,
				TransaksiTerjadwal,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('transaksi_massal.transaksi_massal.listTransaksiMassalMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					isErr: false,
					errMessage: '',
					product: [],
					tx_code: Object.entries(listTrx),
					rekening: [],
					selectRekening: '',
					statusConvert: '',
					refnumber: props.ref_number || null,
				});
				const uploadRef = useRef();

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const loadData = React.useCallback(async () => {
					setState({
						...state,
						isErr: false,
						errMessage: '',
					});

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{
								id_tx_draft: props.id_tx_draft,
							},
							true,
						);

						dsMainAction.loadStore(response, 'std', serverDataMapping, true);
						if (response.data.length > 0) {
							setState({ ...state, refnumber: response.data[0].ref_number });
						}
					} catch (err) {
						setState({
							...state,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}, [dsMainAction, props._authToken, props.id_tx_draft]);

				React.useEffect(() => {
					(async function () {
						if (props.uiMode == 'edit') {
							await loadData();
							var popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
							await setState({
								...state,
								popupComponent,
							});
						} else {
							dsMainAction.addRow({
								tx_code: 'TRF425',
								product_code: '425',
							});
						}
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const handleKonfirmasi = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						let validasi = { ...fieldValidErrors };

						if (isInValid.length > 0) throw new Error(validasi[isInValid[0][0]]);

						if (!state?.file && props.uiMode !== 'edit') throw new Error('File Upload tidak boleh kosong');

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});
						var data = new FormData();
						data.append('file', state.file);

						if (dataUnload?.data?.length > 0 && props.uiMode !== 'edit') {
							if (dataUnload.data[0].description == '') {
								dataUnload.data[0].description = '-';
							}

							if (dataUnload.data[0].tx_code == 'TRF425') {
								dataUnload.data[0].tx_code = 'TRF';
							}

							dataUnload.data[0] = { ...dataUnload.data[0], ...state.terjadwalState, ...state.terjadwalState?.value };
							if (dataUnload.data[0]['sched_exec_datetime']) {
								dataUnload.data[0]['sched_exec_datetime'] = moment(dataUnload.data[0]['sched_exec_datetime']).format('YMMDD');
							}

							for (let i in dataUnload?.data[0]) {
								data.append(i, dataUnload?.data[0][i]);
							}

							await appAction.postForm(_moduleId, 'getConfirmation', props._authToken, data);

							appAction.frameAction.closeModal();
							AlertHandler('Data berhasil disimpan', 'success');
						} else {
							appAction.frameAction.closeModal();
							AlertHandler('Simpan Perubahan Berhasil', 'success');
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const handleConvert = async () => {
					setState({
						...state,
						isErr: false,
						errMessage: '',
						statusConvert: 'waiting',
					});
					try {
						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: true,
							includeDeletedRows: true,
						});

						if (dataUnload?.data?.length > 0) {
							dataUnload.data[0].__loadFlag = 'U';

							await appAction.fetchResource(_moduleId, 'method', 'convertData', props._authToken, dataUnload, true);

							appAction.frameAction.closeModal();
							AlertHandler('Data berhasil disimpan', 'success');
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const handleClose = async () => {
					setState({
						...state,
						isErr: false,
						errMessage: '',
						statusConvert: 'waiting',
					});
					try {
						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: true,
							includeDeletedRows: true,
						});

						if (dataUnload?.data?.length > 0) {
							dataUnload.data[0].__loadFlag = 'U';

							await appAction.fetchResource(_moduleId, 'method', 'closeData', props._authToken, dataUnload, true);

							appAction.frameAction.closeModal();
							AlertHandler('Data berhasil ditutup', 'success');
						}
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};

				const handleAuthorize = async _ => {
					if (window.confirm('apakah anda yakin akan memproses transaksi ini?')) {
						try {
							var response = await appAction.postData(_moduleId, 'authorize', props._authToken, {
								tx_ref_number: dsMainProxy.fields?.tx_ref_number,
								current_workflow_state: dsMainProxy.fields?.workflow_state,
								isProcess: true,
								comment1: state.comment1,
								comment2: state.comment2,
							});
							if (response.data.require_otp == 'T') {
								await askotp(response.data.otp_ref_number);
							} else {
								alert('Proses berhasil!');
								if (response) props.closeModal(true);
							}
						} catch (e) {
							AlertHandler(e.message, 'error');
						}
					} else props.closeModal();
				};

				const handleReject = async _ => {
					if (window.confirm('apakah anda yakin akan membatalkan transaksi ini?')) {
						try {
							var response = await appAction.postData(_moduleId, 'authorize', props._authToken, {
								tx_ref_number: dsMainProxy.fields?.tx_ref_number,
								isProcess: false,
								comment1: state.comment1,
								comment2: state.comment2,
							});
							if (response) props.closeModal(true);
							AlertHandler('Proses transaksi berhasil', 'success');
						} catch (e) {
							AlertHandler(e.message, 'error');
						}
					} else props.closeModal();
				};

				const askotp = async notp_ref => {
					var otp = await appAction.frameAction.showModalAsync({
						contentClass: OTPComponent,
						contentProps: {
							_moduleId,
							_authToken: props._authToken,
							tx_ref_number: dsMainProxy.fields?.tx_ref_number,
							otp_ref_number: notp_ref,
							appAction,
						},
						size: 'small',
					});
					if (otp) {
						var { otp, otp_ref_number } = otp;
						if (!otp) return alert('Silakan isi kode OTP');
						var resp = await appAction.postData(_moduleId, 'authorize', props._authToken, {
							tx_ref_number: dsMainProxy.fields?.tx_ref_number,
							current_workflow_state: dsMainProxy.fields?.workflow_state,
							isProcess: true,
							otp,
							otp_ref_number,
						});
						if (resp.isErr) {
							throw new Error(resp.isErr);
						} else if (resp) {
							props.closeModal(true);
							AlertHandler('Proses persetujuan berhasil', 'success');
						}
					}
				};

				const Konfirmasi = async process => {
					await appAction.frameAction.showModal({
						contentClass: p => {
							return (
								<div>
									<div
										style={{
											paddingTop: '2rem',
											paddingBottom: '2rem',
											fontSize: '1.2rem',
										}}
									>
										{p.message}
									</div>
									<div>
										<FormLabel label="Catatan 1">
											<Input
												onKeyUp={e => {
													setState({
														...state,
														comment1: e.target.value,
													});
												}}
											/>
										</FormLabel>
										{/* <FormLabel label="Catatan 2">
											<Input
												onKeyUp={e => {
													setState({
														...state,
														comment2: e.target.value,
													});
												}}
											/>
										</FormLabel> */}
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											marginTop: 30,
										}}
									>
										<Button
											type="bordered"
											style={{
												marginRight: 10,
											}}
											onClick={() => {
												p.closeModal();
											}}
										>
											Batal
										</Button>
										<Button
											onClick={() => {
												p.closeModal();
												if (process) {
													p.handleAuthorize();
												} else p.handleReject();
											}}
										>
											{!process ? 'Tolak' : dsMainProxy.fields?.apprel == 'approver' ? 'Approve' : 'Release'}
										</Button>
									</div>
								</div>
							);
						},
						headerProps: {
							title: 'Konfirmasi' + (process ? ' ' : ' Tolak ') + dsMainProxy.fields?.apprel == 'approver' ? 'Approve' : 'Release',
							icon: 'FaCheck',
						},
						contentProps: {
							message: 'Apakah anda yakin akan ' + (process ? 'memproses' : 'membatalkan') + ' transaksi ini?',
							handleAuthorize,
							handleReject,
							dsMainAction,
							process,
						},
						size: 'small',
					});
				};

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				const AlertHandler = async (msg, type, body) => {
					let alert = await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
					return alert;
				};

				const handleDownload = trx => {
					if (!trx) {
						AlertHandler('Download Gagal', 'warning', 'Silahkan pilih jenis transaksi');
						return false;
					}

					let url = '';
					if (trx === trx_code['TransferRekeningSamaBank']) {
						url = 'TransferRekeningSamaBank';
					} else if (trx === trx_code['TransferBankLain']) {
						url = 'TransferBankLain';
					} else if (trx === trx_code['TransferLuarNegeri']) {
						url = 'TransferLuarNegeri';
					} else if (trx === trx_code['Payment']) {
						url = 'Payment';
					} else if (trx === trx_code['Purchase']) {
						url = 'Purchase';
					} else if (trx === trx_code['PaymentVA']) {
						url = 'PaymentVA';
					}

					// create blob link to download
					const link = document.createElement('a');
					link.href = Template[url];
					link.setAttribute('download', Math.floor(Math.random() * 123456789 + 987654321) + '.template_massal.xlsx');

					// append to html page
					document.body.appendChild(link);

					// force download
					link.click();

					// clean up and remove the link
					link.parentNode.removeChild(link);
				};

				const handleAddButton = React.useCallback(async x => {
					const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
						'transaksi_massal.transaksi_massal.detailTransaksiMassalEditNew',
						{
							tx_code: x.fields.tx_code,
							metode: x.fields.product_code,
							product_code: x.fields.product_code,
							tx_ref_number: x.fields.ref_number,
						},
						{},
						{
							menuId: 'popup_menu',
							menuModuleId: _moduleId,
							key: 'mnuAddDetail',
						},
					);

					if (componentClass && _authToken) {
						const modalshow = await frameAction.showModalAsync({
							headerProps: {
								title: 'Tambah Detail Transaksi Massal',
								icon: 'FaPlusCircle',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
								confirmComponent: confirmComponent,
								tx_code: x.fields.tx_code,
								metode: x.fields.product_code,
								product_code: x.fields.product_code,
								id_tx_draft: props.id_tx_draft,
								tx_ref_number: x.fields.ref_number,
							},
							size: 'large',
						});
						if (!modalshow) {
							console.log('test');
						}
					}
				}, []);

				const confirmComponent = ({ message, closeModal }) => {
					return (
						<div>
							<div
								style={{
									paddingTop: '6rem',
									paddingBottom: '6rem',
									marginBottom: 15,
									fontSize: '1.2rem',
								}}
							>
								{message}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 30,
								}}
							>
								<Button
									type="bordered"
									style={{
										marginRight: 10,
									}}
									onClick={() => closeModal(false)}
								>
									Batal
								</Button>
								<Button onClick={() => closeModal(true)}>Proses</Button>
							</div>
						</div>
					);
				};

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Print Excel',
							onClick: async () => {
								try {
									await appAction.postData(_moduleId, 'importList', props._authToken, { ...params });
								} catch (err) {
									AlertHandler(err.message, 'alert');
								}
							},
							render: () => <FaRegFilePdf />,
						},
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								// handleFilter();
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const handleChangeType = e => {
					if (uploadRef && uploadRef.current) {
						uploadRef.current.value = null;
						setState({
							...state,
							file: '',
						});
					}

					dsMainAction.setFields({
						tx_code: e.target.value,
						product_code: e.target.value === 'TRF425' ? '425' : '',
						metode: '',
						account_no: '',
						account_name: '',
						description: '',
					});
				};

				const handleBatal = async _ => {
					if (window.confirm('apakah anda yakin akan membatalkan transaksi ini?')) {
						try {
							await appAction.postData(_moduleId, 'batalMassal', props._authToken, {
								tx_ref_number: dsMainProxy.fields?.tx_ref_number,
							});
							AlertHandler('Proses pembatalan berhasil', 'success');
							await loadData();
						} catch (e) {
							AlertHandler(e.message, 'error');
						}
					}
				};

				const handleTerjadwal = (terjadwalState = {}) => {
					setState({ ...state, terjadwalState });
				};

				return (
					<div>
						<div>
							<div
								style={{
									display: 'flex',
									width: '-webkit-fill-available',
									justifyContent: 'space-between',
								}}
							>
								<div
									style={{
										width: '-webkit-fill-available',
									}}
								>
									<FormLabel label="No. Ref. Transaksi Massal">
										<b>
											<mainComps.FieldDataDisplay fieldName="tx_ref_number" />
										</b>
									</FormLabel>

									<FormLabel label="Tipe Transaksi">
										{props.uiMode !== 'edit' ? (
											<RadioGroup
												data={state.tx_code}
												display="block"
												name={'type_transaksi_massal'}
												id={'type_transaksi_massal'}
												currentValue={dsMainProxy.fields.tx_code}
												onChange={e => {
													handleChangeType(e);
												}}
											/>
										) : dsMainProxy.fields.tx_code === 'TRF' ? (
											<b>Transfer ke Bank {dsMainProxy.fields.product_code === '425' ? 'BJBS Lain' : 'Lain'} </b>
										) : (
											<b>Pembayaran / Pembelian</b>
										)}
									</FormLabel>

									<FormLabel label="Rekening Sumber">
										{props.uiMode !== 'edit' ? (
											<mainComps.FieldDataInput fieldName="account_no" />
										) : (
											<b>
												<mainComps.FieldDataDisplay fieldName="account_no" />
											</b>
										)}
									</FormLabel>

									<FormLabel label="File Upload">
										<b>{dsMainProxy.fields?.source_file_url && (dsMainProxy.fields?.source_file_url).split('svs')[1]}</b>
									</FormLabel>

									{props.uiMode !== 'edit' ? (
										<>
											<FormLabel>
												<div style={{ display: 'flex' }}>
													<Button
														onClick={() => {
															handleDownload(dsMainProxy.fields.tx_code);
														}}
														type="bordered"
														style={{
															marginRight: 20,
															width: '100%',
														}}
													>
														<FaCloudDownloadAlt /> Download Template
													</Button>
													<Upload
														icon={<FaCloudUploadAlt />}
														id="file_upload"
														name="file_upload"
														accept={['.csv', '.xls', '.xlsx']}
														reference={uploadRef}
														currenImg={state.file?.name}
														onChange={e => {
															if (e?.target?.files?.length > 0) {
																setState({
																	...state,
																	file: e.target.files[0],
																});
															}
														}}
													/>
												</div>
											</FormLabel>
											{/* <FormLabel>
												<TransaksiTerjadwal handleTerjadwal={handleTerjadwal} />
											</FormLabel> */}
										</>
									) : (
										<div>
											<FormLabel label="Status Convert">
												<b>
													{dsMainProxy.fields.conv_status_detail && dsMainProxy.fields.conv_status_detail !== '' ? (
														<mainComps.FieldDataDisplay fieldName="conv_status_detail" />
													) : (
														'-'
													)}
												</b>
											</FormLabel>
											<FormLabel label="Status Inquiry">
												<b>
													{dsMainProxy.fields.status_inquiry && dsMainProxy.fields.status_inquiry !== '' ? (
														<mainComps.FieldDataDisplay fieldName="status_inquiry" />
													) : (
														'-'
													)}
												</b>
											</FormLabel>
											<FormLabel label="Status Close">
												<b>{dsMainProxy.fields.is_closed && dsMainProxy.fields.is_closed !== 'T' ? 'Belum ditutup' : 'Sudah ditutup'}</b>
											</FormLabel>
										</div>
									)}

									<FormLabel label="Keterangan">
										{props.uiMode !== 'edit' ? (
											<mainComps.FieldDataInput fieldName="description" />
										) : (
											<b>
												{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? (
													<mainComps.FieldDataDisplay fieldName="description" />
												) : (
													'-'
												)}
											</b>
										)}
									</FormLabel>
								</div>

								{/* {props.uiMode == 'edit' && dsMainProxy.fields.is_closed !== 'T' ? (
									<Button
										style={{
											width: 250,
										}}
										onClick={() => handleAddButton(dsMainProxy)}
									>
										<FaPlusCircle /> Tambah Detail Transaksi
									</Button>
								) : null} */}
							</div>

							{props.uiMode == 'edit' && state.refnumber !== null ? (
								<NDataBrowser
									stdAppAction={appAction}
									moduleId={_moduleId}
									resourceType="method"
									dataId="getDetailMassal"
									dataProvider={dsetProvider}
									dsetPath="main"
									uiData={editUIData}
									authToken={props._authToken}
									popupComponent={p =>
										dsMainProxy.fields.is_closed !== 'T' ? (
											<state.popupComponent
												{...p}
												confirmComponent={confirmComponent}
												tx_code={dsMainProxy.fields.tx_code}
												metode={dsMainProxy.fields.product_code}
												product_code={dsMainProxy.fields.product_code}
												tx_ref_number={dsMainProxy.fields.ref_number}
												AlertHandler={e => AlertHandler(e)}
											/>
										) : null
									}
									params={{
										id_tx_draft: props.id_tx_draft,
										tx_ref_number: state.refnumber,
									}}
									shownFields={[
										'id_batch_detail',
										'ref_number',
										'tx_ref_number',
										'inquiry_ref_number',
										'credit_account_no',
										'credit_account_name',
										'amount',
										'amount_fee',
										'detail_description',
										'exec_status',
									]}
									filterComponent={({ sortFields, changeParams, params, clearFilter }) => (
										<>
											<Filter>
												<FormLabel label="&nbsp;">
													<FilterActionButtons buttons={ActionButtons(clearFilter)} />
												</FormLabel>
											</Filter>
										</>
									)}
								/>
							) : null}

							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							>
								<>
									{props.uiMode !== 'edit' ? (
										<Button
											onClick={() => {
												handleKonfirmasi();
											}}
											style={{
												marginRight: 10,
											}}
										>
											Konfirmasi
										</Button>
									) : dsMainProxy.fields?.userlog == 'operator' ? (
										<>
											{dsMainProxy.fields.conv_status === 'N' && state.statusConvert !== 'waiting' ? (
												<>
													<Button
														onClick={() => {
															handleConvert();
														}}
														style={{
															marginRight: 10,
														}}
													>
														Convert File
													</Button>
												</>
											) : null}

											{dsMainProxy.fields.is_closed !== 'T' && state.statusConvert !== 'waiting' ? (
												<>
													<Button
														onClick={() => {
															handleClose();
														}}
														style={{
															marginRight: 10,
														}}
													>
														Close Batch
													</Button>
													<Button onClick={handleBatal} type={'bordered'} style={{ marginRight: 5 }}>
														Batal
													</Button>
												</>
											) : null}
										</>
									) : (
										<></>
									)}
									{dsMainProxy.fields.apprel_status === 'true' && (
										<>
											<Button onClick={() => Konfirmasi(true)} style={{ marginRight: 5 }}>
												{dsMainProxy.fields?.apprel == 'approver' ? 'Approve' : 'Release'}
											</Button>{' '}
											<Button onClick={() => Konfirmasi(false)} type={'bordered'} style={{ marginRight: 5 }}>
												Reject
											</Button>
										</>
									)}
									<Button
										onClick={() => {
											appAction.frameAction.closeModal();
										}}
										type="bordered"
									>
										Kembali
									</Button>
								</>
							</div>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
