import React from 'react';
import DatePicker from 'react-datepicker';

const DateFieldDataInput = React.memo(props => {
	const fieldName = props.fieldName;
	const vals = props.fields;
	const fd = props.fieldDefs[fieldName];

	return (
		<DatePicker
			selected={fd.type === 'date' ? vals[fieldName] : null}
			onChange={v => {
				const sDate =
					v instanceof Date
						? `${v.getFullYear().toString().padStart(4, '0')}-${(
								v.getMonth() + 1
						  )
								.toString()
								.padStart(2, '0')}-${v
								.getDate()
								.toString()
								.padStart(2, '0')}`
						: undefined;
				props.setField(fieldName, sDate);
			}}
		></DatePicker>
	);
});

export { DateFieldDataInput };
