/*
  DAFReact framework module - Application form (class)
  appname: listRekening
  filename: listRekeningView.js
  moduleid: listRekeningView
  author: IK
*/

//--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			staticComponents: { FormLabel },
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('manajemen_akun.rekening_favorite.rekeningFavoriteMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			class ListRekeningView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(this.dataContext, metadata, initialData, editUIData);
					this.state = { isErr: false, errMessage: '' };
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});

					try {
						var response = await this.appAction.fetchResource(_moduleId, 'single_data', 'dataSO', this.props._authToken, {
							credit_account_no: this.props.credit_account_no,
							tx_code: this.props.tx_code,
							product_code: this.props.product_code,
						});
						this.dsMainAction.loadStore(response, 'std', serverDataMapping, true);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents({ context: this.dataContext, dsetPath: 'main' }, vcomps);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							<div>
								<div style={{ display: 'flex' }}>
									<FormLabel label="Kode Transaksi" style={{ width: '100%' }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="tx_code" />
										</b>
									</FormLabel>
									<FormLabel label="Kode Produk" style={{ width: '100%' }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="product_code" />
										</b>
									</FormLabel>
								</div>
								<div style={{ display: 'flex' }}>
									<FormLabel label="Rekening Sumber" style={{ width: '100%' }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="account_no" />
										</b>
									</FormLabel>
									<FormLabel label="Rekening Tujuan" style={{ width: '100%' }}>
										<b>
											<mainComps.FieldDataDisplay fieldName="credit_account_no" />
										</b>
									</FormLabel>
								</div>
								<FormLabel label="Nama Favorite" style={{ width: '100%' }}>
									<b>
										<mainComps.FieldDataDisplay fieldName="favorite_name" />
									</b>
								</FormLabel>
								<div style={{ display: this.state.isErr ? 'block' : 'none' }}>{this.state.errMessage}</div>
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return ListRekeningView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
