import React, { useEffect, useState } from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

const templateDefault = (dsMainProxy, mainComps) => {
	return (

							<table>
								<tr>
									<td>No. Transaksi</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>{dsMainProxy.fields.ref_number && dsMainProxy.fields.ref_number !== '' ? <mainComps.FieldDataDisplay fieldName="ref_number" /> : '-'}</b>
									</td>
								</tr>
								<tr>
									<td>Tanggal Input</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>{dsMainProxy.fields.tx_date && dsMainProxy.fields.tx_date !== '' ? <mainComps.FieldDataDisplay fieldName="tx_date" /> : '-'}</b>
									</td>
								</tr>
								<tr>
									<td>Rekening Sumber</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>
											{dsMainProxy.fields.account_no && dsMainProxy.fields.account_no !== '' ? <mainComps.FieldDataDisplay fieldName="account_no" /> : ''} -{' '}
											{dsMainProxy.fields.account_name && dsMainProxy.fields.account_name !== '' ? (
												<mainComps.FieldDataDisplay fieldName="account_name" />
											) : (
												''
											)}
										</b>
									</td>
								</tr>
								<tr>
									<td>Rekening Tujuan</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>
											{dsMainProxy.fields.credit_account_no && dsMainProxy.fields.credit_account_no !== '' ? (
												<mainComps.FieldDataDisplay fieldName="credit_account_no" />
											) : (
												''
											)}{' '}
											-{' '}
											{dsMainProxy.fields.credit_account_name && dsMainProxy.fields.credit_account_name !== '' ? (
												<mainComps.FieldDataDisplay fieldName="credit_account_name" />
											) : (
												''
											)}
										</b>
									</td>
								</tr>
								<tr>
									<td>Jumlah</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>{dsMainProxy.fields.amount && dsMainProxy.fields.amount !== '' ? <mainComps.FieldDataDisplay fieldName="amount" /> : '-'}</b>
									</td>
								</tr>
								<tr>
									<td>Berita</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>
											{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? <mainComps.FieldDataDisplay fieldName="description" /> : '-'}
										</b>
									</td>
								</tr>
								<tr>
									<td>Refference</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>
											{dsMainProxy.fields.external_ref_number && dsMainProxy.fields.external_ref_number !== '' ? (
												<mainComps.FieldDataDisplay fieldName="external_ref_number" />
											) : (
												'-'
											)}
										</b>
									</td>
								</tr>
								<tr>
									<td>Bank Tujuan</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>{dsMainProxy.fields.bank && dsMainProxy.fields.bank !== '' ? <mainComps.FieldDataDisplay fieldName="bank" /> : '-'}</b>
									</td>
								</tr>
								<tr>
									<td>Status</td>
									<td style={{ padding: 5 }}>:</td>
									<td>
										<b>
											{dsMainProxy.fields.exec_status && dsMainProxy.fields.exec_status !== '' ? <mainComps.FieldDataDisplay fieldName="exec_status" /> : '-'}
										</b>
									</td>
								</tr>
							</table>

	)
}

const template070001 = (state) => {
	return (
		
							<>
							<div style={{
								alignContent: 'center'
							}}>
								<table style={{
													borderCollapse: 'collapse'
												}}>
									<tbody>

										<tr>
												<td style={{
													borderCollapse: 'collapse',
													textAlign: 'center'
												}} colSpan='7'>
													STRUK PEMBAYARAN TAGIHAN LISTRIK
												</td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>IDPEL : {state.dataPrint?.data[0]?.tx_data?.bill_info?.id_pelanggan || '-'}</td>
												<td style={{
													borderCollapse: 'collapse'
												}}>BL/TH : {state.dataPrint?.data[0]?.tx_data?.bill_info?.bl_th || '-'}</td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>NAMA : {state.dataPrint?.data[0]?.tx_data?.bill_info?.nama || '-'} </td>
												<td style={{
													borderCollapse: 'collapse'
												}}>STAND METER : {state.dataPrint?.data[0]?.tx_data?.bill_info?.stand_meter || '-'}</td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>TARIF/DAYA : {state.dataPrint?.data[0]?.tx_data?.bill_info?.tarif_daya || '-'}</td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>RP TAG PLN :<span style={{
													color:'red'
												}}> Rp. {state.dataPrint?.data[0]?.tx_data?.bill_info?.rp_tag_pln || '-'}</span></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>NO REF *{')'} : <span> {state.dataPrint?.data[0]?.tx_data?.bill_info?.no_ref || '-'}</span></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<th style={{
													borderCollapse: 'collapse'
												}} colSpan='7' rowspan='2'>PLN menyatakan struk ini sebagai bukti pembayaran yang sah</th>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>ADMIN CA *{')'} :Rp. <span> {state.dataPrint?.data[0]?.tx_data?.bill_info?.admin_ca || '-'}</span></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}>TOTAL BAYAR :<span style={{
													color:'red'
												}}>Rp.  {state.dataPrint?.data[0]?.tx_data?.bill_info?.total_bayar || '-'}</span></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
												<td style={{
													borderCollapse: 'collapse'
												}}></td>
										</tr>
										<tr>
												<th style={{
													borderCollapse: 'collapse',
													color:'red'
												}} colSpan='7'>Anda masih memiliki sisa tunggakan  {state.dataPrint?.data[0]?.tx_data?.bill_info?.tunggakan || '-'} bulan </th>
										</tr>
										<tr>
												<th style={{
													borderCollapse: 'collapse',
													color:'red'
												}} colSpan='7'>Rincian tagihan dapat diakses di www.pln.co.id </th>
										</tr>
										<tr>
												<th style={{
													borderCollapse: 'collapse',
													color:'red'
												}} colSpan='7'>Informasi Hubungi Contact Center : 123</th>
										</tr>
										<tr>
												<th style={{
													borderCollapse: 'collapse',
													color:'red'
												}} colSpan='7'>Atau Hub. PLN Terdekat:[no.telp Rayon]*{')'}</th>
										</tr>
									</tbody>
								</table>
							</div>
							</>
	)
}

const template070002 = (state) => {
	let normalStyle = {
		borderCollapse: 'collapse',
		fontFamily: 'Courier New',
	}
	return (
		<div style={{
			alignContent: 'center'
		}}>
			<table style={normalStyle}>
				<tbody>
					<tr>
							<th style={normalStyle} colSpan='7'>STRUK PEMBELIAN LISTRIK PRABAYAR</th>
					</tr>
					<tr>
							<td style={normalStyle}></td>
							<td style={normalStyle}></td>
					</tr>
					<tr>
							<td style={normalStyle}>NO METER : {state.dataPrint?.data[0]?.tx_data?.bill_info?.nomor_meter || '-'}</td>
							<td style={normalStyle}>MATERAI : <span>Rp. {state.dataPrint?.data[0]?.tx_data?.bill_info?.materai || '-'}</span></td>
					</tr>
					<tr>
							<td style={normalStyle}>IDPEL : <span>{state.dataPrint?.data[0]?.tx_data?.bill_info?.id_pelanggan || '-'}</span></td>
							<td style={normalStyle}>PPN : <span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.ppn || '-'}</span></td>
					</tr>
					<tr>
							<td style={normalStyle}>NAMA : {state.dataPrint?.data[0]?.tx_data?.bill_info?.nama || '-'}</td>
							<td style={normalStyle}>PPJ : <span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.ppj || '-'}</span></td>
					</tr>
					<tr>
							<td style={normalStyle}>TARIF/DAYA : {state.dataPrint?.data[0]?.tx_data?.bill_info?.tarif_daya || '-'}</td>
							<td style={normalStyle}>ANGSURAN :<span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.angsuran || '-'}</span></td>
					</tr>
					<tr>
							<td style={normalStyle}>NO REF *{')'} : <span>{state.dataPrint?.data[0]?.tx_data?.bill_info?.no_ref || '-'}</span></td>
							<td style={normalStyle}>RP STROOM/TOKEN : <span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.rp_stroom || '-'}</span></td>
					</tr>
					<tr>
							<td style={normalStyle}>RP BAYAR:<span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.rp_bayar || '-'}</span></td>
							<td style={normalStyle}>JML KWH : {state.dataPrint?.data[0]?.tx_data?.bill_info?.kwh || '-'}</td>
					</tr>
					<tr>
							<td style={normalStyle}></td>
							<td style={normalStyle}>ADMIN CA*{')'}<span>Rp.{state.dataPrint?.data[0]?.tx_data?.bill_info?.admin_ca || '-'}</span></td>
					</tr>
					<tr>
							<th style={normalStyle} colSpan='7'>STROOM/TOKEN : {state.dataPrint?.data[0]?.tx_data?.bill_info?.token || '-'}</th>
					</tr>
					<tr>
							<td style={normalStyle}></td>
							<td style={normalStyle}></td>
					</tr>
					<tr>
							<th style={normalStyle} colSpan='7'>Informasi hubungi contct center 123</th>
					</tr>
					<tr>
							<th style={normalStyle} colSpan='7'>Atau</th>
					</tr>
					<tr>
							<th style={normalStyle} colSpan='7'>Hub. PLN Terdekat </th>
					</tr>
					<tr>
							<td style={normalStyle}></td>
							<td style={normalStyle}></td>
					</tr>
				</tbody>
			</table>
	</div>
	)
}

const template070003 = (state) => {
	let styleNormal = {
		borderCollapse: 'collapse',
		fontFamily: 'Courier New',
	}
	let redColor = {
		color:'red'
	}
	return (
		
	<div style={{
		alignContent: 'center'
	}}>
		<table style={styleNormal}>
			<tbody>
				<tr>
						<td style={styleNormal}>
							STRUK NON TAGIHAN LISTRIK
						</td>
				</tr>
				<tr>
						<td style={styleNormal}></td>
				</tr>
				<tr>
						<td style={styleNormal}>TRANSAKSI : {'*)'}</td>
				</tr>
				<tr>
						<td style={styleNormal}>NOMOR REGISTRASI : {state.dataPrint?.data[0]?.tx_data?.bill_info?.nomor_registrasi || '-'}</td>
				</tr>
				<tr>
						<td style={styleNormal}>TANGGAL REGISTRASI : {state.dataPrint?.data[0]?.tx_data?.bill_info?.tgl_registrasi || '-'}</td>
				</tr>
				<tr>
						<td style={styleNormal}>NAMA : {state.dataPrint?.data[0]?.tx_data?.bill_info?.nama || '-'} </td>
				</tr>
				<tr>
						<td style={styleNormal}>IDPEL : {state.dataPrint?.data[0]?.tx_data?.bill_info?.id_pelanggan || '-'}</td>
				</tr>
				<tr>
						<td style={styleNormal}>BIAYA PLN :<span style={redColor}> Rp. {state.dataPrint?.data[0]?.tx_data?.bill_info?.rp_tag_pln || '-'}</span></td>
				</tr>
				<tr>
						<td style={styleNormal}>NO REF {'**)'} : <span> {state.dataPrint?.data[0]?.tx_data?.bill_info?.no_referensi || '-'}</span></td>
				</tr>
				<tr>
						<td style={styleNormal}></td>
				</tr>
				<tr>
						<th style={styleNormal}>PLN menyatakan struk ini sebagai bukti pembayaran yang sah</th>
				</tr>
				<tr>
						<td style={styleNormal}></td>
				</tr>
				<tr>
						<td style={styleNormal}>ADMIN CA {'**)'} :Rp. <span> {state.dataPrint?.data[0]?.tx_data?.bill_info?.admin_ca || '-'}</span></td>
				</tr>
				<tr>
						<td style={styleNormal}>TOTAL BAYAR :<span style={redColor}>Rp.  {state.dataPrint?.data[0]?.tx_data?.bill_info?.total_bayar || '-'}</span></td>
				</tr>
				<tr>
						<td style={styleNormal}></td>
				</tr>
				<tr>
						<th style={{...styleNormal, ...redColor}}>Rincian tagihan dapat diakses di www.pln.co.id </th>
				</tr>
				<tr>
						<th style={{...styleNormal, ...redColor}}>Informasi Hubungi Contact Center : 123</th>
				</tr>
				<tr>
						<th style={{...styleNormal, ...redColor}}>Atau Hub. PLN Terdekat</th>
				</tr>
			</tbody>	
		</table>
	</div>
	)
}


export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				PageTitle,
				Button,
				saveAs,
				FormLabel,
				Dropdown,
				AlertComponent,
				TransaksiTerjadwal,
				ReactIconFa: { FaPrint },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('manajemen_akun.histori_transaksi.historiTransaksiMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					rekening: [],
					selectRekening: '',
					selectCommerce: '',
					terjadwalState: {},
					dataPrint:{},
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');
				const [today, setToday] = useState(false)

				// load data function
				const loadData = React.useCallback(async () => {
					var prevState = state;
					setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});
					try {
						var response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, {
							id_transaction: params.id_transaction,
						});
						setState({
							...prevState,
							dataPrint: response,
						});
						dsMainAction.loadStore(response, 'std', serverDataMapping, true);
					} catch (err) {
						setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}, [dsMainAction, props._authToken, props.id_tx_draft]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({});
						loadData();
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleCetak = async () => {
					try {
						await appAction.postData(_moduleId, 'cetakResi', props._authToken, { ...params, ...dsMainProxy.fields });
						// const response = await appAction.postData(_moduleId, 'cetakResi', props._authToken, { ...params, ...dsMainProxy.fields });
						// const blob = new Blob([response], { type: 'application/pdf' });
						// saveAs(blob, 'resi_historitransaksi_' + dsMainProxy.fields.ref_number + '_' + dsMainProxy.fields.tx_date + '.pdf');
						// props.closeModal(true);
						AlertHandler('Cetak resi berhasil', 'success');
					} catch (err) {
						props.closeModal(true);
						AlertHandler(err.message, 'gagal');
					}
				};

				const handleCetakMPN = async () => {
					try {
						const resp = await appAction.postData(_moduleId, 'cetakResiMPN', props._authToken, { ...params, ...dsMainProxy.fields });
						AlertHandler('Cetak resi berhasil', 'success');
					} catch (err) {
						props.closeModal(true);
						AlertHandler(err.message, 'gagal');
					}
				};

				const handleTagihanPLN = async () => {
					try {
						// console.log(state.dataPrint.data[0].tx_data)
						const resp = await appAction.postData(_moduleId, 'cetakResiTagihanPLN', props._authToken, { ...params, ...state.dataPrint.data[0].tx_data.bill_info });
						AlertHandler('Cetak resi berhasil', 'success');
					} catch (err) {
						props.closeModal(true);
						AlertHandler(err.message, 'gagal');
					}
				};

				const handlePLN70003 = async () => {
					try {
						// console.log(state.dataPrint.data[0].tx_data)
						const resp = await appAction.postData(_moduleId, 'cetakResi70003', props._authToken, { ...params, ...state.dataPrint.data[0].tx_data.bill_info });
						AlertHandler('Cetak resi berhasil', 'success');
					} catch (err) {
						props.closeModal(true);
						AlertHandler(err.message, 'gagal');
					}
				};

				const handlePembelianPLN = async () => {
					try {
						// console.log(state.dataPrint.data[0].tx_data.bill_info)
						const resp = await appAction.postData(_moduleId, 'cetakResiPembelianPLN', props._authToken, { ...params, ...state.dataPrint.data[0].tx_data.bill_info });
						AlertHandler('Cetak resi berhasil', 'success');
					} catch (err) {
						props.closeModal(true);
						AlertHandler(err.message, 'gagal');
					}
				};
				useEffect(()=> {
					let tx_date = dsMainProxy.fields.tx_date
					tx_date = new Date(tx_date).toLocaleDateString()
					let newDate = new Date().toLocaleDateString()
					setToday(tx_date == newDate)

				}, [dsMainProxy])

				let template = templateDefault(dsMainProxy, mainComps)

				var isPLN = false
				
				// comment this if you dont want to use pln template
				isPLN = true

				if(isPLN){
					if(dsMainProxy.fields.product_code === '070001'){
						template = template070001(state)
					} else if(dsMainProxy.fields.product_code === '070002'){
						template = template070002(state)
					} else if(dsMainProxy.fields.product_code === '070003'){
						template = template070003(state)
					}
				}
				// console.log(state)
				return (
					<div>
						<div
							style={{
								borderStyle: 'double',
								padding: 10,
							}}
						>
						{template}
							<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
						</div>
						<br />
						<div className="d-flex justify-content-center">
							{["070001","070002","070003"].includes(dsMainProxy.fields.product_code) ? null : (
								<Button
									onClick={() => {
										handleCetak();
									}}
								>
									<FaPrint /> Cetak
								</Button>
							)}
							{dsMainProxy.fields.tx_code === 'MPN' ? (
								<Button style={{ marginLeft: '1rem' }} onClick={() => handleCetakMPN()}>
									<FaPrint /> Cetak Resi Pajak
								</Button>
							) : null}
							{/* PLN Non-Taglis */}
							{(dsMainProxy.fields.product_code === '070001' && today) ? (
								<Button style={{ marginLeft: '1rem' }} onClick={() => handleTagihanPLN()}>
									<FaPrint /> Cetak Resi PLN Tagihan
								</Button>
							) : null}
							{/* Prabayar */}
							{(dsMainProxy.fields.product_code === '070002' && today) ? (
								<Button style={{ marginLeft: '1rem' }} onClick={() => handlePembelianPLN()}>
									<FaPrint /> Cetak Resi PLN Pembelian
								</Button>
							) : null}
							{/* Pascabayar */}
							{(dsMainProxy.fields.product_code === '070003' && today) ? (
								<Button style={{ marginLeft: '1rem' }} onClick={() => handlePLN70003()}>
									<FaPrint /> Cetak Resi PLN Tagihan
								</Button>
							) : null}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
