import React from 'react';
import './table.scss';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const Table = ({ dataModel = {}, datas = [], onRowClick, rowButtons }) => {
	const renderTrComponent = () =>
		datas.map((i, k) => (
			<tr key={k} onClick={onRowClick ? () => onRowClick(i) : null}>
				<td></td>
				{rowButtons && rowButtons() !== false && (
					<td key="_td000" style={{ textAlign: 'center' }}>
						{rowButtons(i)}
					</td>
				)}
				{Object.keys(dataModel).map((ii, kk) => {
					var type = dataModel[ii].type;
					var key = dataModel[ii].name;
					var fd = dataModel[ii].fd;
					var uiData = dataModel[ii].uiData || {};

					const { numformatted, thousand, decimal, dateformat, custFunc = null } = uiData;

					var value = i[key] || i[key] === 0 ? i[key] : '-';
					
					if (custFunc && typeof custFunc == 'function') return <td key={kk} style={{ position: 'relative' }}>{custFunc(i[key], i)}</td>;

					// var momentinstatnce = moment(value).utcOffset(7)
					var momentinstatnce = moment(value)

					return (
						<td key={kk}>
							{type === 'date'
								? momentinstatnce.format(dateformat || 'llll')
								: fd.asString(value, {
										numformatted,
										thousand,
										decimal,
								  })}
						</td>
					);
				})}
			</tr>
		));

	const renderNoItem = () => (
		<tr>
			<td></td>
			<td colSpan={Object.keys(dataModel).length + 1}>No data found...</td>
		</tr>
	);

	return (
		<table className={'table'}>
			<thead>
				<tr>
					<th></th>
					{rowButtons && rowButtons() !== false && (
						<th key="_th000" style={{ textAlign: 'center' }}>
							Aksi
						</th>
					)}
					{Object.keys(dataModel).map((i, k) => (
						<th key={'_th' + k}>{dataModel[i].label || '-'}</th>
					))}
				</tr>
			</thead>
			<tbody>{datas.length > 0 ? renderTrComponent() : renderNoItem()}</tbody>
		</table>
	);
};

export default Table;
