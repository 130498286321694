/*
  DAFReact framework module - Application form (hook)
  appname: listTransaksiVA
  filename: listTransaksiVAList.js
  moduleid: listTransaksiVAList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			staticComponents: {
				RadioGroup,
				Dropdown,
				FormLabel,
				DatePicker,
				Input,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconFa: { FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
				dataselector,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'manajemen_va.list_transaksi_va.listTransaksiVAMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					status: '',
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						// {
						// 	type: 'bordered',
						// 	name: 'Print Excel',
						// 	onClick: () => {

						// 	},
						// 	render: () => <SiMicrosoftexcel />,
						// },
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								handleFilter();
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				return (
					<>
						<PageTitle
							title="Histori Transaksi VA"
							subtitle="Manajemen VA"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							resourceType="method"
							dataId="listSO"
							autoLoad={false}
							dataProvider={dsetProvider}
							authToken={props._authToken}
							params={filter}
							dsetPath="main"
							uiData={editUIData}
							rowButtons={false}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<>

										<Filter>
											{/* Tanggal */}
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={
															filter.start_date
														}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div
														style={{ padding: 10 }}
													>
														s.d.
													</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Sort & Search */}
											<FormLabel label="Urutkan & Cari">
												<div
													style={{ display: 'flex' }}
												>
													<Dropdown
														style={{
															width: '250px',
														}}
														onChange={e =>
															changeParams({
																sortField:
																	e.target
																		.value,
															})
														}
														item={sortFields}
														value={params.sortField}
													/>
													<SearchBox
														label="Tampilkan"
														style={{
															marginLeft: 5,
															width: '250px',
														}}
														handleSearch={src => {
															changeParams({
																start_date:
																	filter.start_date,
																end_date:
																	filter.end_date,
																src: src,
															});
														}}
														srcText={params.src}
													/>
												</div>
											</FormLabel>

											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons(
														clearFilter,
													)}
												/>
											</FormLabel>
										</Filter>
									</>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
