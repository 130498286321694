import React from 'react';
import Checkbox from '../../app_components/Forms/Checkbox/Checkbox';
import FormLabel from '../../app_components/Forms/FormLabel/FormLabel';

const RekeningFavorite = (props) => {
    const mainComps = props.mainComps
    return (
        <div >
            <Checkbox
                label="Simpan Sebagai Favorit"
                reference={props.reference}
                onChange={(e) => {
                    props.dsMainAction.setFields({
                        favorite: e.target.checked
                    });
                }
                }
            />
            {props.dsMainProxy.fields.favorite == 'true' ? (
                <FormLabel label="Nama Favorit">
                    <mainComps.FieldDataInput fieldName="favorite_name" />
                </FormLabel>
            ) : null}
        </div>
    );
};

export default RekeningFavorite;
