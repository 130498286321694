import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				AlertComponent,
				PageTitle,
				Button,
				FormLabel,
				RadioGroup,
				ReactIconFa: {},
				TransaksiTerjadwal,
				RekeningFavorite,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('transfer.rekening_bank_lain.rekeningBankLainMetadata');

		const componentClass = await appAction.fetchAndExecModule('transfer.rekening_bank_lain.rekeningBankLainKonfirmasi');

		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					step: 0,
					tx_code: 'TRF',
					is_rtgs: 'ONLINE',
					cost_by: 'sender',
					terjadwalState: {},
					isVa: false,
					metodeKirim: {
						ONLINE: 'ONLINE',
					},
				});

				const favref = React.useRef(null);

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				React.useEffect(() => {
					(async function () {
						const { isVa } = await appAction.getState();

						dsMainAction.addRow({
							tx_code: 'TRF',
							is_rtgs: 'ONLINE',
							cost_by: 'sender',
							purpose_code: '1002',
						});

						if (!isVa)
							var metodeKirim = {
								ONLINE: 'ONLINE',
								SKN: 'SKN',
								RTGS: 'RTGS',
								BIFAST: 'BI FAST',
							};
						else
							var metodeKirim = {
								ONLINE: 'ONLINE',
							};

						setState({ ...state, isVa, metodeKirim });
					})();
				}, [dsMainAction, props.uiMode]);

				const handleValidation = () => {
					const { fieldValidStates, fieldValidErrors } = dsMainProxy;
					const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
						return !v;
					});

					if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);
				};

				const handleConfirm = async () => {
					try {
						handleValidation();

						if (componentClass) {
							var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
								includeLoadedRows: false,
								includeDeletedRows: true,
							});

							if (dataUnload.data.length > 0) {
								dataUnload.data[0]['terjadwal'] = state.terjadwalState;
								dataUnload.data[0]['tx_code'] = 'TRF';
								if ((dataUnload.data[0].is_rtgs === 'SKN' || dataUnload.data[0].is_rtgs === 'RTGS') && dataUnload.data[0].credit_account_name === '') {
									throw new Error('Nama Rekening Tujuan tidak boleh kosong');
								}
							}

							const modalshow = await frameAction.showModalAsync({
								headerProps: {
									title: 'Konfirmasi Transfer',
									icon: 'FaInfoCircle',
								},
								contentClass: componentClass,
								contentProps: {
									_moduleId,
									_authToken: props._authToken,
									data: dataUnload,
								},
								size: 'medium',
							});
							if (modalshow) {
								dsMainAction.deleteRow();
								dsMainAction.addRow({
									tx_code: 'TRF',
									is_rtgs: 'ONLINE',
									cost_by: 'sender',
									purpose_code: '1002',
								});
								setState({ ...state, isErr: false, errMessage: '', step: 0, tx_code: 'TRF', is_rtgs: 'ONLINE', cost_by: 'sender', terjadwalState: {} });

								favref.current.checked = false;
							}
						}
					} catch (error) {
						AlertHandler(error.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleTerjadwal = (terjadwalState = {}) => {
					setState({ ...state, terjadwalState });
				};

				return (
					<div>
						<PageTitle subtitle="Transfer Dana" title="Rekening Bank Lain" style={{ marginBottom: `1.5rem` }}></PageTitle>

						<div>
							<FormLabel label="Rekening Sumber">
								<mainComps.FieldDataInput fieldName="account_no" />
							</FormLabel>

							<FormLabel label="Metode kirim">
								<RadioGroup
									data={Object.entries(state.metodeKirim)}
									name={'is_rtgs'}
									currentValue={dsMainProxy.fields.is_rtgs}
									type={'icon'}
									varian={'bordered'}
									onChange={e => {
										var cost_by = {};
										if (e.target.value == 'ONLINE' || e.target.value == 'BIFAST') {
											cost_by = { cost_by: 'sender' };
											setState({ ...state, ...cost_by });
										}
										dsMainAction.setFields({
											is_rtgs: e.target.value,
											kode_bank_tujuan: null,
											kode_bank_tujuan_name: null,
											credit_account_no: null,
											credit_account_name: null,
											...cost_by,
										});
									}}
								/>
							</FormLabel>

							<div style={{ display: 'flex' }}>
								<FormLabel label="Kode Bank" style={{ width: '-webkit-fill-available' }}>
									<mainComps.FieldDataInput elProps={{ readOnly: true }} fieldName="kode_bank_tujuan" />
								</FormLabel>
								<div style={{ marginRight: 10 }}></div>
								<FormLabel label="Nomor Rekening Tujuan" style={{ width: '-webkit-fill-available' }}>
									<mainComps.FieldDataInput fieldName="credit_account_no" />
								</FormLabel>
							</div>

							{(dsMainProxy.fields.is_rtgs == 'SKN' || dsMainProxy.fields.is_rtgs == 'RTGS') && (
								<>
									<FormLabel label="Nama Penerima">
										<mainComps.FieldDataInput
											fieldName="credit_account_name"
											elProps={(() => {
												var is_rtgs = dsMainProxy.fields?.is_rtgs;
												var accName = dsMainProxy.fields?.credit_account_name;
												return {
													errors:
														(is_rtgs == 'SKN' || is_rtgs == 'RTGS') && !accName
															? {
																	isError: true,
																	errorMessage: 'Silakan isi nama penerima',
															  }
															: {},
												};
											})()}
										/>
									</FormLabel>
									<FormLabel label="Biaya dibebankan pada">
										<RadioGroup
											data={Object.entries({
												sender: 'Pengirim',
												receiver: 'Penerima',
											})}
											name={'cost_by'}
											currentValue={state.cost_by}
											type={'icon'}
											varian={'bordered'}
											onChange={e => {
												setState({
													...state,
													cost_by: e.target.value,
												});
												dsMainAction.setField('cost_by', e.target.value);
											}}
										/>
									</FormLabel>
								</>
							)}

							{dsMainProxy.fields.is_rtgs == 'BIFAST' && (
								<FormLabel label="Tujuan Transaksi">
									<mainComps.FieldDataInput fieldName="purpose_code" />
									{/* <RadioGroup
										data={Object.entries({
											'01': 'ONLINE',
											'02': 'SKN',
											'03': 'RTGS',
											'04': 'BI FAST',
										})}
										name={'purpose_code'}
										currentValue={dsMainProxy.fields.purpose_code}
										type={'icon'}
										varian={'bordered'}
										onChange={e => {
											dsMainAction.setFields({
												purpose_code: e.target.value
											});
										}}
									/> */}
								</FormLabel>
							)}

							<FormLabel label="Nominal yang dikirim">
								<mainComps.FieldDataInput fieldName="amount" />
							</FormLabel>
							<FormLabel label="Berita">
								<mainComps.FieldDataInput fieldName="description" />
							</FormLabel>
							<FormLabel label="No. Dokumen">
								<mainComps.FieldDataInput fieldName="external_ref_number" />
							</FormLabel>

							{/* <FormLabel label="Tags">
								<mainComps.FieldDataInput fieldName="tags" />
							</FormLabel> */}

							<TransaksiTerjadwal handleTerjadwal={handleTerjadwal} />

							<RekeningFavorite {...props} mainComps={mainComps} dsMainAction={dsMainAction} dsMainProxy={dsMainProxy} reference={favref} />

							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							>
								<Button
									onClick={() => {
										handleConfirm();
									}}
								>
									Konfirmasi
								</Button>
							</div>

							<div
								style={{
									display: state.isErr ? 'block' : 'none',
								}}
							>
								{state.errMessage}
							</div>
						</div>
					</div>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
