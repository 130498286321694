import React from 'react';
import './PageTitle.scss';

const PageTitle = ({ title = '', subtitle = '', body = '', children }) => {
	return (
		<div className="page-title">
			<div>
				<div className="subtitle">{subtitle}</div>
				<div className="title">{title}</div>
				{body && <div>{body}</div>}
			</div>
			{children && <div>{children}</div>}
		</div>
	);
};

export default PageTitle;
