import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				trf: {
					fields: [
						// trf

						{
							name: 'id_transaction',
							type: 'string',
							title: 'id_transaction',
							length: 20,
						},
						{
							name: 'currencyAsal',
							type: 'string',
							title: 'currencyAsal',
							length: 20,
						},
						{
							name: 'currencyTujuan',
							type: 'string',
							title: 'currencyTujuan',
							length: 20,
						},
						{
							name: 'kurs',
							type: 'float',
							title: 'Kode Bank',
							length: 20,
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Rekening Sumber',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Rekening Sumber');
								return check;
							},
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening Sumber',
							length: 50,
						},
						{
							name: 'kode_negara_tujuan',
							type: 'string',
							title: 'Kode Negara',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(
									value,
									'Kode Negara Tujuan',
								);
								return check;
							},
						},
						{
							name: 'nama_negara_tujuan',
							type: 'string',
							title: 'Nama Negara',
							length: 20,
						},
						{
							name: 'kode_bank_tujuan',
							type: 'string',
							title: 'Kode Bank',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Kode Bank');
								return check;
							},
						},
						{
							name: 'credit_account_no',
							type: 'string',
							title: 'Rekening Tujuan',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Rekening Tujuan');
								return check;
							},
						},
						{
							name: 'credit_account_name',
							type: 'string',
							title: 'Nama Rekening Tujuan',
							length: 50,
							validator: (value, fieldName) => {
								let check = isExist(
									value,
									'Nama Rekening Tujuan',
									4, 50
								);
								return check;
							},
						},
						{
							name: 'amount1',
							type: 'float',
							title: 'Nominal',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Nominal');
								return check;
							},
						},
						{
							name: 'amount2',
							type: 'float',
							title: 'Jumlah',
							length: 20,
						},
						{
							name: 'amount_fee',
							type: 'float',
							title: 'Biaya',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Biaya Transfer');
								return check;
							},
						},
						{
							name: 'amount_net',
							type: 'float',
							title: 'Biaya',
							length: 20,
						},
						{
							name: 'description',
							type: 'string',
							title: 'Berita',
							length: 160,
							regex: /[^a-zA-Z0-9 .]/g, // only alphanumeric and space
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'Kode Transaksi',
							length: 20,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'Kode Produk',
							length: 20,
						},
						{
							name: 'tx_type',
							type: 'string',
							title: 'Jenis Transaksi',
							length: 15,
						},
						{
							name: 'date',
							type: 'date',
							title: 'Tanggal',
							length: 15,
						},
						{
							name: 'freq',
							type: 'string',
							title: 'Setiap',
							length: 15,
						},
						{
							name: 'recurring_type',
							type: 'string',
							title: 'Tipe',
							length: 15,
						},
						{
							name: 'at_date',
							type: 'date',
							title: 'Pada Tanggal',
							length: 15,
						},
						{
							name: 'start_date',
							type: 'date',
							title: 'Mulai Tanggal',
							length: 15,
						},
						{
							name: 'end_date',
							type: 'date',
							title: 'Sampai Tanggal',
							length: 15,
						},
						{
							name: 'desc',
							type: 'string',
							title: 'Keterangan',
							length: 30,
						},
						{
							name: 'favorite',
							type: 'string',
							title: 'favorite',
							length: 10,
						},
						{
							name: 'favorite_name',
							type: 'string',
							title: 'favorite_name',
							length: 50,
						},

					],

					indexes: ['id_transaction'],
				},
			};

			const initialData = {
				'main:trf': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'trf',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				trf: {
					fields: {
						kurs: { decimal: 6 },
						amount1: { thousand: true, decimal: 2 },
						amount2: { thousand: true, decimal: 2 },
						amount_fee: { thousand: true, decimal: 2 },
						amount_net: { thousand: true, decimal: 2 },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
