import React from 'react';

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			NDataBrowser,
			dsetProvider,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { FilterActionButtons ,Filter, Button, FormLabel, SearchBox, Dropdown, AlertComponent, ViewTransaksiTerjadwal,ReactIconFa: { FaRetweet }, },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('manajemen_transaksi.transaksi_terjadwal.transaksiTerjadwalMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
				});
				const initialFilter = {};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const loadData = React.useCallback(async () => {
					var prevState = state;
					setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});
					try {
						var response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, {
							id_tx_draft: props.id_tx_draft,
						});
						dsMainAction.loadStore(response, 'std', serverDataMapping, true);
					} catch (err) {
						setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}, [dsMainAction, props._authToken, props.id_tx_draft]);

				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({});
						loadData();
						const { role } = await appAction.getState();
						setState({ ...state, role });
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initialFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				const handleCancelTerjadwal = async _ => {
					if (window.confirm('Apakah anda yakin akan membatalkan / menghentikan transaksi ini?')) {
						try {
							await appAction.postData(_moduleId, 'batalTerjadwal', props._authToken, { tx_ref_number: dsMainProxy.fields?.tx_ref_number });

							props.closeModal();
							AlertHandler('Transaksi dibatalkan', 'success');
						} catch (err) {
							AlertHandler(err.message, 'error');
						}
					}
				};

				return (
					<div>
						<div style={{ display: 'flex' }}>
							<FormLabel label="No. Ref. Transaksi" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="tx_ref_number" />
								</b>
							</FormLabel>
							<FormLabel label="Tanggal Input" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="entry_datetime" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Dari Rekening" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="account_no" />
								</b>
							</FormLabel>
							<FormLabel label="Keterangan" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="description" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Ke Rekening" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="credit_account_no" />
								</b>
							</FormLabel>
							<FormLabel label="Nama Rekening Tujuan" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="credit_account_name" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Jumlah" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="amount" />
								</b>
							</FormLabel>
							<FormLabel label="Biaya" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="amount_fee" />
								</b>
							</FormLabel>
						</div>
						<div style={{ display: 'flex' }}>
							<FormLabel label="Tanggal Eksekusi" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="sched_exec_datetime" />
								</b>
							</FormLabel>
							<FormLabel label="Status Eksekusi" style={{ width: '100%' }}>
								<b>
									<mainComps.FieldDataDisplay fieldName="execution_desc" />
								</b>
							</FormLabel>
						</div>
						<ViewTransaksiTerjadwal
							terjadwalState={{
								tx_type: dsMainProxy.fields.sched_period == 'X' ? 'date' : 'recurring',
								value: {
									sched_period: dsMainProxy.fields.sched_period,
									sched_day: dsMainProxy.fields.sched_day,
									sched_date: dsMainProxy.fields.sched_date,
									sched_count: dsMainProxy.fields.sched_count,
									sched_exec_datetime: dsMainProxy.fields.sched_exec_datetime,
								},
							}}
						/>
						<div>
							{state.role === 'releaser' && dsMainProxy.fields.sched_status != 'C' && dsMainProxy.fields.execution_status != 'C' && (
								<Button onClick={() => handleCancelTerjadwal()}>Hentikan / Batalkan Terjadwal</Button>
							)}
						</div>
						<hr />
						<div style={{ overflow: 'auto' }}>
						<h4>Histori Transaksi</h4>
							<NDataBrowser
								stdAppAction={appAction}
								moduleId={_moduleId}
								resourceType="scroll_query"
								dataId="listSO"
								dataProvider={dsetProvider}
								dsetPath="main"
								authToken={props._authToken}
								params={{ ref_number: props.tx_ref_number}}
								autoLoad={true}
								uiData={{
									main: {
										fields: {
											amount: { thousand: true, decimal: 2 },
										},
									},
								}}
								filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
									return (
										<Filter>
											<FormLabel label="Urutkan">
												<Dropdown
													onChange={e =>
														changeParams({
															sortField: e.target.value,
														})
													}
													item={sortFields}
													value={params.sortField}
													placeholder="--Pilih urutan--"
												/>
											</FormLabel>

											<FormLabel label="Cari">
												<SearchBox
													style={{
														width: 400,
													}}
													handleSearch={src =>
														changeParams({
															src,
														})
													}
													srcText={params.src}
												/>
											</FormLabel>

											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</Filter>
									);
								}}
							/>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
					</div>
				);
			}
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
