import React from 'react';
import Button from '../../app_components/Button/Button';
import { useState } from 'react';
import './Upload.scss';

export default function Upload({
	onChange,
	size = 'small',
	id = '',
	name = '',
	icon = 'icon',
	url,
	reference,
	description,
	...otherProps
}) {
	let [namet, setNamet] = useState('No File');
	let [sized, setSize] = useState(0);
	let [preview, setPreview] = useState('');

	const handlePreview = e => {
		let files = e;
		let reader = new FileReader();
		reader.onload = r => {
			setPreview(r.target.result);
		};
		let res = reader.readAsDataURL(files[0]);
		// console.log(res)
	};


	return (
		<>
			<div className="dividers" style={{}}>
				<input
					ref={reference}
					type="file"
					name={'file' + (name ? name : null) + (id ? id : null)}
					id={'file' + (name ? name : null) + (id ? id : null)}
					className="inputfile"
					onChange={e => {
						if (e?.target?.files?.length > 0) {
							setNamet(e.target.files[0].name);
							setSize(parseFloat(e.target.files[0].size) / 1000)
							handlePreview(e.target.files);
							onChange(e);
						}
					}}
					{...otherProps}
				/>
				<label
					htmlFor={'file' + (name ? name : null) + (id ? id : null)}
					style={{ padding: 10, borderRadius: 2.5 }}
				>
					{icon} Choose a image
				</label>
				<label style={{ paddingLeft: 10 }}>{namet}{sized > 0 ? ' - (' + sized + ' kB)' : ''}</label>
			</div>
			{description ? <p><i>{description}</i></p> : null}
			{preview ? <img className="preview-image" src={preview} alt="preview" /> : url && url !== '' ? <img className="preview-image" src={url} alt="preview" /> : null}
		</>
	);
}
