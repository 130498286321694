import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports( React, globals ) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if ( !_moduleId ) {
			throw new Error( '_moduleId not defined' );
		}

		function componentFactory( params ) {
			const metadata = {
				plnPrepaidAdvice: {
					fields: [
						// pln prepaid advice
						{
							name: 'id_transaction',
							type: 'string',
							title: 'id_transaction',
							length: 20,
						},
						{
							name: 'b48',
							type: 'string',
							title: 'b48',
							length: 100,
						},
						{
							name: 'ref_no',
							type: 'string',
							title: 'ref_no',
							length: 100,
						},
						{
							name: 'txdraft_ref_number',
							type: 'string',
							title: 'txdraft_ref_number'
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Rekening Sumber',
							length: 50,
							// validator: (value, fieldName) => {
							// 	let check = isExist(value, 'Rekening Sumber');
							// 	return check;
							// },
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening Sumber',
							length: 100,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'Kode Transaksi',
							length: 20,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'Penyedia Layanan',
							length: 20,
						},
						{
							name: 'product_name',
							type: 'string',
							title: 'Penyedia Layanan',
							length: 100,

						},
						{
							name: 'credit_account_no',
							type: 'string',
							title: 'Rekening Tujuan',
							length: 20,
							validator: ( value, fieldName ) => {
								let check = isExist( value, 'Nomor Pelanggan/Nomor Meter' );
								return check;
							},
						},
						// {
						// 	name: 'tx_ref_number',
						// 	type: 'string',
						// 	title: 'Nomor / ID Transaksi',
						// 	length: 50,
						// 	validator: (value, fieldName) => {
						// 		let check = isExist(value, 'Nomor / ID Transaksi');
						// 		return check;
						// 	},
						// },
						{
							name: 'credit_account_name',
							type: 'string',
							title: 'Nama Rekening Tujuan',
							length: 100,
						},
						{
							name: 'amount',
							type: 'int',
							title: 'Jumlah',
							length: 20,
						},
						{
							name: 'amount_fee',
							type: 'int',
							title: 'Biaya',
							length: 20,
						},
						{
							name: 'amount_ex',
							type: 'int',
							title: 'Biaya Lain-lain',
							length: 20,
						},
						{
							name: 'amount_net',
							type: 'int',
							title: 'Biaya',
							length: 20,
							formula: 'amount + amount_fee',
						},
						{
							name: 'date',
							type: 'date',
							title: 'Tanggal',
							length: 15,
						},
						{
							name: 'freq',
							type: 'string',
							title: 'Setiap',
							length: 15,
						},
						{
							name: 'recurring_type',
							type: 'string',
							title: 'Tipe',
							length: 15,
						},
						{
							name: 'at_date',
							type: 'date',
							title: 'Pada Tanggal',
							length: 15,
						},
						{
							name: 'start_date',
							type: 'date',
							title: 'Mulai Tanggal',
							length: 15,
						},
						{
							name: 'end_date',
							type: 'date',
							title: 'Sampai Tanggal',
							length: 15,
						},
						{
							name: 'desc',
							type: 'string',
							title: 'Keterangan',
							length: 30,
						},
						{
							name: 'favorite',
							type: 'string',
							title: 'favorite',
							length: 10,
						},
						{
							name: 'favorite_name',
							type: 'string',
							title: 'favorite_name',
							length: 50,
						},
					],

					indexes: [ 'id_transaction' ],
				},
			};

			const initialData = {
				'main:plnPrepaidAdvice': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'plnPrepaidAdvice',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				plnPrepaidAdvice: {
					fields: {
						account_no: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekening',
								selFields: [
									'account_no',
									'account_name',
									'currency',
								],
								fieldMap: {
									account_no: 'account_no',
									account_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: (
									fields,
									isValidation = false,
								) => {
									return isValidation
										? {
											searchMode: 1,
											searchKey: fields.account_no,
											resourceType: 'method',
										}
										: {
											account_no: fields.account_no,
											resourceType: 'method',
										};
								},
							},
						},
						amount: { thousand: true, decimal: 2, separator: '.' },
						amount_fee: {
							thousand: true,
							decimal: 2,
							separator: '.'
						},
						amount_net: {
							thousand: true,
							decimal: 2,
							separator: '.'
						},
						product_code: {
							inputType: 'select',
							dataSets: {
								'070001': 'Listrik Pascabayar',
							},
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF( aReact, globals ) {
		return await getImports( aReact, globals );
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
