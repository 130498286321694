// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			NDataBrowser,
			staticComponents: {
				Button,
				FormLabel,
				AlertComponent,
				SearchBox,
				Filter,
				FilterActionButtons,
				Dropdown,
				RadioGroup,
				ReactIconFa: { FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('tagging_transaksi.report_tags.reportTagsMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					isErr: false,
					errMessage: '',
					isEditing: false,
					userInfo: {},
				});

				const initialFilter = {};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const AlertHandler = async (msg, type, body) => {
					await appAction.frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					console.log(params.tag_code);
					try {
						const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, { tag_code: params.tag_code }, true);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.ref_number]);

				React.useEffect(() => {
					(async function () {
						// const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						await loadData();
						const { user_name, bank, role } = await appAction.getState();
						setState({
							...state,
							// popupComponent,
							userInfo: {
								user_name,
								bank,
								role,
							},
						});
					})();
				}, [props._authToken, dsMainAction, loadData, props.uiMode]);

				const ActionButtons = React.useCallback(({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initialFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				return (
					<div>
						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="Kode Tag">
								<b>
									<mainComps.FieldDataDisplay fieldName="tag_code" />
								</b>
							</FormLabel>
							<FormLabel style={{ width: '100%' }} label="Keterangan">
								<b>
									<mainComps.FieldDataDisplay fieldName="description" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="Tanggal">
								<b>
									<mainComps.FieldDataDisplay fieldName="tag_date" />
								</b>
							</FormLabel>
						</div>

						<div>
							{/* <div style={{ display: 'flex',justifyContent:'flex-end',marginTop:'1rem' }}>
								{state.isEditing ? <Button onClick={()=>saveDataClick()}>Simpan</Button> : <Button onClick={()=>handleMode()}>Tambah Tags</Button>}
							</div> */}
							<div style={{ overflow: 'auto' }}>
								<NDataBrowser
									stdAppAction={appAction}
									moduleId={_moduleId}
									resourceType="scroll_query"
									dataId="listSO"
									dataProvider={dsetProvider}
									dsetPath="main"
									authToken={props._authToken}
									params={{ tag_code: params.tag_code }}
									autoLoad={true}
									rowButtons={null}
									uiData={{
										main: {
											fields: {
												amount: { thousand: true, decimal: 2 },
											},
										},
									}}
									// popupComponent={p => <state.popupComponent {...p} />}
									// selFields={['id_tag', 'id_transaction', 'tag_code', 'tag_date', 'tag_user_id','amount', 'description']}
									filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
										return (
											<Filter>
												<FormLabel label="Urutkan">
													<Dropdown
														onChange={e =>
															changeParams({
																sortField: e.target.value,
															})
														}
														item={sortFields}
														value={params.sortField}
														placeholder="--Pilih urutan--"
													/>
												</FormLabel>

												<FormLabel label="Cari">
													<SearchBox
														style={{
															width: 400,
														}}
														handleSearch={src =>
															changeParams({
																src,
															})
														}
														srcText={params.src}
													/>
												</FormLabel>

												<FormLabel label="&nbsp;">
													<FilterActionButtons
														buttons={ActionButtons({
															clearFilter,
															params,
														})}
													/>
												</FormLabel>
											</Filter>
										);
									}}
								/>
							</div>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
