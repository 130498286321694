

import React from 'react'; //--DAFReact: development
import { useRef } from 'react';
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
    //--DAFReact: development

    // (function () { //--DAFReact: deploy

    async function getImports(React, globals) {
        // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
        const {
            _moduleId,
            StdAppAction,
            appAction,
            jsdset,
            ndswidget,
            dswidget,
            frameAction,
            staticComponents: {
                Input,
                AlertComponent,
                Dropdown,
                PageTitle,
                Button,
                FormLabel,
                RadioGroup,
                ReactIconFa: { FaInfoCircle },
                InputGroup
            },
        } = globals;
        if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
            throw new Error(
                'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
            );
        }

        /* other required modules are asynchronously loaded here */
        const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('transfer.foreign_remitance.foreignRemitanceMetadata');


        const { PanelDataDisplay, PanelButton } = dswidget;
        const { FieldDataInput, FieldDataDisplay } = ndswidget;




        function componentFactory(params) {
            function AppForm(props) {
                const dataContext = React.useMemo(
                    () => jsdset.dsetCreateContext(),
                    [],
                );
                const DSetProvider = React.useMemo(() => {
                    return jsdset.dsetMetaProvider(
                        dataContext,
                        metadata,
                        initialData,
                        editUIData,
                    );
                }, []);
                return (
                    <DSetProvider>
                        <AppFormUI dataContext={dataContext} {...props} />
                        {/* any other props will be passed down */}
                    </DSetProvider>
                );
            }

            function AppFormUI(props) {
                const [state, setState] = React.useState({
                    isLoading: true,
                    isErr: false,
                    errMessage: '',
                });


                let refAmount = useRef()


                // bind controls to _moduleId and _authToken
                const vComps = React.useMemo(
                    () =>
                        appAction.connect(
                            {
                                PanelDataDisplay,
                                FieldDataDisplay,
                                PanelButton,
                                FieldDataInput,
                            },
                            { _moduleId, _getToken: () => props._authToken },
                        ),
                    [],
                );

                // bind controls to datasets
                const [mainComps] = React.useMemo(
                    () => [
                        jsdset.connect(
                            { context: props.dataContext, dsetPath: 'main' },
                            vComps,
                        ),
                    ],
                    [props.dataContext, vComps],
                );

                // obtain action objects from data context
                const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
                    props.dataContext,
                    'main',
                );




                // set event on component mounting
                React.useEffect(() => {
                    (async function () {
                        dsMainAction.loadStore(
                            props.data,
                            'std',
                            serverDataMapping,
                            true,
                        );

                        if (state.isLoading == true) {
                            const response = await appAction.fetchResource(
                                props._moduleId,
                                'method',
                                'getConfirmation',
                                props._authToken,
                                props.data,
                                true,
                            );

                            // FIXME: this is just dummy @
                            dsMainAction.setFields(response.resp);

                            setState({ ...state, isLoading: false });
                        }
                    })();
                }, [dsMainAction, props.uiMode, props.data]);


                const saveDataClick = async () => {

                    try {
                        const { fieldValidStates, fieldValidErrors } =
                            dsMainProxy;
                        const isInValid = Object.entries(
                            fieldValidStates,
                        ).filter(([i, v]) => {
                            return !v;
                        });

                        if (isInValid.length > 0)
                            throw new Error(fieldValidErrors[isInValid[0][0]]);

                        var dataUnload = dsMainProxy.unloadStore(
                            serverDataMapping,
                            {
                                includeLoadedRows: false,
                                includeDeletedRows: true,
                            },
                        );


                        await appAction.postData(
                            props._moduleId,
                            'saveData',
                            props._authToken,
                            dataUnload,
                        );

                        appAction.frameAction.closeModal(true);
                        AlertHandler('Data berhasil disimpan', 'success');
                    } catch (err) {
                        AlertHandler(err.message, 'alert');
                    }
                };


                const AlertHandler = async (msg, type) => {
                    await frameAction.showModal({
                        contentClass: props => (
                            // <>{msg}</>
                            <AlertComponent
                                {...props}
                                title={msg}
                                type={type}
                            />
                        ),
                        size: 'small',
                    });
                }

                return (
                    <div >
                        <FormLabel label="Rekening Sumber">
                            <b>
                                <mainComps.FieldDataDisplay fieldName='account_no' /> - <mainComps.FieldDataDisplay fieldName='account_name' />
                            </b>
                        </FormLabel>
                        <FormLabel label="Negara Tujuan">
                            <b>
                                <mainComps.FieldDataDisplay fieldName='kode_negara_tujuan' /> - <mainComps.FieldDataDisplay fieldName='nama_negara_tujuan' />
                            </b>
                        </FormLabel>
                        <FormLabel label="Rekening Tujuan">
                            <b>
                                <mainComps.FieldDataDisplay fieldName='kode_bank_tujuan' /> - <mainComps.FieldDataDisplay fieldName='credit_account_no' />- <mainComps.FieldDataDisplay fieldName='credit_account_name' />
                            </b>
                        </FormLabel>
                        <div style={{
                            display: 'flex'
                        }}>
                            <FormLabel label="Nominal yang dikirim">
                                <b>
                                    <mainComps.FieldDataDisplay fieldName='currencyAsal' /> <mainComps.FieldDataDisplay fieldName='amount1' />
                                </b>
                            </FormLabel>
                            <div style={{ padding: 20 }}></div>
                            <FormLabel label="Kurs saat ini">
                                <b><mainComps.FieldDataDisplay fieldName='kurs' /></b>
                            </FormLabel>
                            <div style={{ padding: 20 }}></div>
                            <FormLabel label="Nominal yang akan diterima">
                                <b>
                                    <mainComps.FieldDataDisplay fieldName='currencyTujuan' /> <mainComps.FieldDataDisplay fieldName='amount2' />
                                </b>
                            </FormLabel>
                        </div>
                        <FormLabel label="Biaya">
                            <b>
                                <mainComps.FieldDataDisplay fieldName='currencyAsal' /> <mainComps.FieldDataDisplay fieldName='amount_fee' />
                            </b>
                        </FormLabel>
                        <FormLabel label="Jumlah">
                            <b>
                                <mainComps.FieldDataDisplay fieldName='currencyAsal' /> <mainComps.FieldDataDisplay fieldName='amount_net' />
                            </b>
                        </FormLabel>
                        <FormLabel label="Berita">
                            <b>
                                <mainComps.FieldDataDisplay fieldName={'description'} />
                            </b>
                        </FormLabel>


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <>  <Button
                                onClick={() => { saveDataClick() }}
                                style={{
                                    marginRight: 10,
                                }}>
                                Konfirmasi
                            </Button>
                                <Button
                                    onClick={() => {
                                        appAction.frameAction.closeModal()
                                        // dsMainAction.addRow({})
                                    }}
                                    type="bordered"
                                >
                                    Batalkan
                                </Button></>


                        </div>

                        <div
                            style={{
                                display: state.isErr ? 'block' : 'none',
                            }}
                        >
                            {state.errMessage}
                        </div>
                    </div>
                );
            }

            // return value may be different depending on params
            return React.memo(AppForm);
        }

        return { componentFactory };
    }

    async function initModuleF(aReact, globals) {
        return await getImports(aReact, globals);
    }

    return initModuleF;
    // })()  //--DAFReact: deploy
} //--DAFReact: development
