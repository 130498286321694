/*
  DAFReact framework module - Application form (hook)
  appname: listTagihanVA
  filename: listTagihanVAList.js
  moduleid: listTagihanVAList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Dropdown,
				FormLabel,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa,
				ReactIconSi,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { FaRetweet } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;

		const { editUIData } = await appAction.fetchAndExecModule(
			'manajemen_va.list_tagihan_va.listTagihanVAMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							// {
							// 	type: 'bordered',
							// 	name: 'Print Excel',
							// 	onClick: async () => {
							// 		try {
							// 			await appAction.postData(
							// 				_moduleId,
							// 				'importList',
							// 				props._authToken,
							// 				params,
							// 			);
							// 		} catch (err) {
							// 			AlertHandler(err.message, 'alert');
							// 		}
							// 	},
							// 	render: () => <SiMicrosoftexcel />,
							// },
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => clearFilter(),
								render: () => <FaRetweet />,
							},
						];
					},
					[],
				);

				return (
					<>
						<PageTitle
							title="List Tagihan VA"
							subtitle="Manajemen VA"
							style={{ marginBottom: `1.5rem` }}
						/>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							resourceType="method"
							dataId="listSO"
							autoLoad={false}
							dataProvider={dsetProvider}
							authToken={props._authToken}
							rowButtons={false}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								clearFilter,
								changeParams,
								params,
							}) => {
								return (
									<Filter>
										{/* Status Transaksi */}
										{/* <FormLabel label="Status Transaksi">
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<RadioGroup
													data={Object.entries({
														P: 'Perlu dibayar',
														E: 'Expired',
													})}
													name={'status'}
													currentValue={filter.status}
													initialValue={''}
													type={'icon'}
													varian={'bordered'}
													onChange={e => {
														handleFilter({
															status: e.target
																.value,
														});
													}}
												/>
											</div>
										</FormLabel> */}

										<FormLabel
											label="Urutkan"
											style={{ maxWidth: '250px' }}
										>
											<Dropdown
												onChange={e =>
													changeParams({
														sortField:
															e.target.value,
													})
												}
												item={sortFields}
												width="100%"
												defaultValue={params.sortField}
											/>
										</FormLabel>

										<FormLabel label="Cari">
											<SearchBox
												style={{
													marginLeft: 5,
													width: '300px',
												}}
												handleSearch={src =>
													changeParams({ src })
												}
												srcText={params.src}
											/>
										</FormLabel>
										<FormLabel label="&nbsp;">
											<FilterActionButtons
												buttons={ActionButtons({
													clearFilter,
													params,
												})}
											/>
										</FormLabel>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
