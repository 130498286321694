import React from 'react';
import { FaSearch } from 'react-icons/fa';

import Input from '../Forms/Input/Input';
import Button from '../Button/Button';

import './SearchBox.scss';

const SearchBox = ({
	handleSearch = () => console.log('search started...'),
	srcText = '',
	label,
	style,
}) => {
	const [src, setSrc] = React.useState(srcText || '');

	return (
		<div className={'search-box'} style={style}>
			<div className={'search-box-input'}>
				<FaSearch className="search-icon" />
				<Input
					placeholder={'Masukan kata kunci'}
					value={src}
					onChange={e => setSrc(e.target.value)}
					onKeyPress={e =>
						e.charCode === 13 ? handleSearch(src) : false
					}
				/>
			</div>
			<div>
				<Button onClick={() => handleSearch(src)}>
					{label ? label : 'Cari'}
				</Button>
			</div>
		</div>
	);
};

export default SearchBox;
