import React from 'react';

// internal framework libraries
import { AppFrame } from '../modules/com.ihsan/appframe_minimal.js';

// application-logic libraries
import { StdAppInterfaces } from '../modules/com.ihsan/appdata.js';

// login pages
import LoginPages from '../app_components/_modules/Login/Login';
import RequestLoading from '../app_components/RequestLoading/RequestLoading.js';

const CustomFrameHeader = props => {
	// receives props.title to display
	return <></>;
};

const LoginPage = React.memo(
	/* 
	expected props: 
	isError: boolean
	errMsg: string
	*/

	function (props) {
		return <LoginPages {...props} appInfo={'customer'} />;
	},
);

function MainPage_(props) {
	/*
		Important system components:

		AppFrame: frame viewer
		AppModal: modal viewer
	*/

	return (
		<div>
			{props.requesting === true && <RequestLoading />}

			{props.isLogin !== true ? <LoginPage {...props} /> : <AppFrame treeName="/" headerComponent={CustomFrameHeader} sender="main" />}
		</div>
	);
}

const MainPage = StdAppInterfaces.loginAndRequest(MainPage_);

export { MainPage };
