import React from 'react';

import bg from '../_assets/png/Aksen.png';
import logo from '../_assets/png/logo-putih.png';

import Spinner from '../Spinner/Spinner';

const PageLoadingIndicator = () => {
	return (
		<div
			className={'loadingIndicator'}
			style={{
				backgroundImage: 'url(' + bg + ')',
				backgroundRepeat: 'no-repeat',
				backgroundPositionX: 'center',
			}}
		>
			<img src={logo} />
			<div style={{ display: 'flex' }}>
				<Spinner size={25} customStyle={{ marginRight: 10 }} />
				<h4>Starting Application...</h4>
			</div>
		</div>
	);
};

export default PageLoadingIndicator;
