import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				reportTags: {
					fields: [
						// Corporate
						{
							name: 'tag_code',
							type: 'string',
							title: 'Kode Tag',
						},
						{
							name: 'description',
							type: 'string',
							title: 'Deskripsi',
						},
						{
							name: 'amount',
							type: 'float',
							title: 'Jumlah',
						},
						{
							name: 'tag_date',
							type: 'date',
							title: 'Tanggal Tag',
						},
					],

					indexes: ['tag_code'],
				},
			};

			const initialData = {
				'main:reportTags': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'reportTags',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				reportTags: {
					fields: {
						amount: { thousand: true, decimal: 2 },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
