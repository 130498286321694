export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			staticComponents: {
				PageTitle,
				SearchBox,
				Filter,
				DatePicker,
				FilterActionButtons,
				ReactIconFa: { FaRetweet },
				FormLabel,
				Dropdown,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule('manajemen_transaksi.transaksi_terjadwal.transaksiTerjadwalMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								clearFilter();
								handleFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				const handleFilter = React.useCallback(
					e => {
						setFilter(e ? { ...filter, ...e } : initialFilter);
					},
					[filter],
				);

				return (
					<>
						<PageTitle subtitle="Manajemen Transaksi" title="Transaksi Terjadwal" />

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							autoLoad={false}
							params={filter}
							uiData={editUIData}
							shownFields={[
								'id_tx_draft',
								'entry_datetime',
								'account_no',
								'description',
								'execution_desc',
								'sched_exec_datetime',
								'tx_mode',
								'tx_desc',
								'product_desc',
								'amount',
								'amount_fee'
							]}
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<Filter>
										<FormLabel label="Tanggal Eksekusi">
											<div style={{ display: 'flex' }}>
												<DatePicker
													value={filter.start_date}
													onSelect={e => {
														handleFilter({
															start_date: e,
														});
													}}
												/>
												<div style={{ padding: 10 }}>s.d.</div>
												<DatePicker
													value={filter.end_date}
													onSelect={e => {
														handleFilter({
															end_date: e,
														});
													}}
												/>
											</div>
										</FormLabel>
										<FormLabel label="Urutkan & Cari">
											<div
												style={{
													display: 'flex',
													justifyContent: 'left',
													alignItems: 'center',
												}}
											>
												<Dropdown
													onChange={e =>
														changeParams({
															sortField: e.target.value,
														})
													}
													item={sortFields}
													value={params.sortField}
												/>
												<SearchBox
													label="Tampilkan"
													style={{
														marginLeft: 5,
														width: 300,
													}}
													handleSearch={src =>
														changeParams({
															start_date: filter.start_date,
															end_date: filter.end_date,
															src: src,
														})
													}
													srcText={params.src}
												/>
											</div>
										</FormLabel>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
												marginTop: 10,
											}}
										>
											<FilterActionButtons buttons={ActionButtons(clearFilter)} />
										</div>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};
}
