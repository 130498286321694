import React from 'react';

import * as ds from './jsdsetconnect.js';

import TableConnector from '../../app_components/_hoc/TableConnector';

var dsAction;

const DataBrowser = props => {
	const {
		// dependencies
		stdAppAction,
		moduleId,
		resourceType,
		dataId,
		authToken,
		dsetPath,
		uiData = {},

		// data manipulator
		onRowClick, // if row was clicked, supposed to be function
		rowButtons, // button for each row, if null thats  mean no button at all, supposed to be function

		// static params / initial params
		params = {},
		availableExtraParams = [], // field of params that are available to send

		autoLoad = true, // if [false] then data not fetched in the first load, need user to trigger load function

		keyField = null, // this argument came from lookup only

		initialDataState = {}, // initial data so data wont fetched from server

		shownFields = [], // list array of fieldname, if its not empty, fielddef will be filtered

		postFunction = _ => {}, // post function, if there is a function need to run 

		...otherProps
	} = props;

    // console.log("🚀 ~ file: new.databrowser.js ~ line 39 ~ resourceType", resourceType)

	var initialParams = {
		src: '', // search text
		maxRow: 10, // maxRow set at 10,
		sort_field: '', // it always there
	};

	const [state, setState] = React.useState({
		// functional state
		inServerRequest: false, // indicate if data was fetched or not
		isErr: false, // indicate if there is error
		errMessage: '', // error message that occured during fetching

		// request parameter, if initialDataState is exist, thats mean initial request to get all parameter from server has been declared and invoked somewhere else (at current state from lookup)
		useServerFields: initialDataState.sort_field ? false : true, //
		serverKeyField: initialDataState.sort_field || null, // key field that returned from server
		sortFields: initialDataState.sort_fields || [], // all available fields to select for sorting and searching

		hasNextData: initialDataState.next_data || false,
		hasPrevData: initialDataState.prev_data || false,
	});

	// api params are separated from state,
	initialParams = {
		...initialParams,
		...params,
		forceLoad: false,
		sortField: state.serverKeyField,
	};
	const [apiParams, setApiParams] = React.useState(initialParams);

	const availableParams = [
		'src',
		'sort_field',
		'status',
		'date',
		'startDate',
		'endDate',
		...availableExtraParams, // this is filter parameter from corresponding module, indicate that all value inside are available to be changed at filter
	]; // this variable just to filter changeParams bellow,

	const loadDataClick = async nav => {
		setState({ ...state, isErr: false, inServerRequest: true }); // set state to fetching data

		// check if nav is called, just to indicate that current request is to get next data or previous data
		if (nav && (nav === 'next' || nav === 'prev')) {
			// boundRow is row that collected based on nav, if next, it gets last row from current dataset, otherwise it gets first row of dataset
			// sortField use from apiParams, and if its enty, use from server
			var boundRow =
				nav == 'next'
					? dsAction.fieldsArray.slice(-1)[0]
					: dsAction.fieldsArray[0];
			if (boundRow) {
				var sortValueBound =
					boundRow[apiParams.sortField || state.serverKeyField];
				var keyValueBound = boundRow[state.serverKeyField];
			}
		}

		try {
			const { maxRow: max_row, sortField, ...restParams } = apiParams;

			var response = await stdAppAction.fetchResource(
				moduleId,
				resourceType ? resourceType : 'scroll_query',
				dataId,
				authToken,

				// parameter here
				Object.assign(
					{
						max_row,
						get_client_fields: state.useServerFields
							? 'true'
							: 'false', // always sent, to make code simpler
					},
					boundRow
						? {
								is_bound: 1,
								value_bound: sortValueBound,
								key_bound: keyValueBound,
								nav_dir: nav,
						  }
						: {}, // if  boundRow, thats mean its navigator, navigator only need, nav type, value_bond, key_bond
					restParams,
					/* 
						  a litle bit tricky here, sort_field are undefined in the first place, after get a response, sort_field are set in params instead of apiParams, this approach is to prevent rendering loop
						  the next request is use apiParams.sortField, but since its empty, it use params.serverKeyField (if none, default which is came from server, if not set, its means use default) 
						  when user selected sort_field, it set sortField and re-load data from server, in this state, apiParams.sortField, sent
						*/
					{ sort_field: sortField || state.serverKeyField }, // its always sent, so if user changed sort_field, and press next, prev, or search button, it will stick as user search
				),
			);

			const {
				client_fields,
				key_field,
				next_data,
				prev_data,
				sort_fields,
				rows,
			} = response;

			if(postFunction && typeof postFunction == 'function')
				postFunction(response)

			if (!nav && state.useServerFields) {
				dsAction.resetAll(
					{
						table: {
							fields: client_fields,
							indexes: [key_field],
						},
					},
					{
						'main:table': [],
					},
					{ table: Object.values(uiData)[0]?.fields || {} },
				);
			}

			dsAction.resetStore();
			dsAction.load(rows);

			setState({
				...state, // load current state

				inServerRequest: false, // set loading to false
				isErr: false, // no error occured

				hasNextData: next_data, // has next data
				hasPrevData: prev_data, // has prev data

				sortFields: sort_fields, // list all available fields

				serverKeyField: key_field, // key field from server
				useServerFields: false, // telling fetch to not reset dsAction
			});
		} catch (err) {
            // console.log("🚀 ~ file: new.databrowser.js ~ line 174 ~ err", err)
			setState({
				...state, // load previous data
				...{
					inServerRequest: false, // set loading to false
					isErr: true, // error occured
					errMessage: err.message, // set error message
				},
			});
		}
	};

	const changeParams = (filters = {}) => {
		var filter = Object.fromEntries(
			Object.entries(filters).filter(([i, v]) => {
				return true; // FIXME: filter for changeparams, bypassed at a moment
				// return availableParams.indexOf(v > 0);
			}),
		);
		setApiParams({ ...apiParams, ...filter, forceLoad: true });
	};

	const clearFilter = () => {
		setApiParams({...initialParams, forceLoad: true});
	};

	// initial load, start in the first load
	React.useEffect(() => {
		if (((!autoLoad && apiParams.forceLoad) || autoLoad) && authToken) {
			(async () => await loadDataClick())();
		}
	}, [authToken, apiParams]);

	var dataContext = otherProps.dataProvider.dataContext;
	const DataComponent = ds.dsetMetaConnector(
		dataContext,
		dsetPath,
		true,
	)(TableConnector);

	return (
		<otherProps.dataProvider>
			<ds.DSetAction
				ref={val => {
					dsAction = val;
				}}
				context={dataContext}
				dsetPath={dsetPath}
				multiRow={true}
			/>

			<DataComponent
				{...{
					...otherProps,

					loadDataClick,
					changeParams,
					clearFilter,

					onRowClick,
					rowButtons,
					state,
					shownFields,
					params: {
						...apiParams,
						sortField: apiParams.sortField || state.serverKeyField,
					},
				}}
			/>

			{state.isErr && <div>Error: {state.errMessage}</div>}
		</otherProps.dataProvider>
	);
};

export default DataBrowser;
