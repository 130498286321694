import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
    //--DAFReact: development

    // (function () { //--DAFReact: deploy

    async function getImports(React, globals) {
        // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
        const {
            _moduleId,
            StdAppAction,
            AppFrameAction,
            appAction,
            jsdset,
            dswidget,
            DataBrowser,
            staticComponents: {
                Validator: { isExist, isEmail, isPasword, isPhone },
            },
        } = globals;
        if (
            !StdAppAction ||
            !AppFrameAction ||
            !appAction ||
            !jsdset ||
            !dswidget ||
            !DataBrowser
        ) {
            throw new Error(
                'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
            );
        }

        if (!_moduleId) {
            throw new Error('_moduleId not defined');
        }

        function componentFactory(params) {
            const metadata = {
                trf: {
                    fields: [

                        {
                            name: 'id_batch_detail',
                            type: 'string',
                            title: 'id_batch_detail',
                            length: 20,

                        },
                        {
                            name: 'kode_bank_tujuan',
                            type: 'string',
                            title: 'Kode Bank',
                            length: 20,
                            // validator: (value, fieldName) => {
                            //     let check = isExist(value, 'Kode Bank');
                            //     return check;
                            // },
                        },
                        {
                            name: 'kode_bank_tujuan_name',
                            type: 'string',
                            title: 'Kode Bank',
                            length: 20,
                        },
                        {
                            name: 'ref_number',
                            type: 'string',
                            title: 'ref_number',
                            length: 20,

                        },
                        {
                            name: 'account_no',
                            type: 'string',
                            title: 'Rekening Sumber',
                            length: 20,
                        },
                        {
                            name: 'account_name',
                            type: 'string',
                            title: 'Nama Rekening Sumber',
                            length: 50,
                        },
                        {
                            name: 'credit_account_no',
                            type: 'string',
                            title: 'credit_account_no',
                            length: 20,
                            validator: (value, fieldName) => {
                                let check = isExist(value, 'Nomor');
                                return check;
                            },
                        },
                        {
                            name: 'external_ref_number',
                            type: 'string',
                            title: 'external_ref_number',
                            length: 20,

                        },
                        {
                            name: 'credit_account_name',
                            type: 'string',
                            title: 'credit_account_name',
                            length: 20,
                            // validator: (value, fieldName) => {
                            //     let check = isExist(value, 'Nama Rekening Tujuan');
                            //     return check;
                            // },
                        },
                        {
                            name: 'tx_ref_number',
                            type: 'string',
                            title: 'tx_ref_number',
                            length: 20,

                        },
                        {
                            name: 'batchtxdetail_ref_number',
                            type: 'string',
                            title: 'batchtxdetail_ref_number',
                            length: 20,

                        },
                        {
                            name: 'amount',
                            type: 'float',
                            title: 'amount',
                            length: 20,
                            validator: (value, fieldName) => {
                                let check = isExist(value, 'Nominal');
                                return check;
                            },
                        },
                        {
                            name: 'amount_fee',
                            type: 'float',
                            title: 'amount_fee',
                            length: 20,

                        },
                        {
                            name: 'amount_ex',
                            type: 'float',
                            title: 'amount_ex',
                            length: 20,

                        },
                        {
                            name: 'amount_type',
                            type: 'string',
                            title: 'amount_type',
                            length: 20,

                        },
                        {
                            name: 'detail_description',
                            type: 'string',
                            title: 'detail_description',
                            length: 160,
							regex: /[^a-zA-Z0-9 .]/g, // only alphanumeric and space

                        },
                        {
                            name: 'id_tx_draft',
                            type: 'string',
                            title: 'id_tx_draft',
                            length: 20,

                        },

                    ],

                    indexes: ['id_batch_detail'],
                },
            };

            const initialData = {
                'main:trf': [],
            };

            const serverDataMapping = {
                data: {
                    dset: 'main',
                    type: 'trf',
                    fieldMapping: {
                        '*': true, // allow default fields
                    },
                },
            };

            const editUIData = {
                trf: {
                    fields: {
                        amount: {
                            decimal: 2,
                            thousand: true
                        },
                        amount_fee: {
                            decimal: 2,
                            thousand: true
                        },
                        amount_ex: {
                            decimal: 2,
                            thousand: true
                        },
                        kode_bank_tujuan: {
                            lookup: {
                                style: {
                                    idleCheck: false,
                                    input: true,
                                    button: true,
                                },
                                dataId: 'getKodeBank',
                                selFields: ['bank_code', 'bank_name'],
                                fieldMap: {
                                    kode_bank_tujuan: 'bank_code',
                                    kode_bank_tujuan_name: 'bank_name',
                                },
                                apiParameterF: (
                                    fields,
                                    isValidation = false,
                                ) => {
                                    return isValidation
                                        ? {
                                            searchMode: 1,
                                            searchKey: fields.bank_code,
                                            resourceType: 'method',
                                        }
                                        : {
                                            bank_code: fields.bank_code,
                                            resourceType: 'method',
                                        };
                                },
                            },
                        },
                        account_no: {
                            lookup: {
                                style: {
                                    idleCheck: false,
                                    input: true,
                                    button: true,
                                },
                                dataId: 'getRekening',
                                selFields: [
                                    'account_no',
                                    'account_name',
                                    'currency',
                                ],
                                fieldMap: {
                                    account_no: 'account_no',
                                    account_name: 'account_name',
                                    currency: 'currency',
                                },
                                apiParameterF: (
                                    fields,
                                    isValidation = false,
                                ) => {
                                    return isValidation
                                        ? {
                                            searchMode: 1,
                                            searchKey: fields.account_no,
                                            resourceType: 'method',
                                        }
                                        : {
                                            account_no: fields.account_no,
                                            resourceType: 'method',
                                        };
                                },
                            },
                        },
                    },
                },
            };

            // return value may be different depending on params
            return { metadata, initialData, serverDataMapping, editUIData };
        }

        return { componentFactory };
    }

    async function initModuleF(aReact, globals) {
        return await getImports(aReact, globals);
    }

    return initModuleF;
    // })()  //--DAFReact: deploy
} //--DAFReact: development
