import React from 'react'; //--DAFReact: development
import './CheckboxBordered.scss';

export default function Checkbox({
	value = 'F',
	label = '',
	onChange = null,
	readOnly = false,
}) {
	const handleClick = () => {
		if (readOnly) return true;

		if (typeof onChange === 'function')
			onChange({ target: { value: value === 'F' ? 'T' : 'F' } });
	};

	return (
		<div
			onClick={handleClick}
			className="bordered-checkbox"
			style={
				!(value === 'T')
					? { color: 'black', borderColor: 'transparent', cursor: 'pointer' }
					: { cursor: 'pointer' }
			}
		>
			{label}
		</div>
	);
}
