/*
  DAFReact framework module - Application form (hook)
  appname: reportTags
  filename: reportTagsList.js
  moduleid: reportTagsList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Dropdown,
				FormLabel,
				DatePicker,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa: { FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { editUIData } = await appAction.fetchAndExecModule('tagging_transaksi.report_tags.reportTagsMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					service_type: [],
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					service_type: '',
				};

				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Print Excel',
								onClick: async () => {
									try {
										await appAction.postData(_moduleId, 'importList', props._authToken, { ...params, ...filter });
									} catch (err) {
										AlertHandler(err.message, 'alert');
									}
								},
								render: () => <SiMicrosoftexcel />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									setFilter(initialFilter);
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[filter],
				);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				return (
					<>
						<PageTitle title="Report Tags" subtitle="Transaction Tags" style={{ marginBottom: `1.5rem` }} />

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							params={filter}
							uiData={editUIData}
							filterComponent={({ sortFields, loadDataClick, clearFilter, changeParams, params }) => {
								return (
									<Filter>
										<FormLabel
											label="Tanggal Transaksi"
											style={{
												maxWidth: '250px',
												marginRight: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
												}}
											>
												<DatePicker
													value={filter.start_date}
													onChange={e => {
														handleFilter({
															start_date: e,
														});
													}}
												/>
												<div style={{ padding: 10 }}>s.d.</div>
												<DatePicker
													value={filter.end_date}
													onChange={e => {
														handleFilter({
															end_date: e,
														});
													}}
												/>
											</div>
										</FormLabel>
										<FormLabel
											label="Urutkan"
											style={{
												marginLeft: 5,
												maxWidth: '250px',
											}}
										>
											<Dropdown
												style={{
													width: '250px',
												}}
												onChange={e =>
													changeParams({
														sortField: e.target.value,
													})
												}
												item={sortFields}
												width="100%"
												value={params.sortField}
											/>
										</FormLabel>

										<FormLabel label="Cari">
											<SearchBox
												style={{
													marginLeft: 5,
													width: '250px',
												}}
												handleSearch={src =>
													changeParams({
														src: src,
														start_date: filter.start_date,
														end_date: filter.end_date,
													})
												}
												srcText={params.src}
											/>
										</FormLabel>

										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
