/*
  DAFReact framework module - Application form (class)
  appname: listTransaksiVAView
  filename: listTransaksiVAView.js
  moduleid: listTransaksiVAView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			staticComponents: { FormLabel },
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'manajemen_va.list_transaksi_va.listTransaksiVAMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			class ListTransaksiVAView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(
						this.dataContext,
						metadata,
						initialData,
						editUIData
					);
					this.state = { isErr: false, errMessage: '' };
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});
					console.log(
						'Loading listTransaksiVA data ' +
							params.id_transaction,
					);

					try {
						var response = await this.appAction.fetchResource(
							_moduleId,
							// 'single_data',
							'method',
							'dataSO',
							this.props._authToken,
							{ id_transaction: params.id_transaction },
						);
						this.dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);

						console.log(response);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents(
						{ context: this.dataContext, dsetPath: 'main' },
						vcomps,
					);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							<div style={{ display: 'flex' }}>
								<FormLabel
									label="No. Transaksi"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="id_transaction" />
									</b>
								</FormLabel>
								<FormLabel
									label="Tanggal Input"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="tx_date" />
									</b>
								</FormLabel>
							</div>

							<div style={{ display: 'flex' }}>
								<FormLabel
									label="Dari Rekening"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="account_no" />
									</b>
								</FormLabel>
								<FormLabel
									label="Nama Rekening"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="account_name" />
									</b>
								</FormLabel>
							</div>

							<div style={{ display: 'flex' }}>
								<FormLabel
									label="Jenis Transaksi"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="tx_type" />
									</b>
								</FormLabel>
								<FormLabel
									label="No. Referensi"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="ref_number" />
									</b>
								</FormLabel>
							</div>

							<div style={{ display: 'flex' }}>
								<FormLabel
									label="Jumlah"
									style={{ width: '100%' }}
								>
									<b>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</b>
								</FormLabel>
							</div>
							<div
								style={{
									display: this.state.isErr
										? 'block'
										: 'none',
								}}
							>
								{this.state.errMessage}
							</div>
						</DSetProvider>
					);
				}
			}

			// return value may be different depending on params
			return ListTransaksiVAView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
