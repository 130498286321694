import React from 'react';
import DataBrowser from './new.databrowser';
import * as jsdset from './jsdsetconnect.js';

import Filter from '../../app_components/Filter/Filter';
import FilterActionButtons from '../../app_components/Filter/FilterActionButtons';
import SearchBox from '../../app_components/SearchBox/SearchBox';
import Dropdown from '../../app_components/Dropdown/Dropdown';
import { FaRetweet } from 'react-icons/fa';

const lookupselector = appAction => async props => {
	// pelease refer this to new.databrowser.js
	// on this section we collect all dependencies for databrowser
	const {
		// request dependencies
		apiInfo: { moduleId, dataId, authToken },
		arrFields,

		// static params / initial params
		params = {},
		availableExtraParams = [], // field of params that are available to send

		uiData = {},

		// just configuratio, please refer to function bellow
		options = {},
	} = props;

	// check if frameAction is available
	appAction.checkFrameAction();

	// this is for row click handle
	const onRowClick = fields => {
		if (fields) {
			var { __sysFields, ...restFields } = fields;
			appAction.frameAction.closeModal(restFields);
		} else if (fields === null) {
			appAction.frameAction.closeModal(null);
		}
	};

	// set if user define keyField, if not then use server key field
	const useServerFields = !arrFields || (Array.isArray(arrFields) && arrFields.length == 0);

	// get data from server,
	// first is to get metadata for corresponding table,
	// second is to fullfil needed of get directSelection, flow: input keyword, than if row was found, select it directly
	try {
		// on this section belbelowwo, please refer to new.databrowser.js,
		var response = await appAction.fetchResource(
			moduleId,
			params.resourceType ? params.resourceType : 'scroll_query',
			dataId,
			authToken,

			Object.assign({
				max_row: 10,
				...params,
				get_client_fields: useServerFields ? 'true' : 'false',
			}),
		);
	} catch (err) {
		await appAction.frameAction.showMessage(err.message, 'Fetch resource error', { messageType: 'error' });
		return null;
	}

	if (options.directSelection && response.rows.length == 0) {
		return null;
	}

	var serverKeyField;
	if (useServerFields) serverKeyField = response.key_field;

	const metadata = {
		table: {
			fields: useServerFields ? response.client_fields : [...arrFields],
			indexes: [useServerFields ? response.key_field : arrFields[0].name],
		},
	};
	const [dataProvider, dataStore] = jsdset.dsetMetaProviderEx(jsdset.dsetCreateContext(), metadata, { 'main:table': [] });
	dataStore.loadDataset('main', response.rows);

	if (options.directSelection && response.rows.length == 1) {
		return dataStore.datasets.main.rowFields[0];
	}

	const DataBrowserContainer = props => {
		const ActionButtons = React.useCallback(clearFilter => {
			return [
				{
					type: 'bordered',
					name: 'Reload',
					onClick: () => clearFilter(),
					render: () => <FaRetweet />,
				},
			];
		}, []);

		return (
			<DataBrowser
				stdAppAction={appAction}
				moduleId={moduleId}
				dataId={dataId}
				authToken={authToken}
				dsetPath="main"
				dataProvider={dataProvider}
				onRowClick={onRowClick}
				rowButtons={false}
				resourceType={params.resourceType ? params.resourceType : 'scroll_query'}
				params={params}
				availableExtraParams={availableExtraParams}
				autoLoad={options.pendingLoad || false}
				initialDataState={response}
				uiData={uiData}
				filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
					if (params.hideFilter === true) return false;

					return (
						<Filter>
							<Dropdown
								onChange={e =>
									changeParams({
										sortField: e.target.value,
									})
								}
								item={sortFields}
								value={params.sortField}
							/>

							<SearchBox handleSearch={src => changeParams({ src })} srcText={params.src} />
							<FilterActionButtons buttons={ActionButtons ? ActionButtons(clearFilter) : []} />
						</Filter>
					);
				}}
			/>
		);
	};

	var modalResult = await appAction.frameAction.showModalAsync({
		contentClass: DataBrowserContainer,
		headerProps: {
			title: 'Select Data',
			icon: 'FaUserPlus',
		},
		size: 'large',
		clickOverlayClose: true,
	});
	return modalResult;
};

export default lookupselector;
