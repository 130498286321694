/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransaction
  filename: digitalTransactionList.js
  moduleid: digitalTransactionList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			frameAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			staticComponents: {
				AlertComponent,
				PageTitle,
				Button,
				SearchBox,
				Checkbox,
				RadioGroup,
				Filter,
				DatePicker,
				Input,
				OTPComponent,
				FilterActionButtons,
				ReactIconFa: { FaRetweet },
				FormLabel,
				Dropdown,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule(
			'manajemen_transaksi.transaksi_menunggu_approval.transaksiMenungguApprovalMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					role: '',
					selected: [],
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);

						const { role } = await appAction.getState();

						setState(prevState => ({ ...prevState, popupComponent: popupComponent, role: role }));
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								clearFilter();
								handleFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const handleFilter = React.useCallback(
					e => {
						setFilter(e ? { ...filter, ...e } : initialFilter);
					},
					[filter],
				);

				const AlertHandler = async (msg, type, body) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				const handleCheckbox = e => {
					if (!state.selected.includes(e)) {
						const temp = state.selected;
						temp.push(e);
						setState(prevState => ({ ...prevState, selected: temp }));
					} else {
						const index = state.selected.indexOf(e);
						if (index !== -1) {
							const temp = state.selected;
							temp.splice(index, 1);
							setState(prevState => ({ ...prevState, selected: temp }));
						}
					}
				};

				const custFunc = (v, index) => {
					return (
						<Checkbox
							key={index}
							value={`${index.tx_ref_number}`}
							label=""
							onChange={() => handleCheckbox(index.tx_ref_number)}
							checked={state.selected.includes(index.tx_ref_number) ? true : false}
						/>
					);
				};

				const handleOtorisasi = async _ => {
					if (state.selected.length < 1)
						await frameAction.showModal({
							contentClass: props => (
								<AlertComponent {...props} title="Silahkan pilih data di checklist" type="warning" confirm={false} />
							),
							size: 'small',
						});
					else
						await frameAction.showModal({
							contentClass: p => {
								return (
									<div>
										<div
											style={{
												paddingTop: '2rem',
												paddingBottom: '2rem',
												fontSize: '1.2rem',
											}}
										>
											{p.message}
										</div>
										<div>
											<FormLabel label="Catatan 1">
												<Input
													onKeyUp={e => {
														setState({
															...state,
															comment1: e.target.value,
														});
													}}
												/>
											</FormLabel>
											{/* <FormLabel label="Catatan 2">
											<Input
												onKeyUp={e => {
													this.setState({
														...this.state,
														comment2: e.target.value,
													});
												}}
											/>
										</FormLabel> */}
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												marginTop: 30,
											}}
										>
											<Button
												type="bordered"
												style={{
													marginRight: 10,
												}}
												onClick={() => {
													p.closeModal();
												}}
											>
												Batal
											</Button>
											<Button
												onClick={() => {
													p.closeModal();
													p.handleAuthorize();
												}}
											>
												Proses
											</Button>
										</div>
									</div>
								);
							},
							headerProps: {
								title: 'Konfirmasi',
								icon: 'FaCheck',
							},
							contentProps: {
								message: 'Apakah anda yakin akan menyetujui transaksi ini?',
								handleAuthorize: handleAuthorize,
							},
							size: 'small',
						});
				};

				const handleAuthorize = async _ => {
					if (window.confirm('Apakah yakin ingin melakukan release?'))
						try {
							var response = await appAction.postData(_moduleId, 'otorisasiApproval', props._authToken, {
								selected: state.selected,
								current_workflow_state: 'R',
								comment1: state.comment1,
								comment2: state.comment2,
							});
							if (response.data.require_otp == 'T') {
								await askotp(response.data.otp_ref_number);
								setState({
									...state,
									otp_ref_number: response.data.otp_ref_number,
								});
							} else {
								AlertHandler('Proses Persetujuan berhasil', 'success');
							}
						} catch (e) {
							AlertHandler(e.message, 'error');
						}
				};

				const askotp = async notp_ref => {
					var otp = await frameAction.showModalAsync({
						contentClass: OTPComponent,
						contentProps: {
							_moduleId,
							_authToken: props._authToken,
							selected: state.selected,
							otp_ref_number: notp_ref,
							endpoint: 'otorisasiApproval',
						},
						size: 'small',
					});
					if (otp) {
						var { otp, otp_ref_number } = otp;
						if (!otp) return alert('Silakan isikan kode OTP.');
						var resp = await appAction.postData(_moduleId, 'otorisasiApproval', props._authToken, {
							selected: state.selected,
							current_workflow_state: state?.response?.current_workflow_state,
							otp,
							otp_ref_number,
						});
						if (resp.err_info) {
							AlertHandler(resp.err_info, 'error');
						} else if (resp) {
							AlertHandler('Proses persetujuan berhasil', 'success');
						}
					}
				};

				return (
					<>
						<PageTitle subtitle="Manajemen Transaksi" title="Transaksi Menunggu Persetujuan"></PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							resourceType={'method'}
							dataId="getTrxDraftList"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							autoLoad={false}
							params={filter}
							uiData={{
								main: {
									fields: {
										amount: { thousand: true, decimal: 2 },
										amount_fee: { thousand: true, decimal: 2 },
										checklist: { custFunc },
									},
								},
							}}
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<>
										<Filter>
											<FormLabel label="Status Workflow">
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: 5,
													}}
												>
													<RadioGroup
														data={Object.entries({
															'': 'Semua',
															C: 'Created',
															A: 'Authorized',
														})}
														name={'workflow_state'}
														currentValue={params.workflow_state}
														initialValue={''}
														type={'icon'}
														varian={'bordered'}
														onChange={e => {
															changeParams({
																workflow_state: e.target.value,
															});
														}}
													/>
												</div>
											</FormLabel>
											<FormLabel label="&nbsp;" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
												{state.role === 'releaser' && <Button onClick={() => handleOtorisasi()}>Otorisasi</Button>}
											</FormLabel>
										</Filter>

										<Filter>
											{/* Tanggal Eksekusi */}
											<FormLabel label="Tanggal Eksekusi">
												<div style={{ display: 'flex' }}>
													<DatePicker
														value={filter.start_date}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div style={{ padding: 10 }}>s.d.</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Sort & Search */}
											<FormLabel label="Urutkan & Cari">
												<div
													style={{
														display: 'flex',
														justifyContent: 'left',
														alignItems: 'center',
													}}
												>
													<Dropdown
														onChange={e =>
															changeParams({
																sortField: e.target.value,
															})
														}
														item={sortFields}
														value={params.sortField}
													/>
													<SearchBox
														label="Tampilkan"
														style={{
															marginLeft: 5,
															width: 300,
														}}
														handleSearch={src =>
															{
																try {
																	changeParams({
																		start_date: filter.start_date,
																		end_date: filter.end_date,
																		src: src,
																	})
																} catch (error) {
																	console.error('error search',error);
																}

															}
														}
														srcText={params.src}
													/>
												</div>
											</FormLabel>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													alignItems: 'center',
													marginTop: 10,
												}}
											>
												<FilterActionButtons buttons={ActionButtons(clearFilter)} />
											</div>
										</Filter>
									</>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
