/*
  DAFReact framework module - General library
  appname: ...
  filename: ...
  moduleid: ...
  author: ...
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  async function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { _moduleId, appAction, appcontext, useStdAppContext } = globals
    if (!appAction || !appcontext || !useStdAppContext) {
      throw new Error('One of required components (appAction or appcontext or useStdAppContext) not found in globals')
    }

    if (!_moduleId) {
      throw new Error('_moduleId not defined')
    }

    /* other required modules are asynchronously loaded here */
    // const importedModule = await appAction.fetchAndExecModule('someModule')

    const appGlobalState = {
      userId: '',
      themeColor: '',
      clickCount: 0
    }

    const appGlobalActionHandlers = {
      setUserId: (state, {userId}) => ({...state, userId}),
      setThemeColor: (state, {themeColor}) => ({...state, themeColor}),
      click: (state, {n}) => ({...state, clickCount: state.clickCount + (n || 1)})
    }

    class AppGlobalAction {
      constructor (dispatch, appAction) {
        this.dispatch = dispatch
        this.appAction = appAction
      }

      setUserId (userId) { this.dispatch({type: 'setUserId', userId}) }
      setThemeColor (themeColor) { this.dispatch({type: 'setThemeColor', themeColor}) }
      click (n = 1) { this.dispatch({type: 'click', n}) }
    }

    const AppGlobalContext = React.createContext()
    function AppGlobalProvider (props) {
      const [state, dispatch] = React.useReducer(appcontext.createReducer(appGlobalActionHandlers), appGlobalState)
      const [, appAction] = useStdAppContext()
      const actionObject = React.useMemo(() => new AppGlobalAction(dispatch, appAction), [dispatch, appAction])

      return (
        <div>
          <AppGlobalContext.Provider value={{state, dispatch, actionObject}}>
            {props.children}
          </AppGlobalContext.Provider>
        </div>
      )
    }

    const useAppGlobalState = () => appcontext.useDispatchContext(AppGlobalContext)

    function componentFactory (params) {

      /* module exports are defined here and are returned from componentFactory (for example: React component for application form) */

      // return value may be different depending on params
      return  { AppGlobalContext, AppGlobalProvider, useAppGlobalState }
    }

    return { componentFactory }
  }

  async function initModuleF(aReact, globals) {
    return await getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

