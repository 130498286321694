import React, { useEffect } from 'react';
import './Login.scss';
import Button from '../../Button/Button';
import FormLabel from '../../Forms/FormLabel/FormLabel';
import InputGroup from '../../InputGroupIcon/InputGroupIcon';
import { FaUser, FaUsers, FaLock, FaEnvelope } from 'react-icons/fa';
import { StdAppAction } from '../../../modules/com.ihsan/appdata.js';
import AlertComponent from '../../AlertComponent/AlertComponent';
import { isEmail, isExist } from '../../Validator/validatorInput';

const Forgot = props => {
	const refEmailInput = React.useRef(null);
	const refUserIDInput = React.useRef(null);
	const refStdAppAction = React.useRef(null);

	const [validator, setValidator] = React.useState({
		user: [false, 'Silakan isi User ID'],
		email: [false, 'Silakan isi Email'],
	});

	useEffect(() => {}, []);

	const kirimEmail = async () => {
		try {
			if (refEmailInput.current.value == '' && refUserIDInput.current.value == '') {
				throw 'Silahkan masukan Email dan User ID';
			}
			if (refEmailInput.current.value == '') {
				throw 'Silahkan masukan Email';
			}
			if (refUserIDInput.current.value == '') {
				throw 'Silahkan masukan User ID';
			}
			var emailRegx = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;
			if (!refEmailInput.current.value.match(emailRegx)) {
				throw 'Silahkan masukan email yg benar';
			}
			var [response, isError, errMessage] = await refStdAppAction.current.apiRequest(
				'app/forgotpassword?request=email_reset_password',
				{
					app_id: props.app_id,
					email_address: refEmailInput.current.value,
					user_id: refUserIDInput.current.value,
				},
				false,
				'POST',
			);
			if (response.status !== '000') {
				throw new Error(response.description);
			} else {
				alert(response.description);
				window.location.href = '/';
			}
		} catch (error) {
			AlertHandler(error.message, 'warning');
		}
	};

	const AlertHandler = async (msg, type) => {
		await refStdAppAction.current.frameAction.showModal({
			contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
			size: 'small',
		});
	};

	return (
		<>
			<StdAppAction ref={refStdAppAction} />

			<center className="title">Lupa Password</center>
			<form
				style={{
					paddingBottom: 10,
				}}
			>
				<FormLabel label="User ID">
					<InputGroup
						icon={<FaUser />}
						name="user"
						id="user"
						reference={refUserIDInput}
						// disabled={isLoading}
						// defaultValue={'kopbjbs01'}
						onChange={e => setValidator({ ...validator, user: isExist(e.target.value,'User ID') })}
						validator={validator.user}
					></InputGroup>
				</FormLabel>
				<FormLabel label="Alamat Email">
					<InputGroup
						icon={<FaEnvelope />}
						name="email"
						id="email"
						reference={refEmailInput}
						onChange={e => setValidator({ ...validator, email: isEmail(e.target.value) })}
						validator={validator.email}
					></InputGroup>
				</FormLabel>
			</form>
			<Button
				style={{ width: '100%' }}
				// reference={refBtnLogin}
				onClick={kirimEmail}
				// disabled={isLoading}
			>
				Kirim Email
			</Button>
			<br />
			<center>
				<a href="/">Kembali</a>
			</center>
		</>
	);
};

export default Forgot;
