import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import { FaCalendarAlt } from 'react-icons/fa';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DatePickerComponent = ({ value = new Date(), ...otherProps }) => {
	const [selectedDate, setDate] = useState(new Date());

	return (
		<div className="date-picker-container">
			<FaCalendarAlt className="calendar-icon" />
			<DatePicker
				className={'date-picker'}
				selected={value}
				{...otherProps}
			/>
		</div>
	);
};

export default DatePickerComponent;
