export const isExist = (value, Field, min, maks) => {
	if (!value || value === '') {
		return [false, Field + ' tidak boleh kosong'];
	} else {
		if (min || maks) {
			return value.length <= parseFloat(maks ? maks : 20) && value.length >= parseFloat(min ? min : 6)
				? [true, '']
				: [false, (min ? Field + ' setidaknya min ' + min + ' karakter dan ' : Field + ' ') + 'maks ' + (maks ? maks : 20) + ' karakter'];
		} else {
			return [true, ''];
		}
	}
}; // for input <20 character

export const isEmail = value => {
	if (!value || value === '') {
		return [false, 'Email tidak boleh kosong'];
	} else if (value.length < 6 || value.length > 50) {
		return [false, 'Silahkan masukan Email yang benar'];
	} else {
		var emailRegx = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;
		return value.match(emailRegx) ? [true, ''] : [false, 'Silahkan masukan Email yang benar'];
	}
}; // for email

export const isPasword = (value, type = 'new', fields) => {
	if (!value || value === '') {
		return [false, 'Kata sandi tidak boleh kosong'];
	} else {
		var passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/;
		var valid = value.match(passRegx);
		var errMsg = 'Kata sandi harus mengandung huruf kapital, huruf kecil, setidaknya satu karakter khusus dan minimal 8 karakter';

		if (type != 'new' && fields.new_password !== value) {
			valid = false;
			errMsg = 'Password baru dan konfirmasi password tidak sama';
		}

		var pwdLength = value.length >= 8 ? true : false;
		return valid && pwdLength ? [true, ''] : [false, errMsg];
	}
};

export const isPhone = value => {
	if (!value || value === '') {
		return [false, 'Nomor Telepon/Handphone tidak boleh kosong'];
	} else if (value.length < 6 || value.length > 15) {
		return [false, 'Silahkan masukan  nomor Telepon/Handphone yang benar'];
	} else {
		var phoneRegx = /^(^\+622[1-9]|^\+628[1-9]|622[1-9]|628[1-9]|^08[1-9]|^02[1-9])(\d{2,4}-?){2}\d{2,4}$/;
		return value.match(phoneRegx) ? [true, ''] : [false, 'Silahkan masukan nomor Telepon/Handphone yang benar'];
	}
}; // for indonesian phone number
