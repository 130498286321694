import React from 'react'; //--DAFReact: development

import Menu from '../Menu/Menu';

import logo from '../_assets/png/logo-putih.png';

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy
	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const { _moduleId, appAction, frameAction, useAppFrameContext } = globals;
		if (!appAction) {
			throw new Error('One of required components (appAction) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		/* other required modules are asynchronously loaded here */
		// const importedModule = await appAction.fetchAndExecModule('someModule')

		function componentFactory(params) {
			/* module exports are defined here and are returned from componentFactory (for example: React component for application form) */

			function AppForm(props) {
				const [state, setState] = React.useState({
					isError: false,
					errMsg: '',
					menu: [],
					handler: {},
					selectedMenu: {
						main: '',
						key: '',
					},
					activeHeader: '',
					activeMenu: '',
				});

				const MenuAutoKeyGenerator = {
					counter: 0,
					getNext: function () {
						this.counter += 1;
						return 'menuAutoKey_' + this.counter;
					},
				};

				React.useEffect(() => {
					(async function () {
						// loading support UI components required by menu event handlers (passed as globals)
						setState({ ...state, isError: false });

						try {
							// load main menu
							const menuSource = await appAction.fetchResource('main', 'menu', 'mainMenu');

							const modMenu = await appAction.evalModule(menuSource, {
								_moduleId: 'main',
								_resourceId: 'mainMenu',
							});

							const tokenResponse = await appAction.fetchResource('main', 'menu_tokens', 'mainMenu', 'dummy');

							appAction.setAccessToken('main', 'menu', 'mainMenu', tokenResponse.tokens);

							const { menuStructure, menuHandlers } = modMenu.componentFactory();

							function _scanEmptyKeys(items) {
								if (!Array.isArray(items)) throw new Error('menuItems parameter or its submenu must be array');
								return items.map(item => {
									var returnned = {
										...item,
										key: item.key || MenuAutoKeyGenerator.getNext(),
									};

									if (item.items) returnned.items = _scanEmptyKeys(item.items);

									return returnned;
								});
							}

							setState({
								...state,
								menu: _scanEmptyKeys(menuStructure),
								handler: menuHandlers,
							});
						} catch (err) {
							setState({
								...state,
								...{ isError: true, errMsg: err.message },
							});
						}
					})();
				}, []);

				const handleNavigation = e => {
					setState({
						...state,
						activeMenu: e.key,
						activeHeader: e.main || e.key,
					});
					var menuKey = e.key;
					const handler = state.handler[menuKey];
					if (handler && typeof handler === 'function') {
						if (typeof props.hidePopup === 'function') {
							props.hidePopup(null);
						}
						if (handler.constructor.name === 'AsyncFunction') {
							handler(appAction, menuKey, e, props).then(() => {});
						} else {
							handler(appAction, menuKey, e, props);
						}
					}
				};

				const handleChangeActiveHeader = e => {
					setState({
						...state,
						activeHeader: state.activeHeader === e ? '' : e,
					});
				};

				return (
					<div>
						<div
							style={{
								height: 200,
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<img style={{ alignSelf: 'center', width: '70%' }} src={logo} />
						</div>
						<div>
							{state.menu.length > 0 &&
								state.menu.map((i, k) => {
									return (
										<Menu
											key={k}
											menuKey={i.key}
											title={i.title}
											submenu={i.items}
											handleChangeActiveHeader={e => handleChangeActiveHeader(e)}
											handleClick={({ main, key }) =>
												handleNavigation({
													key,
													main,
												})
											}
											activeMenu={state.activeMenu}
											activeHeader={state.activeHeader}
											icon={i.icon}
										/>
									);
								})}
						</div>
					</div>
				);
			}
			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
