import React from 'react'; //--DAFReact: development
import './Radio.scss';

export default function Radio({
	label = 'No Label',
	onChange = () => console.log('Radio selected...'),
	id = '',
	name = '',
	icon,
	style,
	types = 'bordered',
	reference = null,
	...otherProps
}) {
	return (
		<label className="containerts" style={style}>
			<input
				id={id}
				onChange={onChange}
				type="radio"
				name={'radio_' + name}
				ref={reference}
				{...otherProps}
			></input>
			<span
				className={types === 'bordered' ? 'checkmarks' : 'checkmarks2'}
			>
				<span className="icons">{icon}</span> {'   '}
				{label}
			</span>
		</label>
	);
}
