// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { AlertComponent, Button, FormLabel, ViewTransaksiTerjadwal, OTPComponent },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
			'transfer.rekening_sama_bank.rekeningSamaBankMetadata',
		);

		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);

				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isLoading: true,
					isErr: false,
					errMessage: '',
				});

				const [terjadwalState, seTerjadwal] = React.useState({});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.loadStore(props.data, 'std', serverDataMapping, true);

						if (state.isLoading == true) {
							try {
								await seTerjadwal(props.data.data[0].terjadwal);
								const response = await appAction.fetchResource(
									props._moduleId,
									'method',
									'getConfirmation',
									props._authToken,
									props.data.data[0],
									true,
								);

								dsMainAction.setFields(response.resp);
								setState({ ...state, isLoading: false });
							} catch (error) {
								props.closeModal(false);
								AlertHandler(error.message, 'error');
							}
						}
					})();
				}, [dsMainAction, props.uiMode, props.data]);

				const handleValidation = () => {
					const { fieldValidStates, fieldValidErrors } = dsMainProxy;
					const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
						return !v;
					});

					if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);
				};

				const handleConfirm = async (action = false) => {
					try {
						handleValidation();

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});
						if (dataUnload.data?.length > 0) {
							var data = dsMainProxy.fields;
							data.isProcess = action;
							data.tx_ref_number = data.txdraft_ref_number;

							var resp = await appAction.postData(props._moduleId, 'saveData', props._authToken, data);
							if (resp.isErr) throw new Error(resp.isErr);

							if (resp.data && resp.data.require_otp == 'T') {
								var otp = await handleOtp({
									endpoint: 'saveData',
									tx_ref_number: data.tx_ref_number,
									otp_ref_number: resp.data.otp_ref_number,
								});
								if (otp) {
									var { otp, otp_ref_number } = otp;
									if (!otp) return alert('Silakan isikan kode OTP.');
									var resp = await appAction.postData(props._moduleId, 'saveData', props._authToken, {
										otp,
										otp_ref_number,
										...data,
									});
									if (resp.err_info) throw new Error(resp.err_info);
								} else {
									props.closeModal(action);
									return;
								}
							}

							props.closeModal(action);
							if (action) AlertHandler('Proses transaksi ' + (action ? 'berhasil' : 'dibatalkan'), 'success');
						}
					} catch (err) {
						props.closeModal(false);
						AlertHandler(err.message, 'alert');
					}
				};

				const handleOtp = async p => {
					var _moduleId = props._moduleId;
					var _authToken = props._authToken;
					return await frameAction.showModalAsync({
						contentClass: props => <OTPComponent {...props} {...{ ...p, appAction, _moduleId, _authToken }} />,
						size: 'small',
					});
				};

				const AlertHandler = async (msg, type) => {
					return await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				return (
					<div>
						{state.isLoading === false && !state.isErr && (
							<>
								<FormLabel label="Rekening Sumber">
									<b>
										<mainComps.FieldDataDisplay fieldName="account_no" /> -{' '}
										<mainComps.FieldDataDisplay fieldName="account_name" />
									</b>
								</FormLabel>
								<FormLabel label="Rekening Tujuan">
									<b>
										<mainComps.FieldDataDisplay fieldName="credit_account_no" /> -{' '}
										<mainComps.FieldDataDisplay fieldName="credit_account_name" />
									</b>
								</FormLabel>

								{terjadwalState &&
									terjadwalState.active === true && ( // need repair (Terjadwal)
										<ViewTransaksiTerjadwal terjadwalState={terjadwalState} />
									)}

								<FormLabel label="Jumlah">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</b>
								</FormLabel>
								<FormLabel label="Biaya Transaksi">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_fee" />
									</b>
								</FormLabel>
								<FormLabel label="Berita">
									<b>
										{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? (
											<mainComps.FieldDataDisplay fieldName="description" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="No. Dokumen">
									<b>
										{dsMainProxy.fields.external_ref_number && dsMainProxy.fields.external_ref_number !== '' ? (
											<mainComps.FieldDataDisplay fieldName="external_ref_number" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="Tags">
									<b>
										<mainComps.FieldDataDisplay fieldName="tags" /> - <mainComps.FieldDataDisplay fieldName="tag_desc" />
									</b>
								</FormLabel>
								<div
									style={{
										marginTop: 20,
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<>
										<Button
											onClick={() => {
												handleConfirm(true);
											}}
											style={{
												marginRight: 10,
											}}
											disabled={state.isLoading}
										>
											Konfirmasi
										</Button>
										<Button
											onClick={() => {
												handleConfirm(false);
											}}
											type="bordered"
											disabled={state.isLoading}
										>
											Batalkan
										</Button>
									</>
								</div>
							</>
						)}

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
