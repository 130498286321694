/*
  DAFReact framework module - Application form (hook)
  appname: historiTransaksi
  filename: historiTransaksiList.js
  moduleid: historiTransaksiList
  author: IK
*/

import React from 'react';

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Dropdown,
				FormLabel,
				DatePicker,
				Input,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa: { FaRetweet, FaRegFilePdf },
				dataselector,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { editUIData } = await appAction.fetchAndExecModule('manajemen_akun.histori_transaksi.historiTransaksiMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					status: [],
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					account_no: '',
					account_name: '',
					status: '',
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(_moduleId, 'method', 'getStatusTransaksi', props._authToken, {}, true);

						if (response?.rows) {
							let tmp = [{ value: null, name: 'Semua' }];
							for (var x of response?.rows) {
								tmp.push({
									value: x.id,
									name: x.description,
								});
							}

							setState(prevState => ({
								...prevState,
								status: tmp,
							}));
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [props._authToken]);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
						loadData();
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Print Excel',
								onClick: async () => {
									try {
										await appAction.postData(_moduleId, 'importList', props._authToken, { ...params, ...filter });
									} catch (err) {
										AlertHandler(err.message, 'alert');
									}
								},
								render: () => <FaRegFilePdf />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									handleFilter();
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[filter],
				);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				const handleLookup = async () => {
					if (!dataselector) return false;

					var getRekening = await dataselector(appAction)({
						apiInfo: {
							moduleId: _moduleId,
							dataId: 'listAccount',
							authToken: props._authToken,
						},
					});

					if (getRekening) {
						handleFilter({
							account_name: getRekening.account_name,
							account_no: getRekening.account_no,
						});
					}
				};

				return (
					<>
						<PageTitle title="Histori Transaksi Keluar" subtitle="Manajemen Akun" style={{ marginBottom: `1.5rem` }} />

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							// resourceType="method"
							dataId="listSO"
							autoLoad={false}
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							params={params}
							// rowButtons={null}
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<>
										<Filter>
											{/* Pilih Rekening */}
											<FormLabel
												label="Pilih Rekening"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div style={{ display: 'flex' }}>
													<Input
														readOnly={true}
														placeholder={'--Pilih Rekening--'}
														value={
															filter.account_no || params.account_no
																? (filter.account_no || params.account_no) + ' - ' + (filter.account_name || params.account_name)
																: null
														}
														onClick={handleLookup}
													/>
												</div>
											</FormLabel>

											{/* Status Transaksi */}
											<FormLabel
												label="Status Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												{state.status ? (
													<Dropdown
														style={{
															width: '250px',
														}}
														onChange={e => {
															handleFilter({
																status: e.target.value,
															});
														}}
														item={state.status}
														value={filter.status}
													/>
												) : null}
											</FormLabel>
										</Filter>

										<Filter>
											{/* Tanggal */}
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={filter.start_date}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div style={{ padding: 10 }}>s.d.</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Sort & Search */}
											<FormLabel label="Urutkan & Cari">
												<div style={{ display: 'flex' }}>
													<Dropdown
														style={{
															width: '250px',
														}}
														onChange={e =>
															changeParams({
																sortField: e.target.value,
															})
														}
														item={sortFields}
														value={params.sortField}
													/>
													<SearchBox
														label="Tampilkan"
														style={{
															marginLeft: 5,
															width: '250px',
														}}
														handleSearch={src => {
															if (!filter.account_no) return alert('Silahkan isi nomor rekening.');
															changeParams({
																start_date: filter.start_date,
																end_date: filter.end_date,
																src: src,
																account_no: filter.account_no,
																status: filter.status,
															});
														}}
														srcText={params.src}
													/>
												</div>
											</FormLabel>

											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</Filter>
									</>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
