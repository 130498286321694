import React from 'react';
import './Button.scss';

export default function Button({
	children = '',
	style = {},
	size = 'medium', // small, medium, large
	color = 'primary', // primary, secondary, succes, cancel, disabled
	type = '', // bordered, borderless
	variant = '', // rounded or null
	reference = null,
	...otherProps
}) {
	return (
		<button
			className={['button', size, color, type].join(' ')}
			style={{
				borderRadius: variant === 'rounded' ? 50 : 2.5,
				paddingLeft: 15,
				paddingRight: 15,
				...style,
			}}
			ref={reference}
			{...otherProps}
		>
			{children}
		</button>
	);
}
