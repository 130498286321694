import React, { useState } from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				PageTitle,
				Button,
				FormLabel,
				Dropdown,
				AlertComponent,
				TransaksiTerjadwal,
				RekeningFavorite
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule('pembelian.pulsa.pulsaMetadata');

		const componentClass = await appAction.fetchAndExecModule(
			'pembelian.pulsa.pulsaEditNewKonfirmasi',
		);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);

				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					rekening: [],
					selectRekening: '',
					selectNominal: '',
					selectProvider: '',

					terjadwalState: {},
				});
				{/* untuk rekening favorite */ }
				const favref = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				const [nmnl, setNmnl] = useState([])

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const rekening = await appAction.fetchResource(
							_moduleId,
							'method',
							'getRekening',
							props._authToken,
							{},
							true,
						);

						const nominal = await appAction.fetchResource(
							_moduleId,
							'method',
							'getNominal',
							props._authToken,
							{},
							true,
						);


						const provider = await appAction.fetchResource(
							_moduleId,
							'method',
							'getProvider',
							props._authToken,
							{},
							true,
						);

						if (rekening?.rows && nominal?.rows) {
							let tmpRekening = [
								{ value: '', name: 'Pilih Rekening' },
							];
							for (var x of rekening?.rows) {
								tmpRekening.push({
									value: x.account_no,
									name:
										x.account_no +
										' - ' +
										x.account_name.toUpperCase(),
									account_name: x.account_name,
								});
							}

							let tmpNominal = [
								{ value: '', name: 'Pilih Nominal' },
							];
							setNmnl(nominal?.rows)
							for (var x of nominal?.rows) {
								tmpNominal.push({
									value: x.id,
									name:
										x.provider +
										' - ' +
										x.nominal.toUpperCase(),
									nominal: x.nominal,
								});
							}

							let tmpProvider = [
								{ value: '', name: 'Pilih Provider' },
							];
							for (var x of provider?.rows) {
								tmpProvider.push({
									value:
										x.product_code,
									name:
										x.product_code + ' - ' + x.description.toUpperCase(),
									description: x.description,
								});
							}

							setState(prevState => ({
								...prevState,
								rekening: tmpRekening,
								nominal: tmpNominal,
								provider: tmpProvider,

							}));
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.id_transaction]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({});
						loadData();
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				const handleConfirm = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } =
							dsMainProxy;
						const isInValid = Object.entries(
							fieldValidStates,
						).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0)
							throw new Error(fieldValidErrors[isInValid[0][0]]);

						if (componentClass) {
							var dataUnload = dsMainProxy.unloadStore(
								serverDataMapping,
								{
									includeLoadedRows: false,
									includeDeletedRows: true,
								},
							);

							// create redis confirmation to trans BE
							// await appAction.postData(
							// 	_moduleId,
							// 	'createConfirmation',
							// 	props._authToken,
							// 	dataUnload,
							// );

							// need revision
							if (dataUnload.data.length > 0) {

								dataUnload.data[0]['terjadwal'] = state.terjadwalState
								dataUnload.data[0]['tx_code'] = 'BLP'

								const modalshow = await frameAction.showModalAsync({
									headerProps: {
										title: 'Konfirmasi Pembelian',
										icon: 'FaInfoCircle',
									},
									contentClass: componentClass,
									contentProps: {
										_moduleId,
										_authToken: props._authToken,
										data: dataUnload,
									},
									size: 'large',
								});

								if (modalshow) {
									dsMainAction.deleteRow();
									dsMainAction.addRow({});
									setState({
										...state,
										selectRekening: '',
										selectNominal: '',
									});
									{/* untuk reset rekening favorite */ }
									favref.current.checked = false
								}
							}
						}
					} catch (error) {
						AlertHandler(error.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				const handlePilihRekening = (e, field, direct, name) => {
					let rekening = state.rekening
						.filter(key => key['value'].includes(e.target.value))
						.map(datafilter => {
							return datafilter.account_name;
						});
					dsMainAction.setFields({
						[field]: e.target.value,
						[name]: rekening[0],
					});
					setState({
						...state,
						[direct]: e.target.value,
					});
				};

				const handlePilihNominal = (e, field, direct, name) => {
					let nominal = state.nominal
						.filter(key => key['value'].includes(e.target.value))
						.map(datafilter => {
							return datafilter.nominal;
						});
					dsMainAction.setFields({
						[field]: e.target.value,
						[name]: nominal[0],
						amount: nominal[0],
					});
					setState({
						...state,
						[direct]: e.target.value,
					});
				};

				const handlePilihProvider = (e, field, direct, name) => {
					let provider = state.provider
						.filter(key => key['value'].includes(e.target.value))
						.map(datafilter => {
							return datafilter.description;
						});
					dsMainAction.setFields({
						[field]: e.target.value,
						[name]: provider[0],
					});
					// console.log(provider[0])
					let nom = nmnl.filter(n => provider[0].includes(n.provider))
					if(provider[0].toLowerCase().includes('three'))
					{
						nom = nmnl.filter(n => n.provider === 'Tri')
					}
					let tmpNominal = [
						{ value: '', name: 'Pilih Nominal' },
					];
					for (var x of nom) {
						tmpNominal.push({
							value: x.id,
							name:
								x.provider +
								' - ' +
								x.nominal.toUpperCase(),
							nominal: x.nominal,
						});
					}
					setState({
						...state,
						nominal: tmpNominal,
						[direct]: e.target.value,
					});
				};

				const handleTerjadwal = (terjadwalState = {}) => {
					setState({ ...state, terjadwalState });
				};

				return (
					<div className="row">
						<PageTitle subtitle="Pembelian" title="Pulsa" />

						<div className="col-lg-6">
							<div className="col-cl-12">
								<FormLabel label="Rekening Sumber">
									{/* <Dropdown
										onChange={e => {
											handlePilihRekening(
												e,
												'account_no',
												'selectRekening',
												'account_name',
											);
										}}
										isSearchable={true}
										placeholder={'Pilih Rekening'}
										value={state.selectRekening}
										item={state.rekening}
										width="-webkit-fill-available"
									/> */}
									<mainComps.FieldDataInput fieldName="account_no" />

								</FormLabel>

								<FormLabel label="Provider">
									<Dropdown
										onChange={e => {
											handlePilihProvider(
												e,
												'product_code',
												'selectProvider',
												'product_name',
											);
										}}
										isSearchable={true}
										placeholder={'Pilih Provider'}
										value={state.selectProvider}
										item={state.provider}
										width="-webkit-fill-available"
									/>
								</FormLabel>

								<FormLabel label="Nomor Telepon">
									<mainComps.FieldDataInput fieldName="credit_account_no" />
								</FormLabel>

								{/* <FormLabel label="Nominal">
									<Dropdown
										onChange={e => {
											handlePilihNominal(
												e,
												'amount',
												'selectNominal',
												'',
											);
										}}
										isSearchable={true}
										placeholder={'Pilih Nominal'}
										value={state.selectNominal}
										item={state.nominal}
										width="-webkit-fill-available"
									/>
								</FormLabel> */}

								<TransaksiTerjadwal
									handleTerjadwal={handleTerjadwal}
								/>
								{/* untuk rekening favorite */}
								<RekeningFavorite {...props} mainComps={mainComps} dsMainAction={dsMainAction} dsMainProxy={dsMainProxy} reference={favref} />


								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<Button
										onClick={() => {
											handleConfirm();
										}}
									>
										Konfirmasi
									</Button>
								</div>
							</div>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
