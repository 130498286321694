export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				transaksiTerjadwal: {
					fields: [
						{
							name: 'id_tx_draft',
							type: 'string',
						},
						{
							name: 'tx_ref_number',
							type: 'string',
						},
						{
							name: 'account_no',
							type: 'string',
							length: 20,
						},
						{
							name: 'description',
							type: 'string',
							length: 20,
						},
						{
							name: 'credit_account_no',
							type: 'string',
							length: 50,
						},
						{
							name: 'credit_account_name',
							type: 'string',
							length: 150,
						},
						{
							name: 'execution_status',
							type: 'string',
						},
						{
							name: 'execution_desc',
							type: 'string',
						},
						{
							name: 'entry_datetime',
							type: 'date',
						},
						{
							name: 'tx_mode',
							type: 'string',
							length: 20,
						},
						{
							name: 'tx_desc',
							type: 'string',
							length: 50,
						},
						{
							name: 'product_desc',
							type: 'string',
							length: 50,
						},
						{
							name: 'amount',
							type: 'float',
						},
						{
							name: 'amount_fee',
							type: 'float',
						},
						{
							name: 'sched_status',
							type: 'string',
						},
						{
							name: 'sched_period',
							type: 'string',
						},
						{
							name: 'sched_day',
							type: 'string',
						},
						{
							name: 'sched_date',
							type: 'date',
						},
						{
							name: 'sched_count',
							type: 'int',
						},
						{
							name: 'sched_exec_datetime',
							type: 'date',
						},
					],

					indexes: ['id_tx_draft'],
				},
			};

			const initialData = {
				'main:transaksiTerjadwal': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'transaksiTerjadwal',
					fieldMapping: {
						'*': true,
					},
				},
			};

			const editUIData = {
				transaksiTerjadwal: {
					fields: {
						amount: { thousand: true, decimal: 2 },
						amount_fee: { thousand: true, decimal: 2 },
						sched_exec_datetime: {dateformat: 'ddd, MMM D, YYYY'}
					},
				},
			};

			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
