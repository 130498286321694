import React from 'react';

import './OTPComponent.scss';

import Input from '../../Forms/Input/Input';
import Button from '../../Button/Button';

const OTPComponent = props => {
	const [otp, setOtp] = React.useState({
		otp: props.otp,
		otp_ref_number: props.otp_ref_number,
		tx_ref_number: props.tx_ref_number,
	});

	const [state, setState] = React.useState({ time: {}, seconds: 0 });

	const handleOnChange = e => {
		var v = e.target.value;
		setOtp({ ...otp, otp: v });
	};

	const handleCancelOTP = e => {
		props.closeModal(false);
	};

	const handleConfirmOTP = e => {
		if (!otp.otp) return alert('Silakan isikan kode OTP.');
		props.closeModal(otp);
	};

	const handleRequestOTP = async e => {
		var resp = await props.appAction.postData(props._moduleId, 'authorize', props._authToken, {
			tx_ref_number: otp.tx_ref_number,
			rerequest_otp: 'T',
			otp_ref_number: otp.otp_ref_number,
		});
		setOtp({ ...otp, otp_ref_number: resp.data?.otp_ref_number || null });
		if (resp.isErr) throw new Error(resp.isErr);
	};

	const secondsToTime = secs => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		let obj = {
			h: hours,
			m: minutes,
			s: seconds,
		};
		return obj;
	};

	// set event on component mounting
	React.useEffect(() => {
		(async function () {
			hitungMundur();
		})();
	}, []);

	const hitungMundur = () => {
		var timeleft = 30;
		var downloadTimer = setInterval(function () {
			timeleft -= 1;
			setState({
				...state,
				time: secondsToTime(timeleft),
				seconds: timeleft,
			});
			if (timeleft === 0) {
				clearInterval(downloadTimer);
				return false;
			}
		}, 1000);
	};

	return (
		<div align="center">
			<p>Kode OTP akan di kirimkan ke device anda, </p>
			<p>
				<h3>Silakan masukan OTP anda.</h3>
			</p>
			<p>
				<Input maxLength={6} onChange={handleOnChange} value={otp.otp || ''} />
			</p>
			<p>
				{state.seconds == 0 ? (
					<a
						href="/#"
						onClick={e => {
							e.preventDefault();
							handleRequestOTP();
							hitungMundur();
						}}
					>
						Kirim ulang
					</a>
				) : (
					<>
						<b>
							{state.time.m > 0 ? state.time.m + ' Menit' : ''} {state.time.s > 0 ? state.time.s + ' Detik Untuk ' : ''}
						</b>
						<u
							style={{
								cursor: 'not-allowed',
							}}
						>
							Kirim ulang
						</u>
					</>
				)}
			</p>

			<div>
				<Button type="bordered" style={{ marginRight: 10 }} onClick={handleCancelOTP}>
					Batal
				</Button>
				<Button onClick={handleConfirmOTP}>Proses Otorisasi</Button>
			</div>
		</div>
	);
};

export default OTPComponent;
