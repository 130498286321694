import React from 'react';

import './Spinner.scss';

const Spinner = ({ size = 35, dark, customStyle }) => {
	const borderColor = dark ? 0 : 255;
	const preDefinedStyle = {
		borderWidth: Math.ceil(size / 7),
		borderColor: `rgba(${borderColor}, ${borderColor}, ${borderColor}, 0.15)`,
		borderTopColor: `rgba(${borderColor}, ${borderColor}, ${borderColor}, 0.8)`,
		borderStyle: 'solid',
	};
	return (
		<div
			className="spinner"
			style={{
				width: size,
				height: size,
				...customStyle,
				...preDefinedStyle,
			}}
		/>
	);
};

export default Spinner;
