// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			NDataBrowser,
			staticComponents: {
				Button,
				FormLabel,
				AlertComponent,
				SearchBox,
				Filter,
				FilterActionButtons,
				Dropdown,
				RadioGroup,
				ReactIconFa: { FaRetweet },
				ReactIconSi: { SiMicrosoftexcel },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('manajemen_akun.histori_transaksi.historiTransaksiTagsMetadata');

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					isErr: false,
					errMessage: '',
					isEditing: false,
					userInfo: {},
				});

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				const AlertHandler = async (msg, type, body) => {
					await appAction.frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} body={body} />,
						size: 'small',
					});
				};

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, { id_transaction: params.id_transaction }, true);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.ref_number]);

				// const fetchTxCode = React.useCallback(async () => {
				// 	setState(state => ({
				// 		...state,
				// 		isErr: false,
				// 		errMessage: '',
				// 		txlist: [],
				// 	}));

				// 	try {
				// 		const response = await appAction.fetchResource(
				// 			_moduleId,
				// 			'method',
				// 			'getTxCode',
				// 			props._authToken,
				// 			{
				// 				is_financial: true,
				// 			},
				// 			true,
				// 		);

				// 		if (response?.data?.length > 0) {
				// 			var dbtxlist = [];
				// 			var crtxlist = [];
				// 			response.data.map(i => {
				// 				if (i.dc_flag == 'D') dbtxlist.push(i);
				// 				if (i.dc_flag == 'C') crtxlist.push(i);
				// 			});
				// 			setState({ ...state, dbtxlist, crtxlist });
				// 		}
				// 	} catch (err) {
				// 		setState(prevState => ({
				// 			...prevState,
				// 			isErr: true,
				// 			errMessage: err.message,
				// 		}));
				// 	}
				// }, []);

				// set event on component mounting

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						await loadData();
						const { user_name, bank, role } = await appAction.getState();
						setState({
							...state,
							popupComponent,
							userInfo: {
								user_name,
								bank,
								role,
							},
						});
					})();
				}, [props._authToken, dsMainAction, loadData, props.uiMode]);

				const initialFilter = {};
				const [filter, setFilter] = React.useState(initialFilter);
				const ActionButtons = React.useCallback(({ clearFilter, params }) => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								setFilter(initialFilter);
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				});

				const handleHapusTags = async p => {
					if (window.confirm('Apakah anda yakin akan menghapus tag ini?')) {
						try {
							const response = await appAction.fetchResource(
								_moduleId,
								'method',
								'deleteTag',
								props._authToken,
								{
									id_tag: p.id_tag,
								},
								true,
							);
							if (response.status === '000') AlertHandler('Hapus Tag Berhasil', 'success');
						} catch (err) {
							AlertHandler(err.message, 'alert');
						}
					}
				};

				const handleMode = () => {
					setState(prevState => ({ ...prevState, isEditing: !state.isEditing }));
				};

				const saveDataClick = async () => {
					var cstate = await appAction.getState();
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;

						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});

						console.log(dataUnload.data);
						// if (dataUnload?.data?.length > 0) {
						// 		if (dataUnload.data[0].old_password !== cstate.globals.password) {
						// 				throw new Error('Kata Sandi Sebelumnya yang Anda masukkan salah ');
						// 		}
						// }

						await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);

						// appAction.frameAction.closeModal();
						AlertHandler('Data berhasil disimpan', 'success');
						dsMainAction.setField('tag_code', '');
						dsMainAction.setField('tag_amount', '');
						setState(prev => ({ ...prev, isEditing: !state.isEditing }));
					} catch (err) {
						AlertHandler(err.message, 'alert');
					}
				};
				// const handleButton = async get => {
				// 	var total_debet = dsMainProxy.fields.total_debet || 0;
				// 	var total_credit = dsMainProxy.fields.total_credit || 0;

				// 	if (
				// 		window.confirm(
				// 			'total debet = ' +
				// 				total_debet +
				// 				', total credit = ' +
				// 				total_credit +
				// 				', Apakah anda yakin Untuk ' +
				// 				(get == 'authorize' ? 'Meng-Otorisasi' : 'Batalkan Otorisasi') +
				// 				' data ini?',
				// 		)
				// 	) {
				// 		try {
				// 			const response = await appAction.fetchResource(
				// 				_moduleId,
				// 				'method',
				// 				get,
				// 				props._authToken,
				// 				{
				// 					ref_number: props.ref_number,
				// 					statement_id: dsMainProxy.fields.statement_id,
				// 				},
				// 				true,
				// 			);
				// 			appAction.frameAction.closeModal();
				// 			AlertHandler(response.message, response.err_info);
				// 		} catch (err) {
				// 			AlertHandler(err.message, 'alert');
				// 		}
				// 	}
				// };

				// const handleDeleteButton = async () => {
				// 	if (window.confirm('Apakah anda yakin akan mnenghapus statement ini?')) {
				// 		try {
				// 			const response = await appAction.fetchResource(
				// 				_moduleId,
				// 				'method',
				// 				'deleteStatement',
				// 				props._authToken,
				// 				{
				// 					statement_id: dsMainProxy.fields.statement_id,
				// 				},
				// 				true,
				// 			);
				// 			AlertHandler(response.message, response.err_info);
				// 		} catch (err) {
				// 			AlertHandler(err.message, 'alert');
				// 		}
				// 	}
				// };

				// const handleAddSTDetail = async () => {
				// 	const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
				// 		pathName + '-view-addDetail',
				// 		{},
				// 		{},
				// 		{
				// 			menuId: 'popup_menu',
				// 			menuModuleId: _moduleId,
				// 			key: 'mnuTambahStatementDetail',
				// 		},
				// 	);
				// 	if (componentClass && _authToken)
				// 		await appAction.frameAction.showModalAsync({
				// 			headerProps: {
				// 				title: 'Tambah / Upload Statement Detail',
				// 				icon: 'FaPlus',
				// 			},
				// 			contentClass: componentClass,
				// 			contentProps: {
				// 				_authToken,
				// 				uiMode: 'add',
				// 				statement_id: dsMainProxy.fields.statement_id,
				// 				account_no: dsMainProxy.fields.account_no,
				// 			},
				// 			size: 'large',
				// 		});
				// };

				// const handleUploadSTDetail = async () => {
				// 	const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
				// 		pathName + '-view-uploadDetail',
				// 		{},
				// 		{},
				// 		{
				// 			menuId: 'popup_menu',
				// 			menuModuleId: _moduleId,
				// 			key: 'mnuUploadTambahStatementDetail',
				// 		},
				// 	);

				// 	if (componentClass && _authToken)
				// 		await frameAction.showModalAsync({
				// 			headerProps: {
				// 				title: 'Tambah / Upload Statement per Account',
				// 				icon: 'FaPlus',
				// 			},
				// 			contentClass: componentClass,
				// 			contentProps: {
				// 				_authToken,
				// 				uiMode: 'add',
				// 				account_no: state.account_no,
				// 			},
				// 			size: 'large',
				// 		});
				// };

				// const handleHapusDetail = async p => {
				// 	var addText = p.report_id ? ' Statement sudah dilaporkan sebelumnya.' : '';
				// 	if (window.confirm('Apakah anda yakin akan menghapus detail statement ini?' + (addText ? addText : ''))) {
				// 		try {
				// 			const response = await appAction.fetchResource(
				// 				_moduleId,
				// 				'method',
				// 				'deleteStatementDetail',
				// 				props._authToken,
				// 				{
				// 					stdetail_id: p.stdetail_id,
				// 				},
				// 				true,
				// 			);
				// 			appAction.frameAction.closeModal();
				// 			AlertHandler(response.message, response.err_info);
				// 		} catch (err) {
				// 			AlertHandler(err.message, 'alert');
				// 		}
				// 	}
				// };

				return (
					<div>
						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="No. Transaksi">
								<b>
									<mainComps.FieldDataDisplay fieldName="id_transaction" />
								</b>
							</FormLabel>
							<FormLabel style={{ width: '100%' }} label="Tanggal Input">
								<b>
									<mainComps.FieldDataDisplay fieldName="tx_date" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="Dari Rekening">
								<b>
									<mainComps.FieldDataDisplay fieldName="account_no" />
								</b>
							</FormLabel>
							<FormLabel style={{ width: '100%' }} label="Nama Rekening">
								<b>
									<mainComps.FieldDataDisplay fieldName="account_name" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="Ke Rekening">
								<b>
									<mainComps.FieldDataDisplay fieldName="credit_account_no" />
								</b>
							</FormLabel>
							<FormLabel style={{ width: '100%' }} label="Nama Rekening Tujuan">
								<b>
									<mainComps.FieldDataDisplay fieldName="credit_account_name" />
								</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel style={{ width: '100%' }} label="Ke Rekening">
								<b>
									<mainComps.FieldDataDisplay fieldName="credit_account_no" />
								</b>
							</FormLabel>
							<FormLabel style={{ width: '100%' }} label="Jumlah">
								<b>
									<mainComps.FieldDataDisplay fieldName="amount" />
								</b>
							</FormLabel>
						</div>

						{state.isEditing ? (
							<div>
								<FormLabel label="Kode Tag">
									<mainComps.FieldDataInput fieldName="tag_code" />
								</FormLabel>

								<FormLabel label="Jumlah">
									<mainComps.FieldDataInput fieldName="tag_amount" />
								</FormLabel>
							</div>
						) : null}

						<div>
							{state.userInfo.role === 'releaser' ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
									{state.isEditing ? <Button onClick={() => saveDataClick()}>Simpan</Button> : <Button onClick={() => handleMode()}>Tambah Tags</Button>}
								</div>
							) : null}
							<div style={{ overflow: 'auto' }}>
								<NDataBrowser
									stdAppAction={appAction}
									moduleId={_moduleId}
									resourceType="scroll_query"
									dataId="listSO"
									dataProvider={dsetProvider}
									dsetPath="main"
									authToken={props._authToken}
									params={{ id_transaction: params.id_transaction.toString() }}
									autoLoad={true}
									uiData={{
										main: {
											fields: {
												amount: { thousand: true, decimal: 2 },
											},
										},
									}}
									popupComponent={p => <state.popupComponent {...p} handleHapusTags={handleHapusTags} />}
									selFields={['id_tag', 'id_transaction', 'tag_code', 'tag_date', 'tag_user_id', 'amount', 'description']}
									filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
										return (
											<Filter>
												<FormLabel label="Urutkan">
													<Dropdown
														onChange={e =>
															changeParams({
																sortField: e.target.value,
															})
														}
														item={sortFields}
														value={params.sortField}
														placeholder="--Pilih urutan--"
													/>
												</FormLabel>

												<FormLabel label="Cari">
													<SearchBox
														style={{
															width: 400,
														}}
														handleSearch={src =>
															changeParams({
																src,
															})
														}
														srcText={params.src}
													/>
												</FormLabel>

												<FormLabel label="&nbsp;">
													<FilterActionButtons
														buttons={ActionButtons({
															clearFilter,
															params,
														})}
													/>
												</FormLabel>
											</Filter>
										);
									}}
								/>
							</div>
						</div>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development

// import React from 'react'; //--DAFReact: development
// // 'use strict'; //--DAFReact: deploy

// export function ModuleDefinition() {
// 	//--DAFReact: development

// 	// (function () { //--DAFReact: deploy

// 	async function getImports(React, globals) {
// 		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
// 		const {
// 			_moduleId,
// 			StdAppAction,
// 			AppFrameAction,
// 			appAction,
// 			jsdset,
// 			dswidget,
// 			NDataBrowser,
// 			ndswidget,
// 			frameAction,
// 			staticComponents: { PageTitle, Button, FormLabel, Dropdown, AlertComponent, SearchBox, Filter,
// 				ReactIconFa: { FaRetweet },
// 				ReactIconSi: { SiMicrosoftexcel }, },
// 		} = globals;
// 		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
// 			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
// 		}

// 		/* other required modules are asynchronously loaded here */
// 		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
// 			'manajemen_akun.histori_transaksi.historiTransaksiTagsMetadata',
// 		);

// 		const { PanelDataDisplay, PanelButton } = dswidget;
// 		const { FieldDataDisplay, FieldDataInput } = ndswidget;

// 		function componentFactory(params) {
// 			function AppForm(props) {
// 				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
// 				const DSetProvider = React.useMemo(() => {
// 					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
// 				}, []);
// 				return (
// 					<DSetProvider>
// 						<AppFormUI dataContext={dataContext} {...props} />
// 						{/* any other props will be passed down */}
// 					</DSetProvider>
// 				);
// 			}

// 			function AppFormUI(props) {
// 				const [state, setState] = React.useState({
// 					isErr: false,
// 					errMessage: '',
// 					mode: 'view',
// 				});

// 				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

// 				// bind controls to _moduleId and _authToken
// 				const vComps = React.useMemo(
// 					() =>
// 						appAction.connect(
// 							{
// 								PanelDataDisplay,
// 								FieldDataDisplay,
// 								PanelButton,
// 								FieldDataInput,
// 							},
// 							{ _moduleId, _getToken: () => props._authToken },
// 						),
// 					[],
// 				);

// 				// bind controls to datasets
// 				const [mainComps] = React.useMemo(
// 					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
// 					[props.dataContext, vComps],
// 				);

// 				// obtain action objects from data context
// 				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

// 				// load data function
// 				const loadData = React.useCallback(async () => {
// 					var prevState = state;
// 					setState({
// 						...prevState,
// 						isErr: false,
// 						errMessage: '',
// 					});
// 					try {
// 						var response = await appAction.fetchResource(_moduleId, 'single_data', 'dataSO', props._authToken, {
// 							id_transaction: params.id_transaction,
// 						});
// 						dsMainAction.loadStore(response, 'std', serverDataMapping, true);

// 						console.log(response);
// 					} catch (err) {
// 						setState({
// 							...prevState,
// 							isErr: true,
// 							errMessage: err.message,
// 						});
// 						return;
// 					}
// 				}, [dsMainAction, props._authToken, props.id_tx_draft]);

// 				// set event on component mounting
// 				React.useEffect(() => {
// 					(async function () {
// 						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
// 						setState({ popupComponent });
// 						dsMainAction.addRow({});
// 						loadData();
// 					})();
// 				}, [dsMainAction, loadData, props.uiMode]);

// 				const ActionButtons = React.useCallback(
// 					({ clearFilter, params }) => {
// 						return [
// 							{
// 								type: 'bordered',
// 								name: 'Reload',
// 								onClick: () => {
// 									clearFilter();
// 								},
// 								render: () => <FaRetweet />,
// 							},
// 						];
// 					},
// 					[],
// 				);

// 				const handleMode = mode => {
// 					if (mode === 'add') {
// 						setState(prevState => ({ ...prevState, mode: 'add' }));
// 					} else if (mode === 'view') {
// 						setState(prevState => ({ ...prevState, mode: 'view' }));
// 					}
// 				};

// 				const saveDataClick = async () => {
// 					var cstate = await appAction.getState();
// 					try {
// 						const { fieldValidStates, fieldValidErrors } = dsMainProxy;

// 						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
// 							return !v;
// 						});

// 						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

// 						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
// 							includeLoadedRows: false,
// 							includeDeletedRows: true,
// 						});

// 						console.log(dataUnload.data);
// 						// if (dataUnload?.data?.length > 0) {
// 						// 		if (dataUnload.data[0].old_password !== cstate.globals.password) {
// 						// 				throw new Error('Kata Sandi Sebelumnya yang Anda masukkan salah ');
// 						// 		}
// 						// }

// 						await appAction.postData(_moduleId, 'saveData', props._authToken, dataUnload);

// 						// appAction.frameAction.closeModal();
// 						AlertHandler('Data berhasil disimpan', 'success');
// 					} catch (err) {
// 						AlertHandler(err.message, 'alert');
// 					}
// 				};

// 				const AlertHandler = async (msg, type) => {
// 					await frameAction.showModal({
// 						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
// 						size: 'small',
// 					});
// 				};

// 				return (
// 					<div>
// 						<div style={{ display: 'flex' }}>
// 							<FormLabel label="No. Transaksi" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.id_transaction && dsMainProxy.fields.id_transaction !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="id_transaction" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 							<FormLabel label="Tanggal Input" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.tx_date && dsMainProxy.fields.tx_date !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="tx_date" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 						</div>

// 						<div style={{ display: 'flex' }}>
// 							<FormLabel label="Dari Rekening" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.account_no && dsMainProxy.fields.account_no !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="account_no" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 							<FormLabel label="Nama Rekening" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.account_name && dsMainProxy.fields.account_name !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="account_name" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 						</div>

// 						<div style={{ display: 'flex' }}>
// 							<FormLabel label="Ke Rekening" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.credit_account_no && dsMainProxy.fields.credit_account_no !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="credit_account_no" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 							<FormLabel label="Nama Rekening Tujuan" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.credit_account_name && dsMainProxy.fields.credit_account_name !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="credit_account_name" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 						</div>

// 						<div style={{ display: 'flex' }}>
// 							<FormLabel label="Jumlah" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.amount && dsMainProxy.fields.amount !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="amount" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 							<FormLabel label="No. Referensi" style={{ width: '100%' }}>
// 								<b>
// 									{dsMainProxy.fields.ref_number && dsMainProxy.fields.ref_number !== '' ? (
// 										<mainComps.FieldDataDisplay fieldName="ref_number" />
// 									) : (
// 										'-'
// 									)}
// 								</b>
// 							</FormLabel>
// 						</div>
// 						{state.mode === 'add' ? (
// 							<>
// 								<div style={{ display: 'flex' }}>
// 									<FormLabel label="Kode Tag" style={{ width: '100%' }}>
// 										<mainComps.FieldDataInput fieldName="tag_code" />
// 									</FormLabel>
// 								</div>
// 								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
// 									<Button style={{ marginRight: '1rem' }} onClick={() => saveDataClick()}>
// 										Simpan
// 									</Button>
// 									<Button type="bordered" onClick={() => handleMode('view')}>
// 										Batal
// 									</Button>
// 								</div>
// 							</>
// 						) : (
// 							<>
// 								<div style={{ display: 'flex' }}>
// 									<FormLabel label="Kode Tag" style={{ width: '100%' }}>
// 										<b>
// 											{dsMainProxy.fields.tag_code && dsMainProxy.fields.tag_code !== '' ? (
// 												<mainComps.FieldDataDisplay fieldName="tag_code" />
// 											) : (
// 												'-'
// 											)}
// 										</b>
// 									</FormLabel>
// 								</div>
// 								<div style={{ display: 'flex' }}>
// 									<FormLabel label="Keterangan" style={{ width: '100%' }}>
// 										<b>
// 											{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? (
// 												<mainComps.FieldDataDisplay fieldName="description" />
// 											) : (
// 												'-'
// 											)}
// 										</b>
// 									</FormLabel>
// 								</div>
// 								<div style={{ display: 'flex' }}>
// 									<NDataBrowser
// 										stdAppAction={appAction}
// 										moduleId={_moduleId}
// 										// resourceType="method"
// 										dataId="listSO"
// 										autoLoad={false}
// 										dataProvider={dsetProvider}
// 										authToken={props._authToken}
// 										dsetPath="main"
// 										uiData={editUIData}
// 										params={params}
// 										// rowButtons={null}
// 										filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
// 											return (
// 												<>
// 													<Filter>
// 														{/* Sort & Search */}
// 														<FormLabel label="Urutkan & Cari">
// 															<div style={{ display: 'flex' }}>
// 																<Dropdown
// 																	style={{
// 																		width: '250px',
// 																	}}
// 																	onChange={e =>
// 																		changeParams({
// 																			sortField: e.target.value,
// 																		})
// 																	}
// 																	item={sortFields}
// 																	value={params.sortField}
// 																/>
// 																<SearchBox
// 																	label="Tampilkan"
// 																	style={{
// 																		marginLeft: 5,
// 																		width: '250px',
// 																	}}
// 																	handleSearch={src => {
// 																		changeParams({
// 																			src: src,
// 																		});
// 																	}}
// 																	srcText={params.src}
// 																/>
// 															</div>
// 														</FormLabel>
// 													</Filter>
// 												</>
// 											);
// 										}}
// 										popupComponent={state.popupComponent}
// 									/>
// 								</div>
// 								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
// 									<Button onClick={() => handleMode('add')}>Tambah Tags</Button>
// 								</div>
// 							</>
// 						)}

// 						<div style={{ display: state.isErr ? 'block' : 'none' }}>{state.errMessage}</div>
// 					</div>
// 				);
// 			}

// 			// return value may be different depending on params
// 			return React.memo(AppForm);
// 		}

// 		return { componentFactory };
// 	}

// 	async function initModuleF(aReact, globals) {
// 		return await getImports(aReact, globals);
// 	}

// 	return initModuleF;
// 	// })()  //--DAFReact: deploy
// } //--DAFReact: development
