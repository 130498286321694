import React from 'react';

import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';

import Button from '../Button/Button';
import { FaEllipsisV } from 'react-icons/fa';

/**
 * TODO: clean this file
 * - put popupcomponent else where, this connector must be clean from spesifik module function
 */

const TableConnector = props => {
	const {
		loadDataClick,
		changeParams,
		clearFilter,
		params,
		onRowClick,
		rowButtons = true,
		state: { hasNextData, hasPrevData, inServerRequest, sortFields },
	} = props;

	// State for pop up
	const [state, setState] = React.useState({
		popupShown: false,
		popupX: 0,
		popupY: 0,
		popupRow: undefined,
	});

	const setPopup = React.useCallback(
		(x, y, row) => {
			const { x: containerX, y: containerY } = document
				.querySelector('.main-wrapper')
				.getBoundingClientRect();

			const {
				x: parentX,
				y: parentY,
				width,
				height,
			} = parentRef.current.getBoundingClientRect();

			const popupWidth = parseInt(
				props.popupWidth?.replace('px', '') || 200,
			);

			const actualParentY = parentY - containerY;

			var selisih = parentX + width - (x + popupWidth);

			setState({
				popupShown: true,
				popupX: x - parentX + (selisih < 0 ? selisih : 0),
				popupY: y - actualParentY,
				popupRow: row,
			});
		},
		[setState],
	);

	const hidePopup = React.useCallback(
		row => {
			if (row !== state.popupRow || row === null)
				setState({ popupShown: false });
		},
		[setState],
	);

	// Filtering Data
	const selectedFields = props.selFields;
	const deletedFields = props.delFields;
	const shownFields = props.shownFields;

	var fieldDefs = Object.keys(props.fieldDefs).map(k => props.fieldDefs[k]);

	fieldDefs = fieldDefs.filter(
		f => f.type !== 'dataset' && f.type !== 'link',
	);

	if (selectedFields && Array.isArray(selectedFields)) {
		fieldDefs = fieldDefs.filter(f => selectedFields.indexOf(f.name) >= 0);
	}

	if (deletedFields && Array.isArray(deletedFields)) {
		fieldDefs = fieldDefs.filter(f => deletedFields.indexOf(f.name) < 0);
	}

	if (shownFields && Array.isArray(shownFields) && shownFields.length > 0) {
		fieldDefs = fieldDefs.filter(f => shownFields.indexOf(f.name) > -1);
	}

	fieldDefs = fieldDefs.filter(
		f =>
			!f.isSystem ||
			(selectedFields && selectedFields.indexOf(f.name) >= 0),
	);

	var rows = props.fieldsArray;

	// this is function to call pop up
	const handleActionButton = ({ e, row }) => {
		// get all posible position from arguments,
		const { screenX, screenY, pageX, pageY, clientX, clientY } = e;

		e.preventDefault();
		setPopup(pageX, pageY, row);
		return true;
	};

	var searchSorts = [];
	var dataModel = fieldDefs.map(i => {
		sortFields.map(s => {
			if (s === i.name) searchSorts.push({ value: s, name: i.title });
		});

		return {
			name: i.name,
			label: i.title,
			uiData: props.uiData?.[i.name],
			type: i.type,
			fd: i,
		};
	});

	const parentRef = React.useRef();
	return (
		<div style={{ position: 'relative' }} ref={parentRef}>
			{props.filterComponent && (
				<props.filterComponent
					sortFields={searchSorts}
					loadDataClick={loadDataClick}
					changeParams={changeParams}
					clearFilter={clearFilter}
					params={params}
				/>
			)}

			{Object.keys(dataModel).length > 0 && (
				<div>
					<div style={{ overflow: 'auto' }}>
						<Table
							dataModel={dataModel}
							datas={rows}
							onRowClick={onRowClick}
							rowButtons={row => {
								if (!rowButtons) return false;
								return (
									<Button
										type="borderless"
										onClick={e =>
											handleActionButton({ e, row })
										}
									>
										<FaEllipsisV />
									</Button>
								);
							}}
						/>
					</div>
					<Pagination
						onChangeLimit={e =>
							changeParams({ maxRow: parseInt(e) })
						}
						handleNext={() => loadDataClick('next')}
						handlePrevious={() => loadDataClick('prev')}
						hasNext={hasNextData}
						hasPrev={hasPrevData}
						limit={params.maxRow}
						inServerRequest={inServerRequest}
						hideNavButtons={params.hideNavButtons ? params.hideNavButtons : false}
					/>
				</div>
			)}

			{state.popupShown === true && props.popupComponent && (
				<div
					style={{
						position: 'absolute',
						left: state.popupX + 'px',
						top: state.popupY + 'px',
						width: props.popupWidth || '200px',
						backgroundColor: 'Window',
					}}
					onMouseLeave={() => {
						hidePopup(null);
					}}
				>
					<props.popupComponent
						currentRow={state.popupRow}
						loadFunction={loadDataClick}
					/>
				</div>
			)}
		</div>
	);
};

export default TableConnector;
