import React, { useContext } from 'react';

function createReducer(actionHandlers) {
	return (state, action) => {
		var ae = actionHandlers[action.type];
		if (!ae) throw new Error(`Unknown action type ${action.type}`);
		var newState =
			typeof ae == 'function'
				? ae(state, action)
				: ae.handler(state, action);
		return newState || state;
	};
}

function createMethods(actionHandlers) {
	var actMethods = {};
	var handler;

	// console.log('createActionMethods() called')
	for (let mn in actionHandlers) {
		var action = actionHandlers[mn];
		if (typeof action == 'function') {
			actMethods[mn] = (...args) => {
				return { type: mn, ...(args[0] || {}) };
			};
		} else {
			handler = action.handler;
			if (!handler)
				throw new Error(
					`No handler is specified in action handler for action type ${mn}`,
				);
			let argMapper = action.argMap ? action.argMap : args => args[0];
			actMethods[mn] = (...args) => {
				return { type: mn, ...(argMapper(args) || {}) };
			};
		}
	}
	return actMethods;
}

/*
	ContextProviderHook: HoC that return standard provider component with initial state, action handlers (to build dispatch), and action class
	the resulted component support initActions and refDispatch props
	Parameters:
		- context: context variable
		- actionHandlers: list of action handlers that will be used to create reducer
		- initState: initial data / state
		- actionClass (optional): encapsulator action class for this state, must receive (dispatch) as constructor parameters
		- proxyClass (optional): encapsulator proxy class for this state, must receive (state, dispatch) as constructor parameters
*/

function ContextProviderHook(
	context,
	actionHandlers,
	initState,
	actionClass,
	proxyClass,
) {
	function ProviderComponent(props) {
		const [state, dispatch] = React.useReducer(
			createReducer(actionHandlers),
			initState,
		);
		const actionObject = React.useMemo(() => {
			// console.log('actionObject created');
			return actionClass ? new actionClass(dispatch) : null;
		}, [dispatch]);

		return (
			<div>
				<context.Provider
					value={{
						state,
						dispatch,
						methods,
						actionObject,
						proxyClass,
					}}
				>
					{props.children}
				</context.Provider>
			</div>
		);
	}
	var methods = createMethods(actionHandlers);
	return ProviderComponent;
}

/*
	useDispatchContext: Hook function that returns 
		- current state, 
		- action object (should be constant), 
		- dispatch (should be constant),
		- proxyObject (changes depend on state)
*/

function useDispatchContext(context, proxyParams = undefined) {
	const { state, proxyClass, actionObject, dispatch } = useContext(context);
	return [
		state,
		actionObject,
		dispatch,
		proxyClass ? new proxyClass(state, dispatch, proxyParams) : null,
	];
}

function ContextConnector(
	context,
	stateFilter,
	dispatchPropsCreator,
	mixPropsCreator,
	dispatchPropName,
	methodsPropName,
) {
	function injectComponent(Component) {
		function ConnectedComponent(props) {
			const { state, dispatch, methods } = React.useContext(context);

			const stateProps = stateFilter ? stateFilter(state, props) : state;
			dispatchPropsCreator = dispatchPropsCreator || (() => ({}));
			const dispatchProps = React.useMemo(
				() => dispatchPropsCreator(dispatch, methods),
				[dispatch, methods],
			);
			const mixProps = mixPropsCreator
				? mixPropsCreator(stateProps, dispatchProps)
				: {}; // mixProps are not cached, since they may depend on state value

			return (
				<Component
					{...stateProps}
					{...dispatchProps}
					{...mixProps}
					{...{
						[dispatchPropName || 'disp']: dispatch,
						[methodsPropName || 'meth']: methods,
					}}
					{...props}
				>
					{props.children}
				</Component>
			);
		}
		return ConnectedComponent;
	}

	return injectComponent;
}

function createActionComponent(context, actionInstance, statePropMaps) {
	// TODO: change all calls to renderActionObject
	function ActionComponent(props) {
		const { state, methods, dispatch } = useContext(context);

		// todo: using side effect like this is not recommended !
		actionInstance.dispatch = dispatch;
		actionInstance.methods = methods;
		actionInstance.disp = dispatch;
		actionInstance.meth = methods;
		actionInstance.currentState = { ...state };
		statePropMaps = statePropMaps || {};
		if (typeof statePropMaps == 'object') {
			var targetNames = Object.keys(statePropMaps);
			for (var targetName in targetNames) {
				actionInstance[targetName] = state[targetNames[targetName]];
			}
		} else if (typeof statePropMaps == 'function') {
			statePropMaps(state, actionInstance);
		}
		return <></>;
	}

	return ActionComponent;
}

// simple function to yield event loop so the actions after await will be executed in the next event loop
// usage example:
// await yieldEventLoop()
// console.log('do something')

async function yieldEventLoop() {
	return new Promise(resolve => {
		setTimeout(() => resolve(), 0);
	});
}

export {
	ContextProviderHook,
	ContextConnector,
	createActionComponent,
	yieldEventLoop,
	useDispatchContext,
	createMethods,
	createReducer,
};

/* 
actionHandlers example (with argMap)

const initialState = {f1: 'abc', f2: 'def', f3: 'ghi', f4: 'jkl', counter: 0}
const actionHandlers = { 
	setField: {
		handler: (state, params) => {
			var fName = params.fieldName
			if (!fName)
				throw new Error('Invalid field name')
			return {...state, [fName]: params.value}
		},
		argMap: ([fieldName, value]) => ({fieldName, value})
	},
	increment: {
		handler: (state, params) => {
			return {...state, counter: state.counter + (params.step || 0)}
		},
		argMap: ([step]) => ({step})
	}
}
*/
