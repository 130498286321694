import React from 'react';

import '../../app_styles/commons/_filter-box.scss';

const Filter = ({ style, children }) => {
	return (
		<div className="filter-box" style={style}>
			{children}
		</div>
	);
};

export default Filter;
