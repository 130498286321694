import moment from 'moment'

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			frameAction,
			staticComponents: {
				DatePicker,
				Button,
				Dropdown,
				FormLabel,
				PageTitle,
				Input,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa: { FaRetweet, FaRegFilePdf, FaMoney },
				ReactIconSi: { SiMicrosoftexcel },
				dataselector,
				asString,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { editUIData } = await appAction.fetchAndExecModule('manajemen_akun.mutasi_rekening.mutasiRekeningMetadata');
		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					saldo_awal: '0.00',
					saldo_akhir: '0.00',
					saldo_rekening: '0.00',
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					account_no: '',
					account_name: '',
				};

				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				React.useEffect(() => {
					(async function () {
						// pass
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Print Excel',
								onClick: async () => {
									try {
										await appAction.postData(_moduleId, 'importListExcel', props._authToken, { ...params, ...filter });
									} catch (err) {
										AlertHandler(err.message, 'alert');
									}
								},
								render: () => <SiMicrosoftexcel />,
							},
							{
								type: 'bordered',
								name: 'Print pdf',
								onClick: async () => {
									try {
										await appAction.postData(_moduleId, 'importList', props._authToken, { ...params, ...filter });
									} catch (err) {
										AlertHandler(err.message, 'alert');
									}
								},
								render: () => <FaRegFilePdf />,
							},
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									handleFilter();
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[filter],
				);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				const handleLookup = React.useCallback(
					async _ => {
						if (!dataselector) return false;

						var getRekening = await dataselector(appAction)({
							apiInfo: {
								moduleId: _moduleId,
								dataId: 'listAccount',
								authToken: props._authToken,
							},
						});

						if (getRekening) {
							handleFilter({
								account_no: getRekening.account_no,
								account_name: getRekening.account_name,
							});
						}
					},
					[filter],
				);

				const custFunc = (v, index) => {
					return moment(v).subtract(7, 'h').format('llll');
				};

				return (
					<>
						<PageTitle title="Mutasi Rekening" subtitle="Manajemen Akun" style={{ marginBottom: `1.5rem` }} />
						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							resourceType="method"
							dataId="getMutasiList"
							autoLoad={false}
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={{
								main: {
									fields: {
										amount: { thousand: true, decimal: 2 },
										saldo: { thousand: true, decimal: 2 },
										tx_date: { custFunc },
									},
								},
							}}
							params={filter}
							rowButtons={null}
							filterComponent={({ clearFilter, changeParams, params }) => {
								return (
									<>
										<div
											style={{
												border: `1px solid rgba(0,0,0,0.1)`,
												backgroundColor: `#eeeeee`,
												padding: `1rem`,
												borderRadius: `5px`,
												margin: `1rem 0`,
											}}
										>
											<h5>Saldo</h5>
											<div
												style={{
													padding: `0 1rem`,
												}}
											>
												<p>Saldo Awal Mutasi Rp {asString(state.saldo_awal, 'float', { numformatted: true })}</p>
												<p>Saldo Akhir Mutasi Rp {asString(state.saldo_akhir, 'float', { numformatted: true })}</p>
												<p
													style={{
														fontWeight: `500`,
														textAlign: `right`,
													}}
												>
													Saldo Akhir Rekening Rp {asString(state.saldo_rekening, 'float', { numformatted: true })}
												</p>
											</div>
										</div>
										<Filter>
											<FormLabel
												label="Pilih Rekening"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div style={{ display: 'flex' }}>
													<Input
														readOnly={true}
														placeholder={'--Pilih Rekening--'}
														value={
															filter.account_no || params.account_no
																? (filter.account_no || params.account_no) + ' - ' + (filter.account_name || params.account_name)
																: null
														}
														onClick={() => handleLookup(params)}
													/>
												</div>
											</FormLabel>
											<FormLabel
												label="Tanggal Transaksi"
												style={{
													maxWidth: '250px',
													marginRight: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
													}}
												>
													<DatePicker
														value={filter.start_date}
														onChange={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div style={{ padding: 10 }}>s.d.</div>
													<DatePicker
														value={filter.end_date}
														onChange={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>
											<FormLabel label="&nbsp;">
												<Button
													onClick={() => {
														try {
															if (!filter.account_no) return alert('Silakan isi nomor rekening.');
															changeParams({
																start_date: filter.start_date,
																end_date: filter.end_date,
																account_no: filter.account_no,
															});

														} catch (err) {
															console.error('mutasi rekening error',err);
														}
													}}
												>
													Tampilkan
												</Button>
											</FormLabel>

											<FormLabel label="&nbsp;">
												<FilterActionButtons
													buttons={ActionButtons({
														clearFilter,
														params,
													})}
												/>
											</FormLabel>
										</Filter>
									</>
								);
							}}
							postFunction={rsp => {
								try {
									var { saldo_awal, saldo_akhir, saldo_rekening } = rsp;
									setState({ ...state, ...{ saldo_awal, saldo_akhir, saldo_rekening } });
								} catch (error) {
									console.error('mutasi rekening error',error);
								}
							}}
							// popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
