import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				trf: {
					fields: [
						{
							name: 'entry_datetime',
							type: 'date',
							title: 'Tanggal Input',
						},
						{
							name: 'amount',
							type: 'int',
							title: 'Tanggal Input',
						},
						{
							name: 'ref_number',
							type: 'string',
							title: 'ref_number',
							length: 20,
						},
						{
							name: 'id_tx_draft',
							type: 'string',
							title: 'id_tx_draft',
							length: 20,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'tx_code',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Jenis Transaksi');
								return check;
							},
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Rekening Sumber',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Rekening Sumber');
								return check;
							},
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening Sumber',
							length: 50,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'product_code',
							length: 20,
							// validator: (value, fieldName) => {
							//     let check = isExist(value, 'product_code');
							//     return check;
							// },
						},
						{
							name: 'description',
							type: 'string',
							title: 'Berita',
							length: 160,
						},
						{
							name: 'source_file_url',
							type: 'string',
							title: 'source_file_url',
							length: 1000,
						},
						{
							name: 'file_format',
							type: 'string',
							title: 'file_format',
							length: 20,
						},
						{
							name: 'number_record',
							type: 'string',
							title: 'number_record',
							length: 20,
						},
						{
							name: 'conv_status',
							type: 'string',
							title: 'conv_status',
							length: 10,
						},
						{
							name: 'conv_status_detail',
							type: 'string',
							title: 'conv_status_detail',
							length: 10,
						},
						{
							name: 'amount_fee',
							type: 'float',
							title: 'amount_fee',
							length: 20,
						},
						{
							name: 'amount_ex',
							type: 'float',
							title: 'amount_ex',
							length: 20,
						},
						{
							name: 'jumlah_yang_dikirim',
							type: 'float',
							title: 'jumlah_yang_dikirim',
							length: 20,
						},
						{
							name: 'is_closed',
							type: 'string',
							title: 'is_closed',
							length: 20,
						},
						{
							name: 'apprel_status',
							type: 'string',
						},
						{
							name: 'apprel',
							type: 'string',
						},
						{
							name: 'workflow_state',
							type: 'string',
						},
						{
							name: 'tx_ref_number',
							type: 'string',
						},
						{
							name: 'userlog',
							type: 'string',
						},
						{
							name: 'status_inquiry',
							type: 'string',
						},
					],

					indexes: ['id_tx_draft'],
				},
			};

			const initialData = {
				'main:trf': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'trf',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				trf: {
					fields: {
						amount: {
							decimal: 2,
							thousand: true,
						},
						jumlah_yang_dikirim: {
							decimal: 2,
							thousand: true,
						},
						amount_fee: {
							decimal: 2,
							thousand: true,
						},
						amount_ex: {
							decimal: 2,
							thousand: true,
						},
						account_no: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekening',
								selFields: ['account_no', 'account_name', 'currency'],
								fieldMap: {
									account_no: 'account_no',
									account_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: (fields, isValidation = false) => {
									return isValidation
										? {
												searchMode: 1,
												searchKey: fields.account_no,
												resourceType: 'method',
										  }
										: {
												account_no: fields.account_no,
												resourceType: 'method',
										  };
								},
							},
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
