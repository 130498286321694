import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				trf: {
					fields: [
						{
							name: 'kode_bank_tujuan',
							type: 'string',
							validator: (value, fieldName) => {
								let check = isExist(value, 'Kode Bank');
								return check;
							},
						},
						{
							name: 'kode_bank_tujuan_name',
							type: 'string',
							title: 'Kode Bank',
							length: 20,
						},
						{
							name: 'id_transaction',
							type: 'string',
							title: 'id_transaction',
							length: 20,
						},
						{
							name: 'txdraft_ref_number',
							type: 'string',
							title: 'txdraft_ref_number',
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Rekening Sumber',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Rekening Sumber');
								return check;
							},
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening Sumber',
							length: 50,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'Kode Transaksi',
							length: 20,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'Kode Produk',
							length: 20,
						},
						{
							name: 'credit_account_no',
							type: 'string',
							title: 'Rekening Tujuan',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Rekening Tujuan');
								return check;
							},
						},
						{
							name: 'credit_account_name',
							type: 'string',
							title: 'Nama Rekening Tujuan',
							length: 140,
						},
						{
							name: 'amount',
							type: 'float',
							title: 'Jumlah',
							length: 20,
							validator: (value, fieldName) => {
								let check = isExist(value, 'Jumlah');
								return check;
							},
						},
						{
							name: 'amount_fee',
							type: 'float',
							title: 'Biaya',
							length: 20,
						},
						{
							name: 'amount_ex',
							type: 'float',
							title: 'Biaya Lain-lain',
							length: 20,
						},
						{
							name: 'amount_net',
							type: 'float',
							title: 'Biaya',
							length: 20,
						},
						{
							name: 'description',
							type: 'string',
							title: 'Berita',
							length: 160,
							validator: (value, fieldName) => {
								const regex = /\bsystem\b/i;
								const isSystemPresent = regex.test(value.toLowerCase());
								if (isSystemPresent) {
									return [false, 'Berita tidak boleh mengandung kata "system"'];
								}
								let check = isExist(value, 'Berita');
								return check;
							},
							regex: /[^a-zA-Z0-9 .()_,]/g, // only alphanumeric and space
						},
						{
							name: 'tx_type',
							type: 'string',
							title: 'Jenis Transaksi',
							length: 15,
						},
						{
							name: 'date',
							type: 'date',
							title: 'Tanggal',
							length: 15,
						},
						{
							name: 'freq',
							type: 'string',
							title: 'Setiap',
							length: 15,
						},
						{
							name: 'recurring_type',
							type: 'string',
							title: 'Tipe',
							length: 15,
						},
						{
							name: 'at_date',
							type: 'date',
							title: 'Pada Tanggal',
							length: 15,
						},
						{
							name: 'start_date',
							type: 'date',
							title: 'Mulai Tanggal',
							length: 15,
						},
						{
							name: 'end_date',
							type: 'date',
							title: 'Sampai Tanggal',
							length: 15,
						},
						{
							name: 'desc',
							type: 'string',
							title: 'Keterangan',
							length: 30,
						},
						{
							name: 'favorite',
							type: 'string',
							title: 'favorite',
							length: 10,
						},
						{
							name: 'favorite_name',
							type: 'string',
							title: 'favorite_name',
							length: 50,
						},
						{
							name: 'is_rtgs',
							type: 'string',
							title: 'is_rtgs',
							length: 20,
						},
						{
							name: 'external_ref_number',
							type: 'string',
							title: 'external_ref_number',
							length: 20,
						},
						{
							name: 'cost_by',
							type: 'string',
							title: 'cost_by',
							length: 20,
						},
						{
							name: 'purpose_code',
							type: 'string',
						},
						{
							name: 'tags',
							type: 'string',
							title: 'Tags',
							length: 20,
						},
						{
							name: 'tag_class',
							type: 'string',
							title: 'Kelas Kode',
							length: 50,
						},
						{
							name: 'tag_desc',
							type: 'string',
							title: 'Deskipsi',
							length: 50,
						},
					],

					indexes: ['id_transaction'],
				},
			};

			const initialData = {
				'main:trf': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'trf',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				trf: {
					fields: {
						amount: { thousand: true, decimal: 2 },
						amount_fee: {
							thousand: true,
							decimal: 2,
							readOnly: true,
							disabled: true,
						},
						amount_ex: {
							thousand: true,
							decimal: 2,
							readOnly: true,
							disabled: true,
						},
						kode_bank_tujuan: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getKodeBank',
								selFields: ['bank_code', 'bank_name'],
								fieldMap: {
									kode_bank_tujuan: 'bank_code',
									kode_bank_tujuan_name: 'bank_name',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {
										is_rtgs: fields.is_rtgs,
									};
								},
							},
						},
						account_no: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekeningSumber',
								selFields: ['account_no', 'account_name', 'currency'],
								fieldMap: {
									account_no: 'account_no',
									account_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {};
								},
							},
						},
						credit_account_no: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
									buttonText: 'Favorite',
								},
								dataId: 'getRekeningTujuan',
								selFields: ['credit_account_no'],
								fieldMap: {
									credit_account_no: 'credit_account_no',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {
										is_rtgs: fields.is_rtgs,
										is_empty: fields.is_empty,
										tx_code: fields.tx_code,
										kode_bank_tujuan: fields.kode_bank_tujuan,
									};
								},
							},
						},
						purpose_code: {
							inputType: 'radio',
							type: 'icon',
							variant: 'bordered',
							dataSets: {
								1001: 'Investasi',
								1002: 'Transfer Kekayaan',
								1003: 'Pembelian',
								1099: 'Lainnya',
							},
							initialValue: '1002',
						},
						tags: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getCorporateTags',
								selFields: ['tags', 'tag_class', 'tag_desc'],
								fieldMap: {
									tags: 'tag_code',
									tag_class: 'tag_class',
									tag_desc: 'description',
								},
								apiParameterF: (fields, isValidation = false) => {
									return {};
								},
							},
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
