/*
  DAFReact framework module - General library
  filename: ...
  moduleid: ...
  author: ...
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition () { //--DAFReact: development

// (function () { //--DAFReact: deploy

  function getImports (React, globals) {

    // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
    const { StdAppAction, AppFrameAction, appAction, jsdset, dswidget } = globals
    if (!StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget) {
      throw new Error('One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals')
    }

    const frameAction = appAction.frameAction

    function componentFactory (params) {

      class PopUpExample extends React.Component {

        constructor (props) {
          super(props)
          this.state = {inputValue: ''}
        }
    
        render () {
          return <div>
            This is a popup example !
            <br />
            Input control <input value={this.state.inputValue} onChange={(e) => this.setState({inputValue: e.target.value})}></input>
            <br /><br />
            <button onClick={() => alert('hello')}>Click me !</button>
          </div>
        }
      }

      const ModalContent = (props) => (
        <div>
          ID: 01433344<br />
          Name: Ahmad Andri<br />
          Address: Somewhere in Indonesia <br />
          Lorem Ipsum Dolomet <br />
          Lorem Ipsum Dolomet <br />
          Lorem Ipsum Dolomet <br />
          <br />
          <button onClick={
              () => frameAction.showPopUp(null, 'popup', 
                  (props) => <div>
                    Another simple popup example
                    Click button below to close popup
                    <br /><br />
                    <button onClick={() => frameAction.closePopUp(null, 'PopupOK')}>Close popup</button>
                  </div>,
                  {}, 'Smaller popup',
                  {left: '20px', top: '20px', width: '300px', height: '200px'}, undefined,
                  {onClose: (popupName, result) => console.log(`Popup within dialog ${popupName} closed. result=${result}`)}
                )
            }>Popup within
          </button>
          &nbsp;
          <button onClick={() => frameAction.closeModal(true)}>Ok</button>
        </div>
      )
      
      return { PopUpExample, ModalContent }
    }

    return { componentFactory }
  }

  function initModuleF(aReact, globals) {
    return getImports(aReact, globals)
  }

  return initModuleF
// })()  //--DAFReact: deploy

} //--DAFReact: development

