import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { AlertComponent, PageTitle, Button, FormLabel, TransaksiTerjadwal, RekeningFavorite },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
			'transfer.rekening_sama_bank.rekeningSamaBankMetadata',
		);

		const componentClass = await appAction.fetchAndExecModule('transfer.rekening_sama_bank.rekeningSamaBankKonfirmasi');

		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					terjadwalState: {},
				});

				const favref = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						// remember, this supposed to be new tx_code and product code
						dsMainAction.addRow({});
					})();
				}, [dsMainAction, props.uiMode]);

				const handleValidation = () => {
					const { fieldValidStates, fieldValidErrors } = dsMainProxy;
					const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
						return !v;
					});

					if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);
				};

				const handleConfirm = async () => {
					try {
						handleValidation();

						if (componentClass) {
							var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
								includeLoadedRows: false,
								includeDeletedRows: true,
							});

							if (dataUnload.data?.length > 0) {
								dataUnload.data[0]['tx_code'] = 'TRF';
								dataUnload.data[0]['product_code'] = '425';
								dataUnload.data[0]['terjadwal'] = state.terjadwalState;

								dataUnload.data[0] = { ...dataUnload.data[0], ...state.terjadwalState, ...state.terjadwalState?.value };
								if (dataUnload.data[0]['sched_exec_datetime']) {
									dataUnload.data[0]['sched_exec_datetime'] = moment(dataUnload.data[0]['sched_exec_datetime']).format('YMMDD');
								}

								const modalshow = await frameAction.showModalAsync({
									headerProps: {
										title: 'Konfirmasi Transfer',
										icon: 'FaInfoCircle',
									},
									contentClass: componentClass,
									contentProps: {
										_moduleId,
										_authToken: props._authToken,
										data: dataUnload,
									},
									size: 'large',
								});

								if (modalshow) {
									setState({
										...state,
										terjadwalState: {},
									});

									dsMainAction.deleteRow();
									dsMainAction.addRow({});

									favref.current.checked = false;
								}
							}
						}
					} catch (error) {
						AlertHandler(error.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const handleTerjadwal = (terjadwalState = {}) => {
					setState({ ...state, terjadwalState });
				};

				return (
					<div>
						<PageTitle subtitle="Transfer Dana" title="Issuer Lain Lain" style={{ marginBottom: `1.5rem` }}></PageTitle>

						<div>
							<FormLabel label="Rekening Sumber">
								<mainComps.FieldDataInput fieldName="account_no" />
							</FormLabel>
							<FormLabel label="Nomor Rekening Tujuan">
								<mainComps.FieldDataInput fieldName="credit_account_no" />
							</FormLabel>
							<FormLabel label="Jumlah">
								<mainComps.FieldDataInput fieldName="amount" />
							</FormLabel>
							<FormLabel label="Berita">
								<mainComps.FieldDataInput fieldName="description" />
							</FormLabel>
							<FormLabel label="No. Dokumen">
								<mainComps.FieldDataInput fieldName="external_ref_number" />
							</FormLabel>
							
							{/* <FormLabel label="Tags">
								<mainComps.FieldDataInput fieldName="tags" />
							</FormLabel> */}

							<TransaksiTerjadwal handleTerjadwal={handleTerjadwal} />

							<RekeningFavorite
								{...props}
								mainComps={mainComps}
								dsMainAction={dsMainAction}
								dsMainProxy={dsMainProxy}
								reference={favref}
							/>

							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							>
								<Button
									onClick={() => {
										handleConfirm();
									}}
								>
									Konfirmasi
								</Button>
							</div>

							<div
								style={{
									display: state.isErr ? 'block' : 'none',
								}}
							>
								{state.errMessage}
							</div>
						</div>
					</div>
				);
			}
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
