import React, { useEffect, useState } from 'react';
import './Breadcrumbs.scss';

function Breadcrumbs({ items, active = 0 }) {
	const [activeNav, setActiveNav] = useState(active);

	const handleClick = e => {
		setActiveNav(e);
	};

	useEffect(() => {
		setActiveNav(active);
	}, [active]);
	return (
		<ul className="breadcrumb">
			{items
				? items.map((data, index) => (
					<li key={data.title, index}
						className={
							index === activeNav
								? 'active'
								: index <= activeNav
									? 'visited'
									: ''
						}
					>
						<a href="#">
							<span>{data.title}</span>
							<span>{data.subtitle}</span>
						</a>
					</li>
				))
				: null}
		</ul>
	);
}

export default Breadcrumbs;
