import React from 'react';
import * as ds from './jsdsetconnect.js';
import { GridDataDisplay } from './dsetwidget.js';

const MAX_ROW = 10;

export class DataBrowser extends React.Component {
	/* 
	supported props:
	  *stdAppAction: standard application action provider
	  *moduleId: server-side module id
	  *dataId: server-side resource id (in context of moduleId)
	  *dataProvider: provider object obtained from ds.dsetMetaProvider 
	  *dsetPath: dataset path
	  *keyField: field name that acts as key (can be omited if useServerFields is true)
	  reqAuthToken: requires authToken to request to server
	  authToken: authentication token to send to server along request
	  apiParams: parameter to api call
	  visualComponent: visualization component (default is GridDataDisplay if omited)
	  onRowClick: function to invoke when row is click
	  selFields: selected fields (if using GridDataDisplay)
	  maxRow: maximum row (default MAX_ROW constant)
	  popupComponent: pop up menu component
	  visualProps: visualComponent props
	  pendingLoad: pending data load for first display (default = false )
	  hideNavButtons: hide navigation buttons
	  hideSortOptions: hide sort columns
	  hideSearch: hide search panel
	  hideColumnTitles: hide column titles
	  useServerFields: use fields definition from server instead one defined in dataProvider
	*: mandatory props
  */

	constructor(props) {
		super(props);
		if (
			!props.dataProvider ||
			!props.dsetPath ||
			!props.moduleId ||
			!props.dataId ||
			(!props.keyField && !props.useServerFields)
		)
			throw new Error(
				'dataProvider, dsetPath, moduleId, dataId and keyField props are required',
			);

		if (!props.dataProvider.dataContext)
			throw new Error(
				'Invalid props.dataProvider (not having valid dataContext property)',
			);

		this.stdAppAction = props.stdAppAction;
		this.dsAction = null;

		if (props.initialDataState) {
			const ids = props.initialDataState;
			this.state = {
				serverKeyField: undefined,
				inServerRequest: false,
				isErr: false,
				errMessage: '',
				hasData: true,
				hasNextData: ids.next_data,
				hasPrevData: ids.prev_data,
				sortFields: ids.sort_fields,
				sortField: ids.sort_field,
				noData: ids.rows.length == 0,
			};
		} else {
			this.state = {
				serverKeyField: undefined,
				inServerRequest: false,
				isErr: false,
				errMessage: '',
				hasData: false,
				hasNextData: false,
				hasPrevData: false,
				sortFields: [],
				sortField: '',
			};
		}
	}

	loadDataClick = async e => {
		var newState;
		var isError, errMessage;

		var prevState = this.state;
		this.setState({ ...prevState, isErr: false, inServerRequest: true });
		this.lastResponse = null;
		try {
			var response = await this.stdAppAction.fetchResource(
				this.props.moduleId,
				'scroll_query',
				this.props.dataId,
				this.props.authToken,
				Object.assign(
					{
						max_row: this.props.maxRow || MAX_ROW,
						get_client_fields: this.props.useServerFields
							? 'true'
							: 'false',
					},
					this.props.apiParams || {},
				),
			);
			isError = false;
			errMessage = '';
		} catch (err) {
			isError = true;
			errMessage = err.message;
		}

		if (!isError) {
			newState = {
				inServerRequest: false,
				isErr: false,
				hasData: true,
				hasNextData: response.next_data,
				hasPrevData: response.prev_data,
				sortFields: response.sort_fields,
				sortField: response.sort_field,
				noData: response.rows.length == 0,
			};
			if (this.props.useServerFields) {
				this.dsAction.resetAll(
					{
						table: {
							fields: response.client_fields,
							indexes: [response.key_field],
						},
					},
					{
						'main:table': [],
					},
					{},
				);
				// console.log('use server fields')
			}
			newState.serverKeyField = response.key_field;
			this.dsAction.load(response.rows);
			this.lastResponse = response;
		} else
			newState = {
				inServerRequest: false,
				isErr: true,
				hasData: false,
				errMessage,
			};
		this.setState(newState);
	};

	loadPaging = async (navDir, searchText) => {
		// navDir can be 'next' or 'prev' or 'search'. for 'search' specify searchText
		var newState;
		var isError, errMessage;

		if (this.dsAction.fieldDefs.length == 0) return;

		var prevState = this.state;
		var boundRow =
			navDir == 'next'
				? this.dsAction.fieldsArray.slice(-1)[0]
				: this.dsAction.fieldsArray[0];
		if (boundRow) {
			var sortValueBound = boundRow[prevState.sortField];
			var keyValueBound =
				boundRow[this.props.keyField || this.state.serverKeyField];
		}
		this.setState({ ...prevState, inServerRequest: true, isErr: false });
		try {
			var response = await this.stdAppAction.fetchResource(
				this.props.moduleId,
				'scroll_query',
				this.props.dataId,
				this.props.authToken,
				{
					max_row: this.props.maxRow || MAX_ROW,
					is_bound: boundRow ? 1 : 0,
					value_bound:
						navDir != 'search' ? sortValueBound : searchText,
					key_bound: keyValueBound,
					sort_field: prevState.sortField,
					is_search: navDir == 'search' ? 1 : 0,
					nav_dir: navDir,
					...(this.props.apiParams || {}),
				},
			);
			isError = false;
			errMessage = '';
		} catch (err) {
			isError = true;
			errMessage = err.message;
		}

		if (!isError) {
			try {
				newState = {
					...prevState,
					inServerRequest: false,
					isErr: false,
					hasNextData: response.next_data,
					hasPrevData: response.prev_data,
					noData: response.rows.length == 0,
				};

				if (navDir === 'search') newState.srcText = searchText;
				this.dsAction.resetStore();
				this.dsAction.load(response.rows);
			} catch (err) {
				newState = {
					...prevState,
					inServerRequest: false,
					isErr: true,
					errMessage: err.message,
				};
			}
		} else {
			newState = {
				...prevState,
				inServerRequest: false,
				isErr: true,
				errMessage,
			};
		}
		this.setState(newState);
	};

	loadPrevClick = async e => {
		await this.loadPaging('prev');
	};

	loadNextClick = async e => {
		await this.loadPaging('next');
	};

	sortChange = async e => {
		var newState;
		var isError, errMessage;

		var prevState = this.state;
		this.setState({ ...prevState, isErr: false, inServerRequest: true });

		try {
			var response = await this.stdAppAction.fetchResource(
				this.props.moduleId,
				'scroll_query',
				this.props.dataId,
				this.props.authToken,
				Object.assign(
					{
						max_row: this.props.maxRow || MAX_ROW,
						sort_field: e.target.value,
						auth_token: this.props.authToken || '',
					},
					this.props.apiParams || {},
				),
			);
			isError = false;
			errMessage = '';
		} catch (err) {
			isError = true;
			errMessage = err.message;
		}

		if (!isError) {
			newState = {
				inServerRequest: false,
				isErr: false,
				hasData: true,
				hasNextData: response.next_data,
				hasPrevData: response.prev_data,
				sortFields: response.sort_fields,
				sortField: response.sort_field,
			};
			this.dsAction.resetStore();
			this.dsAction.load(response.rows);
		} else {
			newState = {
				...prevState,
				inServerRequest: false,
				isErr: true,
				errMessage,
			};
		}
		this.setState(newState);
	};

	bSearchClick = async e => {
		var searchText = this.inpSearch.value;

		await this.loadPaging('search', searchText);
	};

	async componentDidMount() {
		if (
			!this.props.pendingLoad &&
			!this.props.initialDataState &&
			(!this.props.reqAuthToken || this.props.authToken)
		) {
			await this.loadDataClick();
		}
	}

	async componentDidUpdate(prevProps) {
		if (
			prevProps.authToken != this.props.authToken &&
			this.props.reqAuthToken
		)
			await this.loadDataClick();
	}

	render() {
		var state = this.state;
		var selFields = this.props.selFields;
		var dataContext = this.props.dataProvider.dataContext;
		if (Array.isArray(selFields) && selFields.length == 0)
			selFields = undefined;
		var visualComponent = this.props.visualComponent || GridDataDisplay;
		const DataComponent = ds.dsetMetaConnector(
			dataContext,
			this.props.dsetPath,
			true,
		)(visualComponent);
		var vProps = this.props.visualProps || {};

		if (visualComponent == GridDataDisplay)
			vProps = {
				popupComponent: this.props.popupComponent,
				...vProps,
				selFields,
				highlightActive: true,
				hideColumnTitles: this.props.hideColumnTitles,
				onRowClick:
					this.props.onRowClick &&
					typeof this.props.onRowClick === 'function'
						? this.props.onRowClick
						: undefined,
			};

		const customProps = {
			loadPaging: this.loadPaging,
			loadDataClick: this.loadDataClick,
			popupComponent: this.props.popupComponent,
			loadDataClick: this.loadDataClick,
			srcText: state.srcText || '',
			state,
		};

		return (
			<this.props.dataProvider>
				<ds.DSetAction
					ref={val => {
						this.dsAction = val;
					}}
					context={dataContext}
					dsetPath={this.props.dsetPath}
					multiRow={true}
				/>

				<div>
					<DataComponent {...vProps} {...customProps} />
				</div>

				<div>
					{!this.props.hideNavButtons ? (
						<>
							<button
								disabled={state.inServerRequest}
								onClick={this.loadDataClick}
							>
								Refresh
							</button>
							<button
								disabled={
									!state.hasData ||
									state.inServerRequest ||
									!state.hasPrevData
								}
								onClick={this.loadPrevClick}
							>
								Prev page
							</button>
							&nbsp;
							<button
								disabled={
									!state.hasData ||
									state.inServerRequest ||
									!state.hasNextData
								}
								onClick={this.loadNextClick}
							>
								Next page
							</button>
							&nbsp;
							<br />
						</>
					) : (
						<></>
					)}
					{!this.props.hideSortOptions ? (
						<select
							style={{
								display: this.props.hideSortOptions
									? 'none'
									: 'block',
							}}
							disabled={state.inServerRequest || !state.hasData}
							onChange={this.sortChange}
							value={state.sortField}
						>
							{state.sortFields.map(fieldName => (
								<option key={fieldName} value={fieldName}>
									{fieldName}
								</option>
							))}
						</select>
					) : (
						<></>
					)}
					{!this.props.hideSearch ? (
						<div>
							Search by{' '}
							<span style={{ color: 'blue' }}>
								{state.sortField}
							</span>
							<input
								ref={value => (this.inpSearch = value)}
							></input>
							<button onClick={this.bSearchClick}>Search</button>
						</div>
					) : (
						<></>
					)}
				</div>
				{state.isErr ? <div>Error: {state.errMessage}</div> : <></>}
			</this.props.dataProvider>
		);
	}
}
