import React from 'react'; //--DAFReact: development
import moment from 'moment';

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
    //--DAFReact: development

    // (function () { //--DAFReact: deploy

    async function getImports(React, globals) {
        // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
        const {
            _moduleId,
            StdAppAction,
            appAction,
            jsdset,
            dswidget,
            ndswidget,
            frameAction,
            staticComponents: {
                AlertComponent,
                Dropdown,
                PageTitle,
                Button,
                FormLabel,
                RadioGroup,
                ReactIconFa: { FaInfoCircle },
                Input,
                ViewTransaksiTerjadwal,
            },
        } = globals;
        if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
            throw new Error(
                'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
            );
        }

        /* other required modules are asynchronously loaded here */
        const { metadata, initialData, serverDataMapping, editUIData } =
            await appAction.fetchAndExecModule(
                'transaksi_massal.transaksi_massal.detailTransaksiMassalMetadata',
            );

        const { PanelDataDisplay, PanelButton } = dswidget;
        const { FieldDataInput, FieldDataDisplay } = ndswidget;

        function componentFactory(params) {
            function AppForm(props) {
                const dataContext = React.useMemo(
                    () => jsdset.dsetCreateContext(),
                    [],
                );
                const DSetProvider = React.useMemo(() => {
                    return jsdset.dsetMetaProvider(
                        dataContext,
                        metadata,
                        initialData,
                        editUIData,
                    );
                }, []);
                return (
                    <DSetProvider>
                        <AppFormUI dataContext={dataContext} {...props} />
                        {/* any other props will be passed down */}
                    </DSetProvider>
                );
            }

            function AppFormUI(props) {
                const [state, setState] = React.useState({
                    isLoading: true,
                    isErr: false,
                    errMessage: '',
                });
                const [terjadwalState, seTerjadwal] = React.useState({});

                // bind controls to _moduleId and _authToken
                const vComps = React.useMemo(
                    () =>
                        appAction.connect(
                            {
                                PanelDataDisplay,
                                FieldDataDisplay,
                                PanelButton,
                                FieldDataInput,
                            },
                            { _moduleId, _getToken: () => props._authToken },
                        ),
                    [],
                );

                // bind controls to datasets
                const [mainComps] = React.useMemo(
                    () => [
                        jsdset.connect(
                            { context: props.dataContext, dsetPath: 'main' },
                            vComps,
                        ),
                    ],
                    [props.dataContext, vComps],
                );

                // obtain action objects from data context
                const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
                    props.dataContext,
                    'main',
                );

                // set event on component mounting
                React.useEffect(() => {
                    (async function () {
                        dsMainAction.loadStore(
                            props.data,
                            'std',
                            serverDataMapping,
                            true,
                        );

                        if (state.isLoading == true) {
                            try {

                                const response = await appAction.fetchResource(
                                    props._moduleId,
                                    'method',
                                    'getConfirmation',
                                    props._authToken,
                                    props.data,
                                    true,
                                );

                                dsMainAction.setFields(response.resp);
                                setState({ ...state, isLoading: false });
                            } catch (error) {
                                props.closeModal(false);
                                AlertHandler(error.message, 'error');
                            }
                        }
                    })();
                }, [dsMainAction, props.uiMode, props.data]);

                const handleConfirm = async (action = false) => {
                    try {
                        const { fieldValidStates, fieldValidErrors } =
                            dsMainProxy;
                        const isInValid = Object.entries(
                            fieldValidStates,
                        ).filter(([i, v]) => {
                            return !v;
                        });

                        if (isInValid.length > 0)
                            throw new Error(fieldValidErrors[isInValid[0][0]]);

                        var dataUnload = dsMainProxy.unloadStore(
                            serverDataMapping,
                            {
                                includeLoadedRows: true,
                                includeDeletedRows: true,
                            },
                        );
                        if (dataUnload.data.length > 0) {
                            if (props.uiMode == 'edit') {
                                dataUnload.data[0]['__loadFlag'] = 'U'
                            } else {
                                dataUnload.data[0]['__loadFlag'] = 'N'
                            }
                            dataUnload.data[0]['tx_ref_number'] = props.tx_ref_number
                            dataUnload.session_id = props.session_id;
                            dataUnload.data[0].isProcess = action;


                            console.log(dataUnload)

                            var resp = await appAction.postData(
                                props._moduleId,
                                'saveData',
                                props._authToken,
                                dataUnload,
                            );

                            if (resp.isErr) throw new Error(resp.isErr);

                            props.closeModal(action);
                            if (action)
                                AlertHandler(
                                    'Data berhasil disimpan',
                                    'success',
                                );
                        }
                    } catch (err) {
                        props.closeModal(false);
                        AlertHandler(err.message, 'alert');
                    }
                };

                const AlertHandler = async (msg, type) => {
                    await frameAction.showModal({
                        contentClass: props => (
                            // <>{msg}</>
                            <AlertComponent
                                {...props}
                                title={msg}
                                type={type}
                            />
                        ),
                        size: 'small',
                    });
                };

                return (
                    <div>
                        {state.isLoading === false && !state.isErr && (
                            <>

                                {/* <FormLabel label="Rekening Sumber">
                                    <mainComps.FieldDataDisplay fieldName="account_no" />
                                </FormLabel> */}

                                {props.product_code === '425' ? null :
                                    <FormLabel label={"Kode Bank"}>
                                        <mainComps.FieldDataDisplay fieldName="kode_bank_tujuan" />
                                        {/* <Dropdown onChange={(e) => { dsMainAction.setField('kode_bank_tujuan', e.target.value); setState({ ...state, selectBank: e.target.value }); }} value={state.selectBank} item={state.bank} width='-webkit-fill-available' /> */}
                                    </FormLabel>}
                                <FormLabel label={props.trx_code === 'BLP' ? "Nomor Tujuan /Nomor Pelanggan" : "Nomor Rekening Tujuan"}>
                                    <mainComps.FieldDataDisplay fieldName="credit_account_no" />
                                </FormLabel>
                                <FormLabel label={props.trx_code === 'BLP' ? "Nominal" : "Nominal yang dikirim"}>
                                    <mainComps.FieldDataDisplay fieldName="amount" />
                                </FormLabel>

                                <FormLabel label={props.trx_code === 'BLP' ? "Keterangan" : "Berita"}>
                                    <mainComps.FieldDataDisplay fieldName="detail_description" />
                                </FormLabel>

                                <div
                                    style={{
                                        marginTop: 20,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <>
                                        <Button
                                            onClick={() => {
                                                handleConfirm(true);
                                            }}
                                            style={{
                                                marginRight: 10,
                                            }}
                                            disabled={state.isLoading}
                                        >
                                            Konfirmasi
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handleConfirm(false);
                                            }}
                                            type="bordered"
                                            disabled={state.isLoading}
                                        >
                                            Batalkan
                                        </Button>
                                    </>
                                </div>
                            </>
                        )}

                        <div
                            style={{
                                display: state.isErr ? 'block' : 'none',
                            }}
                        >
                            {state.errMessage}
                        </div>
                    </div>
                );
            }

            // return value may be different depending on params
            return React.memo(AppForm);
        }

        return { componentFactory };
    }

    async function initModuleF(aReact, globals) {
        return await getImports(aReact, globals);
    }

    return initModuleF;
    // })()  //--DAFReact: deploy
} //--DAFReact: development
