import React from 'react';
import '../Forms/Input/Input.scss';

export default function InputGroup({
	id = '',
	name = '',
	placeholder = '',
	style = {},
	icon,
	type = 'text',
	reference = null,
	validator = [true, ''],
	...otherprops
}) {
	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<input
				type={type}
				className={'input' + (validator[0] ? '' : ' error')}
				id={'inlineFormInputGroup' + id}
				name={'forminput' + name}
				placeholder={placeholder}
				style={{
					paddingLeft: 40,
				}}
				ref={reference}
				{...otherprops}
			></input>
			<span
				style={{
					position: 'absolute',
					top: 10,
					left: 10,
					...style,
				}}
				{...otherprops}
			>
				{icon}
			</span>

			{!validator[0] && <span style={{ color:'#b7282d' }}>{validator[1]}</span>}
		</div>
	);
}
