import React from 'react';

import { AppFrameProvider, AppModal } from './modules/com.ihsan/appframe_minimal.js';

import { StdAppProvider } from './modules/com.ihsan/appdata.js';
import 'bootstrap/dist/css/bootstrap.css';
import './app_styles/app.scss';
import './rc-menu.css';

import { MainPage } from './app_pages/mainPage.js';
import AppPage from './app_pages/appPage.js';

const FrameProvider = AppFrameProvider({ initialClasses: { AppPage } });
class App extends React.Component {
	render() {
		return (
			<FrameProvider>
				<StdAppProvider>
					<MainPage />
					<AppModal />
				</StdAppProvider>
			</FrameProvider>
		);
	}
}

export default App;
