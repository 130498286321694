/*
  dsetwidget.js
  Visual components that are linked to dataset object
  Use in conjunction with jsdsetconnect.js

  components may be connected using HOC function connect() in StdAppAction class
  expected HOC props:

  appAction: instance of StdAppAction
  _moduleId (str): module_id of containing frame
  _getToken (() => str): auth token of containing frame
*/

import React from 'react';

// import component here:
import Input from '../../app_components/Forms/Input/Input';
import Dropdown from '../../app_components/Dropdown/Dropdown';
import Button from '../../app_components/Button/Button';
import CheckboxBordered from '../../app_components/Forms/CheckboxBordered/CheckboxBordered';
import RadioGroup from '../../app_components/Forms/RadioGroup/RadioGroup';

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const PanelDataDisplay = p => {
	var vals = p.fields;
	var fieldDefs = p.fieldDefs;
	var selFieldNames = p.selFields;
	var delFieldNames = p.delFields;

	var inclFieldNames = Object.keys(fieldDefs);
	if (selFieldNames && Array.isArray(selFieldNames)) {
		inclFieldNames = inclFieldNames.filter(
			name => selFieldNames.indexOf(name) >= 0,
		);
	}

	if (delFieldNames && Array.isArray(delFieldNames)) {
		inclFieldNames = inclFieldNames.filter(
			name => delFieldNames.indexOf(name) < 0,
		);
	}

	fieldDefs = inclFieldNames
		.map(name => fieldDefs[name])
		.filter(
			fd =>
				!fd.isSystem ||
				(selFieldNames && selFieldNames.indexOf(fd.name) >= 0),
		);

	return (
		<div>
			{/* <div>panel data display...</div> */}
			{Object.keys(fieldDefs).map(k => {
				var fd = fieldDefs[k];
				var result =
					fd && fd.type != 'dataset' && fd.type != 'link' ? (
						<span key={fd.name}>
							<br />
							<b>{fd.title ? fd.title : fd.name}</b>:{' '}
							{fd.asString(vals[fd.name])}
						</span>
					) : (
						<span key={fd.name}></span>
					);
				return result;
			})}
		</div>
	);
};

const FieldDataDisplay = p => {
	var fieldName = p.fieldName;
	var fd = p.fieldDefs[fieldName];

	if (!fd) {
		// console.log(`Field ${fieldName} not found`);
		return <span></span>;
	}

	const uidField =
		(p.uiData && p.uiData.fields ? p.uiData.fields[p.fieldName] : {}) || {};
	const { numformatted, thousand, decimal, dateformat } = uidField;

	return (
		<>
			{/* <div>field data display...</div> */}
			<span>
				{p.fields === undefined
					? ''
					: fd
						? fd.asString(p.fields[fieldName], {
							numformatted,
							thousand,
							decimal,
							dateformat,
						})
						: `FIELD ${fieldName} NOT FOUND`}
			</span>
		</>
	);
};
class GridDataRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: false,
			basicActionShown: false,
			baLeft: '0px',
			baTop: '0px',
		};
	}

	onBasicMenuItemClick = e => {
		e.stopPropagation();
		console.log(
			`basic action menu clicked "${e.target.value}" - ${this.props.rowId}`,
		);
		this.setState({ ...this.state, basicActionShown: false });
		const gridProps = this.props.gridProps;
		switch (e.target.value) {
			case 'delete':
				gridProps.deleteRow(this.props.rowId);
				break;
			case 'swapUp':
				gridProps.swapRow(this.props.rowId, true);
				break;
			case 'swapDown':
				gridProps.swapRow(this.props.rowId, false);
				break;
			case 'insert':
				gridProps.insertRowAtId({}, this.props.rowId);
				break;
		}
	};

	onBasicMenuMouseOut = e => {
		e.preventDefault();
		this.setState({ ...this.state, basicActionShown: false });
	};

	onMouseOver = () => {
		// console.log('mouse over')
		this.setState({ ...this.state, selected: true });
		this.props.hidePopup(this.props.row);
	};

	onMouseOut = () => {
		// console.log('mouse out')
		this.setState({ ...this.state, selected: false });
	};

	onClick = e => {
		// console.log(`Row ${this.props.rowId} clicked`)
		e.stopPropagation();
		this.props.gridProps.goto({ rowId: this.props.rowId });
		if (
			this.props.onRowClick &&
			typeof this.props.onRowClick == 'function'
		) {
			this.props.onRowClick(this.props.row, this.props);
		}
	};

	onContextMenu = e => {
		e.preventDefault();
		// console.log(`context menu ${e.clientX}, ${e.clientY}`)
		this.props.setPopup(e.clientX, e.clientY, this.props.row);
		return false;
	};

	onDeleteButtonClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.deleteRow(e.target.id);
	};

	onBasicMenuButtonClick = e => {
		this.setState({
			...this.state,
			basicActionShown: true,
			baLeft: `${e.clientX}px`,
			baTop: `${e.clientY}px`,
		});
	};

	render() {
		const props = this.props;
		const uiData = props.uiData;

		return (
			<tr
				key={props.rowId}
				onMouseOver={this.onMouseOver}
				onMouseOut={this.onMouseOut}
				onClick={this.onClick}
				onContextMenu={this.onContextMenu}
				style={{
					backgroundColor: this.state.selected
						? '#dddddd'
						: '#ffffff',
				}}
			>
				{props.cells.map((cell, index) => {
					const fd = props.fieldDefs[index];
					const uidField =
						(uiData && uiData.fields
							? uiData.fields[fd.name]
							: {}) || {};
					const { numformatted, thousand, decimal, dateformat } =
						uidField;

					return (
						<td
							key={cell.key}
							align={
								index == props.cells.length - 1 &&
									props.rowButton
									? 'right'
									: 'left'
							}
						>
							{fd.asString(cell.value, {
								numformatted,
								thousand,
								decimal,
								dateformat,
							})}

							{props.rowButton === 'basic_actions' &&
								typeof props.rowButtonActions === 'object' &&
								this.state.basicActionShown ? (
								<div
									style={{
										position: 'absolute',
										left: this.state.baLeft,
										top: this.state.baTop,
									}}
									onMouseLeave={this.onBasicMenuMouseOut}
								>
									<select
										size={
											Object.keys(props.rowButtonActions)
												.length
										}
										onClick={this.onBasicMenuItemClick}
									>
										{Object.entries(
											props.rowButtonActions,
										).map(([action, title]) => (
											<option value={action} key={action}>
												{title}
											</option>
										))}
									</select>
								</div>
							) : (
								<></>
							)}

							{index == props.cells.length - 1 ? (
								<span>
									&nbsp;&nbsp;
									{props.rowButton === 'delete' ? (
										<button
											key={cell.key}
											style={{
												visibility: this.state.selected
													? 'visible'
													: 'hidden',
											}}
											onClick={this.onDeleteButtonClick}
											id={props.rowId}
										>
											{props.deleteButtonTitle ||
												'delete'}
										</button>
									) : props.rowButton === 'basic_actions' ? (
										<button
											key={cell.key}
											style={{
												visibility: this.state.selected
													? 'visible'
													: 'hidden',
											}}
											onClick={
												this.onBasicMenuButtonClick
											}
											id={props.rowId}
										>
											{props.actionButtonTitle ||
												'actions'}
										</button>
									) : (
										<></>
									)}
								</span>
							) : (
								<span>&nbsp;</span>
							)}
						</td>
					);
				})}
			</tr>
		);
	}
}

const GridDataDisplay = p => {
	/* 
	supported additional properties:
	- selFields: array of selected field names
	- delFields: array of deleted field names
	- columns: object (field name as key). object as value: 
	  - title: specific column title
	- rowButton: string, setting for row button. 'delete': display delete button, 'basic_actions': display basic actions such as swap up, down and del
	- rowButtonActions: object (action name as key). menu title as value. possible action names: 'delete', 'swapUp', 'swapDown', 'insert'
	- deleteButtonTitle: string, title for button when rowButton == 'delete', default is 'delete'
	- actionButtonTitle: string, title for button when rowButton == 'basic_actions', default is 'delete'
	- onRowClick: event when a row is clicked
  */

	const selectedFields = p.selFields;
	const deletedFields = p.delFields;
	const columns = p.columns || {}; // columns are object with field names as keys

	var fieldDefs = Object.keys(p.fieldDefs).map(k => p.fieldDefs[k]);
	var fieldDefs = fieldDefs.filter(
		f => f.type !== 'dataset' && f.type !== 'link',
	);
	var rows = p.fieldsArray;
	const [state, setState] = React.useState({
		popupShown: false,
		popupX: 0,
		popupY: 0,
		popupRow: undefined,
	});
	const setPopup = React.useCallback(
		(x, y, row) =>
			setState({ popupShown: true, popupX: x, popupY: y, popupRow: row }),
		[setState],
	);
	const hidePopup = React.useCallback(
		row => {
			if (row !== state.popupRow || row === null)
				setState({ popupShown: false });
		},
		[setState],
	);

	if (selectedFields && Array.isArray(selectedFields)) {
		fieldDefs = fieldDefs.filter(f => selectedFields.indexOf(f.name) >= 0);
	}

	if (deletedFields && Array.isArray(deletedFields)) {
		fieldDefs = fieldDefs.filter(f => deletedFields.indexOf(f.name) < 0);
	}

	fieldDefs = fieldDefs.filter(
		f =>
			!f.isSystem ||
			(selectedFields && selectedFields.indexOf(f.name) >= 0),
	);

	return (
		<div>
			<table className="ui celled table">
				<thead>
					{!p.hideColumnTitles ? (
						<tr>
							{fieldDefs.map(f => (
								<th key={f.name}>
									<b>
										{columns[f.name] &&
											columns[f.name].title
											? columns[f.name].title
											: f.title
												? f.title
												: f.name}
									</b>
								</th>
							))}
						</tr>
					) : (
						<></>
					)}
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<GridDataRow
							key={row.__rowId}
							rowId={row.__rowId}
							row={row}
							deleteRow={p.deleteRow}
							cells={fieldDefs.map((f, index) => ({
								key: index,
								value: row[f.name],
							}))}
							fieldDefs={fieldDefs}
							onRowClick={p.onRowClick}
							setPopup={setPopup}
							hidePopup={hidePopup}
							uiData={p.uiData}
							gridProps={{ ...p }}
							rowButton={p.rowButton}
							rowButtonActions={p.rowButtonActions}
							deleteButtonTitle={p.deleteButtonTitle}
							actionButtonTitle={p.actionButtonTitle}
						/>
					))}
				</tbody>
			</table>
			{state.popupShown && p.popupComponent ? (
				<div
					style={{
						position: 'absolute',
						left: state.popupX + 'px',
						top: state.popupY + 'px',
						width: p.popupWidth || '200px',
						// height: p.popupWidth || "300px",
						backgroundColor: 'Window',
						// borderStyle: "solid",
						// borderColor: "lightgray",
						// borderWidth: "1px",
						// padding: "-10px",
					}}
					onMouseLeave={() => {
						hidePopup(null);
					}}
				>
					<p.popupComponent currentRow={state.popupRow} />
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
class FieldDataInput extends React.Component {
	constructor(props) {
		super(props);

		const uidField =
			(props.uiData && props.uiData.fields
				? this.props.uiData.fields[props.fieldName]
				: {}) || {};
		this.uidField = uidField;

		// if (Object.keys(uidField).length > 0) {
		// 	console.log('uidField detected for field ' + props.fieldName);
		// 	console.log(uidField);
		// }

		const lookup =
			uidField.lookup && typeof uidField.lookup === 'object'
				? uidField.lookup
				: {};
		this.lookup = lookup;
		const lookupStyle = lookup.style || {};
		this.lookupStyle = lookupStyle;
		if (props.dataSelector && typeof props.dataSelector !== 'function')
			throw new Error('Data selector must be async function');
		if (props.dataValidator && typeof props.dataValidator !== 'function')
			throw new Error('Data validator must be async function');

		this.dataSelector =
			props.dataSelector ||
			(props.appAction &&
				props._moduleId &&
				props._getToken &&
				this.defaultDataSelector);

		this.dataValidator =
			props.dataValidator ||
			(props.appAction &&
				props._moduleId &&
				props._getToken &&
				this.defaultDataValidator);

		this.onInvalidData =
			props.onInvalidData ||
			(props.appAction &&
				props._moduleId &&
				props._getToken &&
				this.defaultInvalidData);
		this.state = { focused: false, textValue: '' };
	}

	defaultDataSelector = async (
		dataId,
		fields,
		keyFields,
		selFields,
		apiParameters,
		options,
	) => {
		return await this.props.appAction.browseData(
			{
				moduleId: this.props._moduleId,
				dataId,
				authToken: this.props._getToken(),
			},
			fields,
			keyFields,
			selFields,
			apiParameters,
			Object.assign(options, {
				title: null,
				minimalist: true,
				size: 'large',
			}),
		);
	};

	defaultDataValidator = async (
		dataId,
		fields,
		keyFields,
		selFields,
		apiParameters,
		options,
	) => {
		return await this.props.appAction.browseData(
			{
				moduleId: this.props._moduleId,
				dataId,
				authToken: this.props._getToken(),
			},
			fields,
			keyFields,
			selFields,
			apiParameters,
			Object.assign(options, {
				title: null,
				hideColumnTitles: true,
				minimalist: true,
				directSelection: true,
			}),
		);
	};

	defaultInvalidData = async fieldName => {
		await this.props.appAction.frameAction.showMessage(
			`No data match for ${fieldName}`,
		);
	};

	onChange = e => {
		const fieldName = this.props.fieldName;

		this.props.setField(fieldName, e.target.value);
		this.setState({ ...this.state, textValue: e.target.value });
		if (this.props.onChange && typeof this.props.onChange === 'function')
			this.props.onChange(e);
	};

	onFocus = e => {
		e.target.select();
		this.setState({ ...this.state, focused: true });
		if (this.props.onFocus && typeof this.props.onFocus === 'function')
			this.props.onFocus(e);
	};

	onExit = e => {
		const fieldName = this.props.fieldName;
		const checkFieldName = '__chk_' + fieldName;
		this.setState({ ...this.state, focused: false });

		if (
			this.uidField.lookup &&
			this.lookupStyle.input &&
			this.dataValidator
		) {
			//  validate this field if lookup-input field
			const lookup = this.lookup;
			const fields = this.props.fields;

			if (
				checkFieldName in fields &&
				fields[fieldName] !== fields[checkFieldName]
			) {
				var selectPromise = this.dataValidator(
					lookup.dataId,
					lookup.fields,
					lookup.keyField,
					lookup.selFields,
					lookup.apiParameterF &&
						typeof lookup.apiParameterF === 'function'
						? lookup.apiParameterF(this.props.fields, true)
						: {},
					this.props.validatorSettings || {},
				);
				if (
					!selectPromise.constructor ||
					selectPromise.constructor.name !== 'Promise'
				)
					throw new Error('dataValidator must be async function');

				selectPromise.then(result => {
					if (
						result &&
						lookup.fieldMap &&
						typeof lookup.fieldMap === 'object'
					) {
						var fieldSettings = Object.fromEntries(
							Object.keys(lookup.fieldMap).map(k => [
								k,
								result[lookup.fieldMap[k]],
							]),
						);
						fieldSettings['__chk_' + fieldName] =
							fieldSettings[fieldName];
						this.props.setFields(fieldSettings);
					} else if (result === null) {
						if (
							this.onInvalidData &&
							typeof this.onInvalidData === 'function'
						) {
							const pr = this.onInvalidData(fieldName);
							if (
								pr.constructor &&
								pr.constructor.name === 'Promise'
							) {
								pr.then(() => { });
							}
						}
						//
					}
				});
			}
		}

		if (this.props.onExit && typeof this.props.onExit === 'function')
			this.props.onExit(e);
	};

	onLookupClick = async e => {
		if (this.dataSelector) {
			const lookup = this.lookup;
			var selectPromise = this.dataSelector(
				lookup.dataId,
				lookup.fields,
				lookup.keyField,
				lookup.selFields,
				lookup.apiParameterF &&
					typeof lookup.apiParameterF === 'function'
					? lookup.apiParameterF(this.props.fields, false)
					: {},
				this.props.selectorSettings || {},
			);
			if (
				!selectPromise.constructor ||
				selectPromise.constructor.name !== 'Promise'
			)
				throw new Error('dataSelector must be async function');
			selectPromise.then(result => {
				if (
					result &&
					lookup.fieldMap &&
					typeof lookup.fieldMap === 'object'
				) {
					var fieldSettings = Object.fromEntries(
						Object.keys(lookup.fieldMap).map(k => [
							k,
							result[lookup.fieldMap[k]],
						]),
					);
					this.props.setFields(fieldSettings);
				}
			});
		}
	};

	// onKeyDown = (e) => {
	//   console.log(`key down - ${e.key} ${e.keyCode}`)
	// }

	render() {
		const props = this.props;
		const state = this.state;
		const fieldName = props.fieldName;
		const vals = props.fields;
		const fd = props.fieldDefs[fieldName];
		const elProps = props.elProps ? props.elProps : {};
		const lookupStyle = this.lookupStyle;
		const { numformatted, thousand, decimal, dateformat } = this.uidField;

		const { fieldValidErrors, fieldValidStates } = props;

		// if (!fd) console.log(`FieldDataInput: field ${fieldName} not found`);

		function getValue() {
			return fd
				? (state.focused
					? (fd.type === 'float' || fd.type === 'int') &&
						parseFloat(state.textValue) === vals[fieldName]
						? state.textValue
						: fd.asString(vals[fieldName])
					: fd.asString(vals[fieldName], {
						numformatted,
						thousand,
						decimal,
						dateformat,
					})) || ''
				: '';
		}

		// check if field is select, radio, or checkbox
		const {
			dataSets = null,
			inputType = null,
			initialValue = null,
			type = null,
			variant = null,
			withborder = null,
		} = this.uidField;

		// components for FieldDataInput
		const getInputComponent = () => {
			return (
				<Input
					value={getValue()}
					{...elProps}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onExit}
					errors={{
						isError: !fieldValidStates[fieldName] || false,
						errorMessage: fieldValidErrors[fieldName] || '',
					}}
					{...{
						readOnly:
							(this.uidField.lookup && !lookupStyle.input) ||
							this.uidField.readOnly ||
							false,
						maxLength: fd ? fd.length : undefined,
					}}
				/>
			);
		};

		const getLookupInput = () => {
			return (
				<div
					style={{
						display: 'flex',
					}}
				>
					<div style={{ flex: 9 }}>{getInputComponent()}</div>
					{lookupStyle.button && this.dataSelector && (
						<div style={{ flex: 1 }}>
							<Button
								style={{ width: '100%' }}
								onClick={this.onLookupClick}
							>
								...
							</Button>
						</div>
					)}
				</div>
			);
		};

		const getRadioComponent = ({ dataSets }) => {
			return (
				<>
					<RadioGroup
						data={Object.entries(dataSets)}
						name={fieldName}
						type={type}
						variant={variant}
						withborder={withborder}
						onChange={this.onChange}
						initialValue={initialValue}
						currentValue={vals[fieldName]}
						readOnly={this.uidField.readOnly || false}
						errors={{
							isError: !fieldValidStates[fieldName] || false,
							errorMessage: fieldValidErrors[fieldName] || '',
						}}
						{...elProps}
					/>
				</>
			);
		};

		const getCheckboxComponent = () => {
			return (
				<CheckboxBordered
					value={vals[fieldName]}
					onChange={this.onChange}
					label={fd.title}
				/>
			);
		};

		const getSelectComponent = ({ dataSets }) => {
			return (
				<Dropdown
					value={vals[fieldName]}
					onChange={this.onChange}
					items={dataSets}
					{...elProps}
					errors={{
						isError: !fieldValidStates[fieldName] || false,
						errorMessage: fieldValidErrors[fieldName] || '',
					}}
				/>
			);
		};

		const availableDataSets = {
			radio: getRadioComponent,
			checkbox: getCheckboxComponent,
		};

		if (dataSets && typeof dataSets === 'object') {
			return Object.keys(availableDataSets).indexOf(inputType) > -1
				? availableDataSets[inputType]({ dataSets })
				: getSelectComponent({ dataSets });
		}

		if (inputType === 'checkbox') return getCheckboxComponent();

		if (this.uidField.lookup && lookupStyle.input) {
			return getLookupInput();
		}

		return getInputComponent();
	}
}
class PanelButton extends React.Component {
	onClick = e => {
		if (this.props.navType == 'next') this.props.next();
		else if (this.props.navType == 'prev') this.props.prev();
		else if (this.props.navType == 'first') this.props.first();
		else if (this.props.navType == 'last') this.props.last();
		else if (this.props.navType == 'new') this.props.addRow({});
	};

	checkEnabled() {
		switch (this.props.navType) {
			case 'next':
				return this.props.hasNextRow;
			case 'prev':
				return this.props.hasPrevRow;
			case 'first':
			case 'last':
				return this.props.hasFirstLastRow;
			case 'new':
				return true;
		}
		return false;
	}
	render() {
		return (
			<button onClick={this.onClick} disabled={!this.checkEnabled()}>
				{this.props.caption}
			</button>
		);
	}
}

export {
	GridDataDisplay,
	PanelDataDisplay,
	FieldDataDisplay,
	FieldDataInput,
	PanelButton,
};
