import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { AlertComponent, Button, FormLabel, ViewTransaksiTerjadwal },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('transfer.rekening_sendiri.rekeningSendiriMetadata');

		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);

				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isLoading: true,
					isErr: false,
					errMessage: '',
				});

				const [terjadwalState, seTerjadwal] = React.useState({});

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				React.useEffect(() => {
					(async function () {
						dsMainAction.loadStore(props.data, 'std', serverDataMapping, true);
						if (state.isLoading == true) {
							try {
								await seTerjadwal(props.data.data[0].terjadwal);
								const response = await appAction.fetchResource(props._moduleId, 'method', 'getConfirmation', props._authToken, props.data.data[0], true);
								dsMainAction.setFields(response.resp);
								setState({ ...state, isLoading: false });
							} catch (error) {
								props.closeModal(false);
								AlertHandler(error.message, 'error');
							}
						}
					})();
				}, [dsMainAction, props.uiMode, props.data]);

				const handleConfirm = async (action = false) => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});

						if (dataUnload.data?.length > 0) {
							dataUnload.data[0]['tx_code'] = 'TRF';
							dataUnload.data[0]['product_code'] = '425';
							dataUnload.data[0]['terjadwal'] = terjadwalState;
							dataUnload.data[0].isProcess = action;

							var resp = await appAction.postData(props._moduleId, 'saveData', props._authToken, dataUnload.data[0]);

							if (resp.isErr) throw new Error(resp.isErr);

							props.closeModal(action);
							if (action) AlertHandler('Data berhasil disimpan', 'success');
						}
					} catch (err) {
						props.closeModal(false);
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					return await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				return (
					<div>
						{state.isLoading === false && !state.isErr && (
							<>
								<FormLabel label="Rekening Sumber">
									<b>
										<mainComps.FieldDataDisplay fieldName="account_no" /> - <mainComps.FieldDataDisplay fieldName="account_name" />
									</b>
								</FormLabel>
								<FormLabel label="Rekening Tujuan">
									<b>
										<mainComps.FieldDataDisplay fieldName="credit_account_no" /> - <mainComps.FieldDataDisplay fieldName="credit_account_name" />
									</b>
								</FormLabel>

								{terjadwalState &&
									terjadwalState.active === true && ( // need repair (Terjadwal)
										<ViewTransaksiTerjadwal terjadwalState={terjadwalState} />
									)}

								<FormLabel label="Jumlah">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</b>
								</FormLabel>
								<FormLabel label="Biaya Transaksi">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_fee" />
									</b>
								</FormLabel>
								<FormLabel label="Berita">
									<b>{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? <mainComps.FieldDataDisplay fieldName="description" /> : '-'}</b>
								</FormLabel>
								<FormLabel label="No. Dokumen">
									<b>
										{dsMainProxy.fields.external_ref_number && dsMainProxy.fields.external_ref_number !== '' ? (
											<mainComps.FieldDataDisplay fieldName="external_ref_number" />
										) : (
											'-'
										)}
									</b>
								</FormLabel>
								<FormLabel label="Tags">
									<b>
										<mainComps.FieldDataDisplay fieldName="tags" /> - <mainComps.FieldDataDisplay fieldName="tag_desc" />
									</b>
								</FormLabel>
								<div
									style={{
										marginTop: 20,
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<>
										<Button
											onClick={() => {
												handleConfirm(true);
											}}
											style={{
												marginRight: 10,
											}}
											disabled={state.isLoading}
										>
											Konfirmasi
										</Button>
										<Button
											onClick={() => {
												handleConfirm(false);
											}}
											type="bordered"
											disabled={state.isLoading}
										>
											Batalkan
										</Button>
									</>
								</div>
							</>
						)}

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
