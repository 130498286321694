import React from 'react';
import FormLabel from '../../app_components/Forms/FormLabel/FormLabel';
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const ViewTransaksiTerjadwal = (props) => {
    let hari = {
        1: 'minggu',
        2: 'senin',
        3: 'selasa',
        4: 'rabu',
        5: 'kamis',
        6: 'jum\'at',
        7: 'sabtu',
    }

    return (
        <>
            <FormLabel label="Jenis Transaksi Terjadwal">
                <b>{props.terjadwalState.tx_type !== '' ? (props.terjadwalState.tx_type == 'date' ? '1 kali' : 'Setiap Minggu atau Bulan') : '-'}</b>
            </FormLabel>

            {props.terjadwalState.tx_type === 'recurring' && (
                <>
                    <FormLabel label="Setiap">
                        <b>
                            {
                                props.terjadwalState.value.sched_period !== '' ? (props.terjadwalState.value.sched_period == 'W' ? 'Minggu' : 'Bulan') : '-'
                            }
                        </b>
                    </FormLabel>
                    {props.terjadwalState.value.sched_period === 'W' ? (
                        <FormLabel label="Pada Hari">
                            <b>
                                {
                                    props.terjadwalState.value.sched_day !== '' ? hari[props.terjadwalState.value.sched_day] : '-'
                                }
                            </b>
                        </FormLabel>) : (
                        <FormLabel label="Pada Tanggal">
                            <b>
                                {
                                    props.terjadwalState.value.sched_date !== '' ? props.terjadwalState.value.sched_date : '-'
                                }
                            </b>
                        </FormLabel>)}
                    <FormLabel label="Jumlah pengulangan">
                        <b>
                            {
                                props.terjadwalState.value.sched_count !== '' ? props.terjadwalState.value.sched_count : '-'
                            }
                        </b>
                    </FormLabel>

                </>
            )}


            <FormLabel label="Pada Tanggal">
                <b>
                    {
                        moment(props.terjadwalState.value.sched_exec_datetime).format('llll')
                    }
                </b>
            </FormLabel>
        </>
    );
};

export default ViewTransaksiTerjadwal;
