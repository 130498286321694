import React from 'react';
import ReactSelect from 'react-select/async';
import { createFilter } from 'react-select';

import './Dropdown.scss';

function Dropdown({
	item = [],
	label,
	style,
	reference,
	isDisabled = false,
	isLoading = false,
	isClearable = false,
	isRtl = false,
	isSearchable = false,
	value = '',
	onChange = () => {},
	...otherProps
}) {
	var selected;
	const options = item.map(i => {
		if (i.value === value) selected = { label: i.name, value: i.value };
		return { label: i.name, value: i.value };
	});

	const filterOptions = {
		ignoreCase: true,
		ignoreAccents: true,
		matchFrom: 'any',
		stringify: option => `${option.label} ${option.value}`,
		trim: true,
	};

	return (
		<div className="dropdown-container" style={{ ...style }}>
			<ReactSelect
				className="select-instance"
				style={{ width: `fit-content` }}
				defaultOptions={options}
				isDisabled={isDisabled}
				isLoading={isLoading}
				isClearable={isClearable}
				isRtl={isRtl}
				isSearchable={isSearchable}
				value={selected}
				ref={reference}
				onChange={e => {
					onChange({ target: { value: e?.value, label: e?.label } });
				}}
				filterOption={createFilter(filterOptions)}
				{...otherProps}
			/>
		</div>
	);
}

export default Dropdown;
