import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			dswidgetex,
			frameAction,
			staticComponents: {
				Button,
				FormLabel,
				ReactIconFa: { FaSearch },
				AlertComponent,
				Input,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule(
			'manajemen_transaksi.list_draft_transaksi.listDraftTransaksiMetadata',
		);

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
								DateFieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ tx_ref_number: props.tx_ref_number },
							true,
						);

						if (response?.data?.length > 0) {
							dsMainAction.loadStore(response, 'std', serverDataMapping, true);

							dsMainAction.recalcFormulas();
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.tx_ref_number]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						await loadData();
					})();
				}, [dsMainAction, loadData, props.uiMode]);

				return (
					<div>
						<FormLabel label="Nomor Draft Transaksi">
							<b>
								{dsMainProxy.fields.tx_ref_number && dsMainProxy.fields.tx_ref_number !== '' ? (
									<mainComps.FieldDataDisplay fieldName="tx_ref_number" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<FormLabel label="Status Eksekusi">
							<b>
								{dsMainProxy.fields.execution_status && dsMainProxy.fields.execution_status !== '' ? (
									<mainComps.FieldDataDisplay fieldName="execution_status" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<FormLabel label="Status Workflow">
							<b>
								{dsMainProxy.fields.workflow_state && dsMainProxy.fields.workflow_state !== '' ? (
									<mainComps.FieldDataDisplay fieldName="workflow_state" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<FormLabel label="Dari Rekening">
							<b>
								{dsMainProxy.fields.account_no && dsMainProxy.fields.account_no !== '' ? (
									<mainComps.FieldDataDisplay fieldName="account_no" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<FormLabel label="Keterangan">
							<b>
								{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? (
									<mainComps.FieldDataDisplay fieldName="description" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<FormLabel label="Jumlah">
							<b>
								{dsMainProxy.fields.amount && dsMainProxy.fields.amount !== '' ? (
									<mainComps.FieldDataDisplay fieldName="amount" />
								) : (
									'-'
								)}
							</b>
						</FormLabel>

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
