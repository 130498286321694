import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { Button, FormLabel, AlertComponent, ViewTransaksiTerjadwal },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error('One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('pembayaran.pajak.pajakMetadata');

		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(() => jsdset.dsetCreateContext(), []);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(dataContext, metadata, initialData, editUIData);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isLoading: true,
					isErr: false,
					errMessage: '',
				});
				const [terjadwalState, seTerjadwal] = React.useState({});

				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								FieldDataDisplay,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				const [mainComps] = React.useMemo(() => [jsdset.connect({ context: props.dataContext, dsetPath: 'main' }, vComps)], [props.dataContext, vComps]);

				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(props.dataContext, 'main');

				React.useEffect(() => {
					(async function () {
						dsMainAction.loadStore(props.data, 'std', serverDataMapping, true);

						if (state.isLoading == true) {
							try {
								await seTerjadwal(props.data.data[0].terjadwal);

								const response = await appAction.fetchResource(props._moduleId, 'method', 'getConfirmation', props._authToken, props.data.data[0], true);
								var tx_info = response.resp?.tx_info;
								console.log('🚀 ~ file: pajakEditNewKonfirmasi.js:72 ~ tx_info', tx_info);

								dsMainAction.setFields({ ...response.resp, ...tx_info });
								setState({ ...state, isLoading: false });
							} catch (error) {
								console.log('🚀 ~ file: pajakEditNewKonfirmasi.js:77 ~ error', error);
								props.closeModal(false);
								AlertHandler(error.message, 'error');
							}
						}
					})();
				}, [dsMainAction, props.uiMode, props.data]);

				const handleConfirm = async (action = false) => {
					try {
						const { fieldValidStates, fieldValidErrors } = dsMainProxy;
						const isInValid = Object.entries(fieldValidStates).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0) throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(serverDataMapping, {
							includeLoadedRows: false,
							includeDeletedRows: true,
						});
						if (dataUnload.data?.length > 0) {
							dataUnload.data[0]['terjadwal'] = terjadwalState;
							dataUnload.data[0].isProcess = action;
							var resp = await appAction.postData(props._moduleId, 'saveData', props._authToken, dataUnload.data[0]);

							if (resp.isErr) throw new Error(resp.isErr);

							props.closeModal(action);
							if (action) AlertHandler('Data berhasil disimpan', 'success');
						}
					} catch (err) {
						props.closeModal(false);
						AlertHandler(err.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const getMpnName = _ => {
					var c = dsMainProxy.fields.credit_account_no;
					if (['0', '1', '2', '3'].indexOf(c[0]) > -1) return 'DJP';
					if (['4', '5', '6'].indexOf(c[0]) > -1) return 'DJBC';
					return 'DJA';
				};

				return (
					<div>
						{state.isLoading === false && !state.isErr && (
							<>
								<FormLabel label="Rekening Sumber">
									<b>
										<mainComps.FieldDataDisplay fieldName="account_no" /> - <mainComps.FieldDataDisplay fieldName="account_name" />
									</b>
								</FormLabel>
								<FormLabel label="Kode Billing">
									<b>
										<mainComps.FieldDataDisplay fieldName="credit_account_no" />
										{dsMainProxy.fields?.credit_account_name && (
											<>
												{' - '}
												<mainComps.FieldDataDisplay fieldName="credit_account_name" />
											</>
										)}
									</b>
								</FormLabel>
								<FormLabel label="Jenis Transaksi">
									<b>Pembayaran / Pembelian {getMpnName()}</b>
								</FormLabel>
								{getMpnName() == 'DJP' && (
									<>
										<FormLabel label="NPWP">
											<b><mainComps.FieldDataDisplay fieldName="npwp" /></b>
										</FormLabel>
										<FormLabel label="Nama Wajib Pajak">
											<b><mainComps.FieldDataDisplay fieldName="namawajibpajak" /></b>
										</FormLabel>
										<FormLabel label="Jumlah Detil">
											<b><mainComps.FieldDataDisplay fieldName="akun" /></b>
										</FormLabel>
										{/* <FormLabel label="Kode Jenis Setoran">
											<b><mainComps.FieldDataDisplay fieldName="kodejenissetoran" /></b>
										</FormLabel>
										<FormLabel label="Masa Pajak">
											<b><mainComps.FieldDataDisplay fieldName="masapajak" /></b>
										</FormLabel>
										<FormLabel label="Nomor SK">
											<b><mainComps.FieldDataDisplay fieldName="nomorsk" /></b>
										</FormLabel> */}
									</>
								)}

								{getMpnName() == 'DJA' && (
									<>
										<FormLabel label="Lembaga Kementrian">
											<b><mainComps.FieldDataDisplay fieldName="kemenlembaga" /></b>
										</FormLabel>
										<FormLabel label="Kode Satuan Kerja">
											<b><mainComps.FieldDataDisplay fieldName="kodesatker" /></b>
										</FormLabel>
										<FormLabel label="Nama Wajib Bayar">
											<b><mainComps.FieldDataDisplay fieldName="namawajibbayar" /></b>
										</FormLabel>
										<FormLabel label="Unit Eselon">
											<b><mainComps.FieldDataDisplay fieldName="uniteselon" /></b>
										</FormLabel>
									</>
								)}

								{getMpnName() == 'DJBC' && (
									<>
										<FormLabel label="Nama Wajib Bayar">
											<b><mainComps.FieldDataDisplay fieldName="namawajibbayar" /></b>
										</FormLabel>
										<FormLabel label="ID Wajib Bayar">
											<b><mainComps.FieldDataDisplay fieldName="idwajibbayar" /></b>
										</FormLabel>
										<FormLabel label="Jenis Dokumen">
											<b><mainComps.FieldDataDisplay fieldName="jenisdokumen" /></b>
										</FormLabel>
										<FormLabel label="Kode KPPBC">
											<b><mainComps.FieldDataDisplay fieldName="kodekppbc" /></b>
										</FormLabel>
										<FormLabel label="No. Dokumen">
											<b><mainComps.FieldDataDisplay fieldName="nomordokumen" /></b>
										</FormLabel>
										<FormLabel label="Tgl. Dokumen">
											<b><mainComps.FieldDataDisplay fieldName="tanggaldokumen" /></b>
										</FormLabel>
									</>
								)}
								{terjadwalState &&
									terjadwalState.active === true && ( // need repair (Terjadwal)
										<ViewTransaksiTerjadwal terjadwalState={terjadwalState} />
									)}
								<FormLabel label="Tagihan">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</b>
								</FormLabel>
								<FormLabel label="Biaya Admin">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_fee" />
									</b>
								</FormLabel>
								<FormLabel label="Total biaya yang harus dibayar">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_net" />
									</b>
								</FormLabel>
								<div
									style={{
										marginTop: 20,
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<>
										<Button
											onClick={() => {
												handleConfirm(true);
											}}
											style={{
												marginRight: 10,
											}}
											disabled={state.isLoading}
										>
											Konfirmasi
										</Button>
										<Button
											onClick={() => {
												handleConfirm(false);
											}}
											type="bordered"
											disabled={state.isLoading}
										>
											Batalkan
										</Button>
									</>
								</div>
							</>
						)}

						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
}
