import React from 'react';

import Input from '../../app_components/Forms/Input/Input';
import DatePicker from '../../app_components/Forms/DatePicker/DatePicker';
import Dropdown from '../../app_components/Dropdown/Dropdown';
import Checkbox from '../../app_components/Forms/Checkbox/Checkbox';
import FormLabel from '../../app_components/Forms/FormLabel/FormLabel';

const TransaksiTerjadwal = ({ handleTerjadwal = () => console.log('getting data...') }) => {
	const initialState = {
		active: false,
		scheduled: 'F',
		tx_type: 'date', // date | recurring
		value: {
			sched_period: 'X', // x | m | w
			sched_count: 1, // jumlah pengulangan '
			sched_day: 1, // hari saat period week
			sched_date: 1, // tanggal saat period month
			sched_exec_datetime: new Date(), // tanggal mulai
			// start_date: new Date(), // start of periode
			// end_date: new Date(), //  end of periode
		},
	};

	const renderDate = () => {
		let tmp = [];
		for (let i = 1; i <= 30; i++) {
			tmp.push({ name: i, value: i.toString() });
		}
		return tmp;
	};

	const [state, setState] = React.useState(initialState);

	const handleChangeState = nState => {
		setState({ ...state, ...nState });
	};

	React.useEffect(() => {
		handleTerjadwal(state);
	}, [state]);

	return (
		<div style={{ marginBottom: 10 }}>
			<Checkbox label="Transaksi Terjadwal" onChange={() => handleChangeState({ active: !state.active, scheduled: state.active == true ? 'F' : 'T' })} />

			{state.active && (
				<div>
					<FormLabel label="Jenis Transaksi">
						<Dropdown
							item={[
								{ name: '1 kali', value: 'date' },
								{
									name: 'Setiap Minggu atau Bulan',
									value: 'recurring',
								},
							]}
							onChange={e => {
								handleChangeState({ tx_type: e.target.value, value: { ...state.value, sched_period: e.target.value == 'recurring' ? 'W' : 'X' } });
							}}
							value={state.tx_type}
						/>
					</FormLabel>

					{state.tx_type === 'recurring' && (
						<>
							<div
								style={{
									display: 'flex',
								}}
							>
								<FormLabel label="Setiap" style={{ flex: 1, marginRight: 20 }}>
									<Dropdown
										item={[
											{ name: 'Minggu', value: 'W' },
											{ name: 'Bulan', value: 'M' },
										]}
										onChange={e => {
											handleChangeState({
												value: {
													...state.value,
													sched_period: e.target.value,
												},
											});
										}}
										value={state.value.sched_period}
									/>
								</FormLabel>

								{state.value?.sched_period === 'M' ? (
									<FormLabel label={'Pada Tanggal'} style={{ flex: 1, marginRight: 20 }}>
										<Dropdown
											value={state.value.sched_date}
											item={renderDate()}
											name="tanggal"
											id="tanggal"
											onChange={e => {
												handleChangeState({
													value: {
														...state.value,
														sched_date: e.target.value,
													},
												});
											}}
										/>
									</FormLabel>
								) : null}

								{state.value?.sched_period === 'W' ? (
									<FormLabel label={'Pada Hari'} style={{ flex: 1, marginRight: 20 }}>
										<Dropdown
											value={state.value.sched_day}
											item={[
												{ value: 1, name: 'minggu' },
												{ value: 2, name: 'senin' },
												{ value: 3, name: 'selasa' },
												{ value: 4, name: 'rabu' },
												{ value: 5, name: 'kamis' },
												{ value: 6, name: "jum'at" },
												{ value: 7, name: 'sabtu' },
											]}
											name="hari"
											id="hari"
											onChange={e => {
												handleChangeState({
													value: {
														...state.value,
														sched_day: e.target.value,
													},
												});
											}}
										/>
									</FormLabel>
								) : null}

								<FormLabel label="Jumlah Pengulangan" style={{ flex: 1, marginRight: 20 }}>
									<Input
										type="number"
										min="1"
										value={state.value.sched_count}
										onChange={e => {
											handleChangeState({
												value: {
													...state.value,
													sched_count: e.target.value,
												},
											});
										}}
									/>
								</FormLabel>
							</div>
						</>
					)}

					<FormLabel label={state.tx_type === 'date' ? 'Pilih Tanggal' : 'Mulai dari Tanggal'}>
						<DatePicker
							value={state.value?.sched_exec_datetime || new Date()}
							onSelect={e => {
								handleChangeState({
									value: {
										...state.value,
										sched_exec_datetime: e,
									},
								});
							}}
						/>
					</FormLabel>
				</div>
			)}
		</div>
	);
};

export default TransaksiTerjadwal;
