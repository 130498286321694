import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports( React, globals ) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if ( !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser ) {
			throw new Error( 'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals' );
		}

		if ( !_moduleId ) {
			throw new Error( '_moduleId not defined' );
		}

		function componentFactory( params ) {
			const metadata = {
				historiTransaksiTags: {
					fields: [
						// Corporate
						// id_transaction,
						// amount,
						// account_no,
						// account_name,
						// credit_account_no,
						// credit_account_name,
						// tag_code,
						// tag_date,
						// tag_user_id
						{
							name: 'id_transaction',
							type: 'string',
							title: 'ID Transaksi',
							length: 6,
						},
						{
							name: 'amount',
							type: 'float',
							title: 'Jumlah',
							length: 20,
						},
						{
							name: 'tx_date',
							type: 'date',
							title: 'Tanggal',
							length: 20,
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'No. Rek',
							length: 20,
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rek.',
							length: 20,
						},
						{
							name: 'credit_account_no',
							type: 'string',
							title: 'No. Rek. Tujuan',
							length: 20,
						},
						{
							name: 'credit_account_name',
							type: 'string',
							title: 'Nama Rek. Tujuan',
							length: 20,
						},
						{
							name: 'tag_code',
							type: 'string',
							title: 'Kode Tag',
							length: 20,
						},
						{
							name: 'tag_date',
							type: 'date',
							title: 'Tanggal Tag',
							length: 20,
						},
						{
							name: 'tag_user_id',
							type: 'string',
							title: 'User Tag',
							length: 20,
						},
						{
							name: 'description',
							type: 'string',
							title: 'Deskripsi',
							length: 100,
						},
						{
							name: 'tag_amount',
							type: 'string',
							title: 'Jumlah',
							length: 100,
						},
					],

					indexes: [ 'id_transaction' ],
				},
			};

			const initialData = {
				'main:historiTransaksiTags': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'historiTransaksiTags',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				historiTransaksiTags: {
					fields: {
						amount: { thousand: true, decimal: 2 },
						tag_code: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getTags',
								selFields: [ 'tag_code', 'description' ],
								fieldMap: {
									tag_code: 'tag_code',
									description: 'description',
								},
								apiParameterF: ( fields, isValidation = false ) => {
									return isValidation
										? {
											searchMode: 1,
											searchKey: fields.tag_code,
											// resourceType: 'method',
										}
										: {
											tag_code: fields.tag_code,
											// resourceType: 'method',
										};
								},
							},
							readOnly: true
						},
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF( aReact, globals ) {
		return await getImports( aReact, globals );
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
