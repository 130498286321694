import React from 'react'; //--DAFReact: development
import './Checkbox.scss';

export default function Checkbox({
	label = 'No Label',
	onChange = {},
	id = '',
	name = '',
	icon,
	style,
	reference = null,
	...otherProps
}) {
	return (
		<label className="checkbox_container" style={{ ...style, cursor: 'pointer' }}>
			<input
				id={id}
				onChange={
					typeof onChange === 'function' ? e => onChange(e) : null
				}
				type="checkbox"
				name={'checkbox_' + name}
				ref={reference}
				{...otherProps}
			></input>
			<span>
				<span className="checkbox_label">{icon}</span> {'   '}
				{label}
			</span>
		</label>
	);
}
