import React from 'react'; //--DAFReact: development
import './Radio.scss';

export default function Radio({
	label = 'No Label',
	onChange,
	id = '',
	name = '',
	style,
	reference = null,
	...otherProps
}) {
	return (
		<label
			className="containerd"
			style={
				{
					...style
				}
			}
		>
			<input
				{...otherProps}
				id={id}
				onChange={e => onChange(e)}
				type="radio"
				name={'radio_' + name}
				ref={reference}
			></input>
			<span className="checkmarkd"></span>
			<>{label}</>
		</label>
	);
}
