/*
  DAFReact framework module - Application form (class)
  appname: digitalTransaction
  filename: digitalTransactionView.js
  moduleid: digitalTransactionView
  author: IK
*/

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			staticComponents: { FormLabel },
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'manajemen_transaksi.list_draft_transaksi.listDraftTransaksiMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput, FieldDataDisplay } = ndswidget;

		function componentFactory(params) {
			class listDraftTransaksiView extends React.PureComponent {
				constructor(props) {
					super(props);
					this.dsMainAction = null;
					this.appAction = appAction;
					this.dataContext = jsdset.dsetCreateContext();
					this.DSetProvider = jsdset.dsetMetaProvider(
						this.dataContext,
						metadata,
						initialData,
						editUIData,
					);
					this.state = { isErr: false, errMessage: '' };
				}

				async loadData(e) {
					var prevState = this.state;
					this.setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});

					try {
						var response = await this.appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							this.props._authToken,
							{ id_tx_draft: params.id_tx_draft },
						);
						this.dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);
					} catch (err) {
						this.setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}

				async componentDidMount() {
					/* load menu components or data from server here */
					await this.loadData();
				}

				render() {
					var vcomps = {
						PanelDataDisplay,
						FieldDataDisplay,
						PanelButton,
						FieldDataInput,
					}; // object of unconnected visual components

					var mainComps = jsdset.connectComponents(
						{ context: this.dataContext, dsetPath: 'main' },
						vcomps,
					);
					const DSetAction = jsdset.DSetAction;
					const DSetProvider = this.DSetProvider;

					return (
						<DSetProvider>
							<DSetAction
								ref={val => {
									this.dsMainAction = val;
								}}
								context={this.dataContext}
								dsetPath="main"
							/>
							<div>
								<FormLabel label="Nomor Draft Transaksi">
									<h6>
										<mainComps.FieldDataDisplay fieldName="id_tx_draft" />
									</h6>
								</FormLabel>

								<FormLabel label="Status Eksekusi">
									<h6>
										<mainComps.FieldDataDisplay fieldName="execution_status" />
									</h6>
								</FormLabel>

								<FormLabel label="Status Workflow">
									<h6>
										<mainComps.FieldDataDisplay fieldName="workflow_state" />
									</h6>
								</FormLabel>

								<FormLabel label="Dari Rekening">
									<h6>
										<mainComps.FieldDataDisplay fieldName="account_no" />
									</h6>
								</FormLabel>

								<FormLabel label="Keterangan">
									<h6>
										<mainComps.FieldDataDisplay fieldName="description" />
									</h6>
								</FormLabel>

								<FormLabel label="Jadwal Pembayaran">
									<h6>
										<mainComps.FieldDataDisplay fieldName="sched_exec_datetime" />
									</h6>
								</FormLabel>

								<FormLabel label="Batas Pembayaran">
									<h6>
										<mainComps.FieldDataDisplay fieldName="expire_datetime" />
									</h6>
								</FormLabel>

								<FormLabel label="Tanggal Eksekusi">
									<h6>
										<mainComps.FieldDataDisplay fieldName="exec_datetime" />
									</h6>
								</FormLabel>

								<FormLabel label="Mode">
									<h6>
										<mainComps.FieldDataDisplay fieldName="tx_mode" />
									</h6>
								</FormLabel>

								<FormLabel label="Kode Transaksi">
									<h6>
										<mainComps.FieldDataDisplay fieldName="tx_code" />
									</h6>
								</FormLabel>

								<FormLabel label="Kode Produk">
									<h6>
										<mainComps.FieldDataDisplay fieldName="product_code" />
									</h6>
								</FormLabel>

								<FormLabel label="Keterangan">
									<h6>
										<mainComps.FieldDataDisplay fieldName="tx_desc" />
									</h6>
								</FormLabel>

								<FormLabel label="Produk">
									<h6>
										<mainComps.FieldDataDisplay fieldName="product_desc" />
									</h6>
								</FormLabel>

								<FormLabel label="Jumlah">
									<h6>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</h6>
								</FormLabel>

								<FormLabel label="Fee">
									<h6>
										<mainComps.FieldDataDisplay fieldName="amount_fee" />
									</h6>
								</FormLabel>

								<div
									style={{
										display: this.state.isErr
											? 'block'
											: 'none',
									}}
								>
									{this.state.errMessage}
								</div>
							</div>
						</DSetProvider>
					);
				}
			}
			// return value may be different depending on params
			return listDraftTransaksiView;
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
