export const trx_code = {
    'TransferRekeningSamaBank': 'TRF425',
    'TransferBankLain': 'TRF',
    // 'TransferLuarNegeri': '2650729',
    // 'Payment': '5198259',
    // 'Purchase': '6950017',
    // 'PaymentVA': '6413449',
}


export const listTrx = {
    'TRF425': 'Transfer ke Rekening BJBS',
    'TRF': 'Transfer ke Bank Lain (ONLINE)'
}

export const product_code =
{
    'hpPascaBayar': 'hp_pascabayar',
    'Internet': 'internet',
    'tvLangganan': 'tv_langganan',
    'MultiFinance': 'multifinance',
    'Tiket': 'tiket',
    'Pajak': 'pajak',
    'PembayaranUmroh': 'pembayaran_umrah',
    'PelunasanHaji': 'pelunasan_haji',
    'BelanjaOnline': 'belanja_online',
    'plnPascaBayar': 'pln_pascabayar',
    'plnNonTaglist': 'pln_nontaglis',
    'TelkomPay': 'telkompay',
    'PembayaranVA': 'pembayaran_VA',
    'Pulsa': 'pulsa',
    'PaketData': 'paket_data',
    'plnPrabayar': 'pln_prabayar',
    'plnPrepaidAdvice': 'pln_prepaid_advice',
}

export const listProduct = {
    'hp_pascabayar': 'HP Pasca Bayar',
    'internet': 'Internet',
    'tv_langganan': 'TV Langganan',
    'multifinance': 'Multi Finance',
    'tiket': 'Tiket',
    'pajak': 'Pajak',
    'pembayaran_umrah': 'Pembayaran Umroh',
    'pelunasan_haji': 'Pelunasan Haji',
    'belanja_online': 'Belanja Online',
    'pln_pascabayar': 'PLN Pasca Bayar',
    'pln_nontaglis': 'PLN Non Taglist',
    'telkompay': 'TelkomPay',
    'pembayaran_VA': 'Pembayaran VA',
    'pulsa': 'Pulsa',
    'paket_data': 'Paket Data',
    'pln_prabayar': 'PLN Prabayar',
    'pln_prepaid_advice': 'PLN Prepaid Advice',
}
export const shown = {
    'hp_pascabayar': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'internet': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'tv_langganan': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'multifinance': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'tiket': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pajak': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pembayaran_umrah': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pelunasan_haji': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'belanja_online': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pln_pascabayar': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pln_nontaglis': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'telkompay': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex', 'detail_description'],
    'pembayaran_VA': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pulsa': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'paket_data': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pln_prabayar': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
    'pln_prepaid_advice': ['id_batch_detail', 'account_no', 'credit_account_no', 'account_name', 'amount', 'amount_ex'],
}


export const purchaseList = ['pulsa', 'paket_data', 'pln_prabayar', 'pln_prepaid_advice']

export const metode = {
    'TRFBNKONLINE': 'ONLINE',
    'TRFBNKSKN': 'SKN',
}

// export const metode = {
//     'ONLINE': 'ONLINE',
//     'SKN': 'SKN',
// }