import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				listTagihanVA: {
					fields: [
						// list draft trx
						{
							name: 'account_no',
							type: 'string',
							title: 'account_no',
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'account_name',
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'tx_code',
						},
						{
							name: 'tx_name',
							type: 'string',
							title: 'tx_name',
						},
						{
							name: 'date',
							type: 'date',
							title: 'date',
						},
						{
							name: 'amount',
							type: 'int',
							title: 'amount',
						},
						{
							name: 'expire_date',
							type: 'date',
							title: 'expire_date',
						},
						{
							name: 'status',
							type: 'string',
							title: 'status',
						},
					],

					indexes: ['account_no'],
				},
			};

			const initialData = {
				'main:listTagihanVA': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'listTagihanVA',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				listTagihanVA: {
					fields: {
						amount: { thousand: true, decimal: 2 },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
