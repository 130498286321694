import React, { useEffect, useState } from 'react';

import './Menu.scss';

const Menu = ({
	menuKey = null,
	title = '',
	submenu = [],
	icon = '',
	handleClick,
	handleChangeActiveHeader,
	activeMenu,
	activeHeader,
}) => {
	const handleHeaderClick = e => {
		if (submenu.length) handleChangeActiveHeader(menuKey);
		else handleClick({ main: '', key: menuKey });
	};

	return (
		<div
			className={
				'menu-container' +
				(activeHeader === menuKey ? ' open' : ' closed')
			}
		>
			<div className={'header'} onClick={handleHeaderClick}>
				<div className="icon">{icon ? icon : ''}</div>
				<div className="text">{title}</div>
			</div>
			{activeHeader === menuKey && (
				<div className="body">
					{submenu.map((i, k) => {
						return (
							<div
								key={'sub' + k}
								className={
									'submenu' +
									(activeMenu === i.key ? ' selected' : '')
								}
								onClick={() =>
									handleClick({ main: menuKey, key: i.key })
								}
							>
								{i.title}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Menu;
