import React from 'react';
import { FaSearch } from 'react-icons/fa';
import '../../app_styles/mixins/_input.scss';
import Input from '../Forms/Input/Input';
import Button from '../Button/Button';

import './InputGroupButton.scss';

const InputButton = ({
    handleSearch = () => console.log('search started...'),
    icon,
    labelButton,
    ...otherprops
}) => {
    return (
        <div className={'input-button-button'}>

            <Input className="input"  {...otherprops} />

            <div>
                <Button onClick={handleSearch} >{icon} {labelButton}</Button>
            </div>
        </div>
    );
};

export default InputButton;
