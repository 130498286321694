import React from 'react';

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				PageTitle,
				FormLabel,
				DatePicker,
				Dropdown,
				SearchBox,
				Filter,
				ReactIconFa,
				ReactIconSi,
				Input,
				FilterActionButtons,
				trxAndProductCode: { listTrx },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { FaPlusCircle, FaRetweet, FaPrint, FaFilter } = ReactIconFa;
		const { SiMicrosoftexcel } = ReactIconSi;
		const { editUIData } = await appAction.fetchAndExecModule('transaksi_massal.transaksi_massal.listTransaksiMassalMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					maxRow: 10,
					tx_code: [],
					role: null,
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					tx_code: '',
					execution_status: '',
				};

				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						await loadData();
					})();
				}, [props._authToken]);

				// load data function
				const loadData = React.useCallback(
					async _ => {
						setState({
							...state,
							isErr: false,
							errMessage: '',
						});

						const { role } = await appAction.getState();
						try {
							const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
							console.log('🚀 ~ file: listTransaksiMassal.js ~ line 83 ~ AppForm ~ role', role);

							if (listTrx) {
								let tmp = [{ value: '', name: 'Pilih transaksi' }];

								for (var x in listTrx) {
									tmp.push({
										value: x,
										name: listTrx[x],
									});
								}

								await setState({
									...state,
									tx_code: tmp,
									popupComponent,
									role,
								});
							}
						} catch (err) {
							setState({
								...state,
								isErr: true,
								errMessage: err.message,
								role,
							});
						}
					},
					[props._authToken],
				);

				const handleAddButton = React.useCallback(async () => {
					const { _authToken, componentClass } = await appAction.fetchFrameComponentWithToken(
						'transaksi_massal.transaksi_massal.listTransaksiMassalEditNew',
						{},
						{},
						{
							menuId: 'popup_menu',
							menuModuleId: _moduleId,
							key: 'mnuAddTransaksiMassal',
						},
					);

					if (componentClass && _authToken) {
						const modalshow = await frameAction.showModalAsync({
							headerProps: {
								title: ' Transaksi Massal Baru',
								icon: 'FaPlusCircle',
							},
							contentClass: componentClass,
							contentProps: {
								_authToken,
								uiMode: 'add',
							},
							size: 'large',
						});
						if (!modalshow) {
							// loadFunction();
							console.log('test');
						}
					}
				}, []);

				const handleFilter = React.useCallback(
					e => {
						setFilter(
							e
								? {
										...filter,
										...e,
								  }
								: initialFilter,
						);
					},
					[filter],
				);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								handleFilter();
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				return (
					<>
						<PageTitle title="List Transaksi Massal" subtitle="Transaksi Massal" style={{ marginBottom: `1.5rem` }}>
							{state.role == 'operator' && (
								<Button onClick={handleAddButton}>
									<FaPlusCircle /> Upload Transaksi Massal Baru
								</Button>
							)}
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							dsetPath="main"
							authToken={props._authToken}
							params={filter}
							uiData={editUIData}
							popupComponent={state.popupComponent}
							filterComponent={({ sortFields, changeParams, params, clearFilter }) => (
								<>
									<Filter>
										<FormLabel label="Tanggal Transaksi" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
											<div
												style={{
													display: 'flex',
												}}
											>
												<DatePicker
													value={filter.start_date}
													onChange={e => {
														handleFilter({
															start_date: e,
														});
													}}
												/>
												<div style={{ padding: 10 }}>Sampai</div>
												<DatePicker
													value={filter.end_date}
													onChange={e => {
														handleFilter({
															end_date: e,
														});
													}}
												/>
											</div>
										</FormLabel>

										<FormLabel label="Status Transaksi" style={{ width: '-webkit-fill-available' }}>
											<Dropdown
												onChange={e => {
													handleFilter({
														execution_status: e.target.value,
													});
												}}
												width={'-webkit-fill-available'}
												item={[
													{ value: '', name: 'Pilih status' },
													{ value: 'P', name: 'Sedang Berlangsung' },
													{ value: 'S', name: 'Dijadwalkan' },
													{ value: 'T', name: 'Dieksekusi' },
													{ value: 'E', name: 'Gagal' },
													{ value: 'C', name: 'Selesai' },
													{ value: 'F', name: 'Belum Dieksekusi' },
												]}
												value={filter.execution_status}
											/>
										</FormLabel>
									</Filter>

									<Filter>
										<FormLabel label="Jenis Transaksi" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
											<Dropdown
												onChange={e => {
													handleFilter({
														tx_code: e.target.value,
													});
												}}
												value={filter.tx_code}
												width={'-webkit-fill-available'}
												item={state.tx_code}
											/>
										</FormLabel>

										<FormLabel label="Urutan dan Cari" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
											<Dropdown
												onChange={e =>
													changeParams({
														sortField: e.target.value,
													})
												}
												item={sortFields}
												value={params.sortField}
												width="-webkit-fill-available"
											/>
										</FormLabel>
										<FormLabel label="&nbsp;" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
											<SearchBox
												label="Tampilkan"
												style={{
													marginLeft: 5,
													width: '300px',
												}}
												handleSearch={src => {
													changeParams({
														start_date: filter.start_date,
														end_date: filter.end_date,
														src: src,
														tx_code: filter.tx_code == 'TRF425' ? 'TRF' : filter.tx_code,
														product_code: filter.tx_code == 'TRF425' ? '425' : '',
														execution_status: filter.execution_status,
														// 	filter.account_no,
														// account_name:
														// 	filter.account_name,
														// txtype:filter.txtype
													});
												}}
												srcText={params.src}
											/>
										</FormLabel>

										<FormLabel label="&nbsp;">
											<FilterActionButtons buttons={ActionButtons(clearFilter)} />
										</FormLabel>
									</Filter>
								</>
							)}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
