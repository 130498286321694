import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			dswidgetex,
			frameAction,
			staticComponents: {
				Button,
				FormLabel,
				ReactIconFa: { FaSearch },
				AlertComponent,
				Input,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule('manajemen_akun.mutasi_rekening.mutasiRekeningMetadata');

		const { PanelDataDisplay, FieldDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput } = ndswidget;
		const { DateFieldDataInput } = dswidgetex;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					isEditing: false,
				});

				const ref_primary_cif_no = React.useRef(null);

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
								DateFieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ id_transaction: params.id_transaction },
							true,
						);
						console.log("🚀 ~ file: mutasiRekeningView.js ~ line 123 ~ loadData ~ response", response)


						if (response?.data?.length > 0) {

							dsMainAction.loadStore(
								response,
								'std',
								serverDataMapping,
								true,
							);

							dsMainAction.recalcFormulas();
						}
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, params.id_transaction]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {

						await loadData();

					})();
				}, [dsMainAction, loadData, props.uiMode]);


				return (
					<div>
						<div style={{ display: 'flex' }}>
							<FormLabel label="No. Transaksi" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.id_transaction && dsMainProxy.fields.id_transaction !== '' ? <mainComps.FieldDataDisplay fieldName="id_transaction" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Tanggal Input" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.tx_date && dsMainProxy.fields.tx_date !== '' ? <mainComps.FieldDataDisplay fieldName="tx_date" /> : '-'}</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Keterangan" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.description && dsMainProxy.fields.description !== '' ? <mainComps.FieldDataDisplay fieldName="description" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Jenis Transaksi" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.tx_type && dsMainProxy.fields.tx_type !== '' ? <mainComps.FieldDataDisplay fieldName="tx_type" /> : '-'}</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Nominal" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.amount && dsMainProxy.fields.amount !== '' ? <mainComps.FieldDataDisplay fieldName="amount" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Saldo Akhir" style={{ width: '100%' }}>
								<b>{dsMainProxy.fields.balance_after && dsMainProxy.fields.balance_after !== '' ? <mainComps.FieldDataDisplay fieldName="balance_after" /> : '-'}</b>
							</FormLabel>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
