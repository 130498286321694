import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isEmail, isPasword, isPhone },
			},
		} = globals;
		if (
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		function componentFactory(params) {
			const metadata = {
				listDraftTransaksi: {
					fields: [
						// list draft trx
						{
							name: 'tx_ref_number',
							type: 'string',
							title: 'tx_ref_number',
						},
						{
							name: 'execution_status',
							type: 'string',
							title: 'execution_status',
							length: 10,
						},
						{
							name: 'workflow_state',
							type: 'string',
							title: 'workflow_state',
							length: 10,
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'account_no',
							length: 10,
						},
						{
							name: 'description',
							type: 'string',
							title: 'description',
							length: 20,
						},
						{
							name: 'sched_exec_datetime',
							type: 'datetime',
							title: 'sched_exec_datetime',
						},
						{
							name: 'expire_datetime',
							type: 'datetime',
							title: 'expire_datetime',
						},
						{
							name: 'exec_datetime',
							type: 'datetime',
							title: 'exec_datetime',
						},
						{
							name: 'tx_mode',
							type: 'string',
							title: 'tx_mode',
							length: 30,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'tx_code',
							length: 10,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'product_code',
							length: 10,
						},
						{
							name: 'tx_desc',
							type: 'string',
							title: 'tx_desc',
							length: 20,
						},
						{
							name: 'product_desc',
							type: 'string',
							title: 'product_desc',
							length: 20,
						},
						{
							name: 'amount',
							type: 'float',
							title: 'amount',
							length: 20,
						},
						{
							name: 'amount_fee',
							type: 'float',
							title: 'amount_fee',
							length: 20,
						},
					],

					indexes: ['tx_ref_number'],
				},
			};

			const initialData = {
				'main:listDraftTransaksi': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'listDraftTransaksi',
					fieldMapping: {
						'*': true, // allow default fields
					},
				},
			};

			const editUIData = {
				listDraftTransaksi: {
					fields: {
						amount: { thousand: true, decimal: 2 },
						amount_fee: { thousand: true, decimal: 2 },
					},
				},
			};

			// return value may be different depending on params
			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
