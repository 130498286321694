/*
  DAFReact framework module - Application form (hook)
  appname: digitalTransaction
  filename: digitalTransactionList.js
  moduleid: digitalTransactionList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			staticComponents: {
				PageTitle,
				SearchBox,
				Filter,
				DatePicker,
				FilterActionButtons,
				ReactIconFa: { FaRetweet },
				FormLabel,
				Dropdown,
				RadioGroup,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } = await appAction.fetchAndExecModule('manajemen_transaksi.list_draft_transaksi.listDraftTransaksiMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
				};
				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => {
					return jsdset.dsetEmptyProvider();
				}, []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => {
								handleFilter();
								clearFilter();
							},
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const handleFilter = React.useCallback(
					e => {
						setFilter(e ? { ...filter, ...e } : initialFilter);
					},
					[filter],
				);

				return (
					<>
						<PageTitle subtitle="Manajemen Transaksi" title="List Draft Transaksi" />
						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="getTxDraftList"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							resourceType="method"
							dsetPath="main"
							autoLoad={false}
							uiData={editUIData}
							params={filter}
							filterComponent={({ sortFields, clearFilter, changeParams, params }) => {
								return (
									<>
										<Filter>
											{/* Filter Execution Status */}
											<FormLabel label="Status Eksekusi">
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: 5,
													}}
												>
													<RadioGroup
														data={Object.entries({
															'': 'Semua',
															T: 'Executed',
															F: 'Not Executed',
															S: 'Scheduled',
															E: 'Failed',
															P: 'Process',
														})}
														name={'execution_status'}
														currentValue={params.execution_status}
														initialValue={''}
														type={'icon'}
														varian={'bordered'}
														onChange={e => {
															changeParams({
																execution_status: e.target.value,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Filter Workflow Status */}
											<FormLabel label="Status Workflow">
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: 5,
													}}
												>
													<RadioGroup
														data={Object.entries({
															'': 'Semua',
															T: 'Constructing',
															C: 'Created',
															A: 'Authorized',
															R: 'Released',
															D: 'Discarded',
														})}
														name={'workflow_state'}
														currentValue={params.workflow_state}
														initialValue={''}
														type={'icon'}
														varian={'bordered'}
														onChange={e => {
															changeParams({
																workflow_state: e.target.value,
															});
														}}
													/>
												</div>
											</FormLabel>
										</Filter>

										<Filter>
											{/* Filter Tanggal */}
											<FormLabel label="Tanggal Eksekusi">
												<div style={{ display: 'flex' }}>
													<DatePicker
														value={filter.start_date}
														onSelect={e => {
															handleFilter({
																start_date: e,
															});
														}}
													/>
													<div style={{ padding: 10 }}>s.d.</div>
													<DatePicker
														value={filter.end_date}
														onSelect={e => {
															handleFilter({
																end_date: e,
															});
														}}
													/>
												</div>
											</FormLabel>

											{/* Filter Sort & Search */}
											<FormLabel label="Urutkan & Cari">
												<div
													style={{
														display: 'flex',
														justifyContent: 'left',
														alignItems: 'center',
													}}
												>
													<Dropdown
														onChange={e =>
															changeParams({
																sortField: e.target.value,
															})
														}
														item={sortFields}
														value={params.sortField}
													/>
													<SearchBox
														label="Tampilkan"
														style={{
															marginLeft: 5,
															width: 300,
														}}
														handleSearch={src =>
															changeParams({
																start_date: filter.start_date,
																end_date: filter.end_date,
																src: src,
															})
														}
														srcText={params.src}
													/>
												</div>
											</FormLabel>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													alignItems: 'center',
													marginTop: 10,
												}}
											>
												<FilterActionButtons buttons={ActionButtons(clearFilter)} />
											</div>
										</Filter>
									</>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
