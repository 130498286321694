// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				ReactIconFa: { FaChevronRight },
			},
		} = globals;

		if (!StdAppAction || !appAction || !jsdset || !dswidget || !DataBrowser) {
			throw new Error('One of required components (StdAppAction, appAction, jsdset, dswidget) not found in globals');
		}

		if (!_moduleId) {
			throw new Error('_moduleId not defined');
		}

		const { AppGlobalProvider } = await appAction.fetchAndExecModule('appGlobal');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					profilePopup: null,
					mainMenu: null,
					isShow: false,
					userInfo: {},
				});

				React.useEffect(() => {
					(async function () {
						const mainMenu = await appAction.fetchAndExecModule('MainMenu');

						const profilePopup = await appAction.fetchMenu(_moduleId, 'popup_menu', 'dummy');

						const { userName, corporate, role } = await appAction.getState();

						console.log(role);

						setState({
							...state,
							profilePopup,
							mainMenu,
							userInfo: {
								userName,
								corporate,
								role,
							},
						});
					})();
				}, []);

				const handlePopup = () => setState({ ...state, isShow: !state.isShow });

				return (
					<AppGlobalProvider>
						<div className="main-wrapper">
							<div className="menu-wrapper">{state.mainMenu && <state.mainMenu />}</div>
							<div className="content-wrapper">
								<div className="app-header justify-content-between">
									<div
										className="profile-btn-container"
										onClick={handlePopup}
										onMouseLeave={() =>
											setState({
												...state,
												isShow: false,
											})
										}
									>
										<div className="img-text-container">
											<div className={'img-container'}>
												<img src={''} />
											</div>
											<div className={'text-container'}>
												<div>{state.userInfo?.userName}</div>
												<div>
													{state.userInfo.role == 'apprel'
														? 'Approver & Releaser'
														: state.userInfo.role == 'approver'
														? 'Approver'
														: state.userInfo.role == 'releaser'
														? 'Releaser'
														: 'Operator'}
												</div>
											</div>
											<div className="icon-container">
												<FaChevronRight />
											</div>
										</div>
										{state.profilePopup && state.isShow && (
											<div
												style={{
													backgroundColor: 'white',
												}}
											>
												<state.profilePopup />
											</div>
										)}
									</div>
									<div>
										<h6>{state.userInfo?.corporate?.corporate_name}</h6>
									</div>
								</div>
								<props.AppFormContainer />
							</div>
						</div>
					</AppGlobalProvider>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
