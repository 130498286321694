import React from 'react';
import Spinner from '../Spinner/Spinner';

const RequestLoading = () => {
	return (
		<div
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.02)',
				zIndex: 100,
			}}
			className="loadingIndicator"
		>
			<div
				style={{
					width: 150,
					height: 80,
					backgroundColor: 'white',
					color: 'white',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
					color: 'black',
                    padding: '0px 20px 0 20px',
                    borderRadius: 5
				}}
			>
				<Spinner size={20} dark />
				Requesting...
			</div>
		</div>
	);
};

export default RequestLoading;
