import React from 'react'; //--DAFReact: development
import { useRef } from 'react';
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			ndswidget,
			dswidget,
			frameAction,
			staticComponents: {
				Input,
				AlertComponent,
				Dropdown,
				PageTitle,
				Button,
				FormLabel,
				RadioGroup,
				ReactIconFa: { FaInfoCircle },
				InputGroup,
				TransaksiTerjadwal,
				RekeningFavorite

			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'transfer.foreign_remitance.foreignRemitanceMetadata',
			);

		const componentClass = await appAction.fetchAndExecModule(
			'transfer.foreign_remitance.foreignRemitanceKonfirmasi',
		);


		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataInput, FieldDataDisplay } = ndswidget;



		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					step: 0,
					rekening: [],
					selectRekening: '',
					bank: [],
					selectBank: '',
					negara: [],
					selectNegara: '',
					kurs: 1,
					allData: {},
					currencyAsal: '',
					currencyTujuan: '',
					mataUang: null,
					terjadwalState: {},
				});

				let refAmount = useRef();
				{/* untuk rekening favorite */ }
				const favref = React.useRef(null);


				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadDataRekening = React.useCallback(async () => {
					setState(state => ({
						...state,
						isErr: false,
						errMessage: '',
					}));

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getRekening',
							props._authToken,
							{},
							true,
						);

						if (response?.rows) {
							let tmp = [{ value: '', name: 'Pilih Rekening' }];
							for (var x of response?.rows) {
								tmp.push({
									value: x.account_no,
									name:
										x.account_no +
										' - ' +
										x.account_name +
										' - (' +
										x.currency +
										')',
									account_name: x.account_name,
									currency: x.currency,
								});
							}
							setState(prevState => ({
								...prevState,
								rekening: tmp,
							}));
						}

						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}

					try {
						const response = await appAction.fetchResource(
							_moduleId,
							'method',
							'getKodeBankKurs',
							props._authToken,
							{},
							true,
						);

						if (response?.rows) {
							let tmp = [
								{ value: '', name: 'Pilih Negara Tujuan' },
							];
							let tmpData = {};
							for (var x of response?.rows) {
								tmp.push({
									value: x.id,
									name: x.id + ' - ' + x.description,
									description: x.description
								});

								tmpData[x.id] = x;
							}

							setState(prevState => ({
								...prevState,
								allData: tmpData,
								negara: tmp,
							}));
						}

						dsMainAction.recalcFormulas();
					} catch (err) {
						setState(prevState => ({
							...prevState,
							isErr: true,
							errMessage: err.message,
						}));
						return;
					}
				}, [dsMainAction, props._authToken, props.trf_code]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {

						dsMainAction.addRow({ kurs: 1, tx_code: 'TRF', product_code: 'TRFLUARNGR', });
						loadDataRekening();
					})();
				}, [dsMainAction, loadDataRekening, props.uiMode]);

				const handleConfirm = async () => {
					try {
						const { fieldValidStates, fieldValidErrors } =
							dsMainProxy;
						const isInValid = Object.entries(
							fieldValidStates,
						).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0)
							throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(
							serverDataMapping,
							{
								includeLoadedRows: false,
								includeDeletedRows: true,
							},
						);


						if (componentClass) {
							const modalshow =
								await frameAction.showModalAsync({
									headerProps: {
										title: 'Konfirmasi Transfer',
										icon: 'FaInfoCircle',
									},
									contentClass: componentClass,
									contentProps: {
										_moduleId,
										_authToken: props._authToken,
										data: dataUnload,
										terjadwalState: state.terjadwalState,
									},
									size: 'medium',
								});
							if (modalshow) {
								dsMainAction.deleteRow();
								dsMainAction.addRow({
									kurs: 1, tx_code: 'TRF', product_code: 'TRFLUARNGR',
								});
								setState({
									...state,
									selectRekening: '',
									selectRekeningTujuan: '',
									selectBank: '',
									selectNegara: '',
									product_codes: 'ONLINE',
									currencyTujuan: '',
									currencyAsal: '',
									bank: [],
								});

								{/* untuk reset rekening favorite */ }
								favref.current.checked = false
							}
						}

					} catch (error) {
						AlertHandler(error.message, 'alert');
					}
				};

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							// <>{msg}</>
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};

				const handleChangeCountry = e => {
					dsMainAction.deleteRow();
					dsMainAction.addRow({
						tx_code: 'TRF', product_code: 'TRFLUARNGR',
					})
					let index = state.rekening.findIndex(k => k.value == state.selectRekening);
					let rekening = state.rekening.filter(key => key['value'].includes(state.selectRekening)).map(datafilter => {
						return datafilter.account_name
					})
					let negara = state.negara.filter(key => key['value'].includes(e.target.value)).map(datafilter => {
						return datafilter.description
					})

					dsMainAction.setFields({
						'kode_negara_tujuan': e.target.value,
						'nama_negara_tujuan': negara[0],
						'account_no': state.selectRekening,
						'currencyAsal': state.rekening[index].currency,
						'account_name': rekening[0]
					});
					let tmpBank = [{ value: '', name: 'Pilih Kode Bank' }];
					if (state?.allData[e.target.value]) {
						for (let i of state?.allData[e.target.value]?.bank) {
							tmpBank.push({
								value: i.bank_no,
								name: i.bank_name,
							});
						}
						let value = state.mataUang;

						if (value == 1) {
							dsMainAction.setFields({
								kurs:
									1 *
									parseFloat(
										state?.allData[e.target.value]?.kurs,
									),
							});
						} else if (value == 2) {
							dsMainAction.setFields({
								kurs:
									1 /
									parseFloat(
										state?.allData[e.target.value]?.kurs,
									),
							});
						}

						dsMainAction.setFields({
							currencyTujuan:
								state?.allData[e.target.value]?.currency,
							kode_bank_tujuan: '',
							credit_account_name: '',
							credit_account_no: '',
						});

						setState({
							...state,
							selectNegara: e.target.value,
							selectBank: '',
							bank: tmpBank,
							kurs: parseFloat(
								state?.allData[e.target.value]?.kurs,
							),
							currencyTujuan:
								state?.allData[e.target.value]?.currency,
						});
					} else {
						dsMainAction.setFields({
							kurs: 1,
							currencyTujuan: '',
							kode_bank_tujuan: '',
							credit_account_name: '',
							credit_account_no: '',
						});
						setState({
							...state,
							selectNegara: e.target.value,
							selectBank: '',
							bank: [],
							kurs: 1,
							currencyTujuan: '',
						});
					}
				};

				const handlePilihRekening = value => {
					dsMainAction.deleteRow();
					dsMainAction.addRow({
						kurs: 1, tx_code: 'TRF', product_code: 'TRFLUARNGR',
					})
					let index = state.rekening.findIndex(k => k.value == value);
					let rekening = state.rekening
						.filter(key => key['value'].includes(value))
						.map(datafilter => {
							return datafilter.account_name;
						});

					let negara = state.negara.filter(key => key['value'].includes(state.selectNegara)).map(datafilter => {
						return datafilter.description
					})

					dsMainAction.setFields({
						account_no: value,
						currencyAsal: state.rekening[index].currency,
						account_name: rekening[0],
						'kode_negara_tujuan': state.selectNegara,
						'nama_negara_tujuan': negara[0],
					});
					setState({
						...state,
						selectRekening: value,
						currencyAsal: state.rekening[index].currency,
					});
				};

				const handlePilihMataUang = value => {
					setState({
						...state,
						mataUang: value,
					});
					if (value == 1) {
						dsMainAction.setFields({
							kurs: 1 * parseFloat(state.kurs),
							amount1: 0,
							amount2: 0,
						});
					} else if (value == 2) {
						dsMainAction.setFields({
							kurs: 1 / parseFloat(state.kurs),
							amount1: 0,
							amount2: 0,
						});
					}
				};

				React.useEffect(() => {
					dsMainAction.setField(
						'amount2',
						parseFloat(dsMainProxy.fields.amount1) /
						parseFloat(dsMainProxy.fields.kurs),
					);
				}, [refAmount?.current?.value]);

				const handleCekBiaya = (e) => {

					dsMainAction.setFields(
						{
							'amount_fee': '150000',
							'amount_net': parseFloat(dsMainProxy.fields.amount1) + 150000,
						}
					);
				}

				const handleTerjadwal = (terjadwalState = {}) => {
					setState({ ...state, terjadwalState });
				};

				return (
					<div>
						<PageTitle
							subtitle="Transfer Dana"
							title="Rekening Luar Negeri"
							style={{ marginBottom: `1.5rem` }}
						></PageTitle>

						<div>
							<FormLabel label="Rekening Sumber">
								<Dropdown
									onChange={e =>
										handlePilihRekening(e.target.value)
									}
									value={state.selectRekening}
									item={state.rekening}
									width="-webkit-fill-available"
								/>
							</FormLabel>

							<FormLabel label="Kode Negara Tujuan">
								<Dropdown
									onChange={e => {
										handleChangeCountry(e);
									}}
									value={state.selectNegara}
									item={state.negara}
									width="-webkit-fill-available"
								/>
							</FormLabel>
							{state.bank.length > 0 ? (
								<>
									<FormLabel label="Kode Bank">
										<Dropdown
											onChange={e => {
												dsMainAction.setField(
													'kode_bank_tujuan',
													e.target.value,
												);
												setState({
													...state,
													selectBank: e.target.value,
												});
											}}
											value={state.selectBank}
											item={state.bank}
											width="-webkit-fill-available"
										/>
									</FormLabel>

									<FormLabel label="Nomor Rekening Tujuan">
										<mainComps.FieldDataInput fieldName="credit_account_no" />
									</FormLabel>
								</>
							) : null}

							{state.bank.length > 0 ? (
								<FormLabel label="Nama Rekening Tujuan">
									<mainComps.FieldDataInput fieldName="credit_account_name" />
								</FormLabel>
							) : null}

							{state.currencyTujuan && state.currencyAsal ? (
								<>
									<FormLabel label="Hitung dengan mata Uang">
										<RadioGroup
											data={Object.entries({
												1: state.currencyAsal,
												2: state.currencyTujuan,
											})}
											display="block"
											name={'type_transaksi'}
											currentValue={state.mataUang}
											onChange={e => {
												handlePilihMataUang(
													e.target.value,
												);
											}}
										/>
									</FormLabel>
									{state.mataUang ? (
										<div style={{ display: 'flex' }}>
											<FormLabel label="&nbsp;">
												<Input
													disabled={true}
													value={
														state.mataUang == 1
															? state.currencyAsal
															: state.currencyTujuan
													}
												/>
											</FormLabel>
											<FormLabel
												label="Nominal yang dikirim"
												style={{
													width: '-webkit-fill-available',
												}}
											>
												<mainComps.FieldDataInput
													elProps={{
														reference: refAmount,
													}}
													fieldName={
														'amount1'

													}
												/>
											</FormLabel>
											<FormLabel
												label={
													'Kurs saat ini  ' +
													(state.mataUang == 1
														? '(' +
														state.currencyAsal +
														' - ' +
														state.currencyTujuan +
														')'
														: '(' +
														state.currencyTujuan +
														' - ' +
														state.currencyAsal +
														')')
												}
												style={{
													width: '-webkit-fill-available',
													marginRight: 10,
													marginLeft: 10,
												}}
											>
												<mainComps.FieldDataInput
													elProps={{ disabled: true }}
													fieldName="kurs"
												/>
											</FormLabel>
											{/* {state.currencyTujuan ? */}
											<FormLabel label="&nbsp;">
												<Input
													disabled={true}
													value={
														state.mataUang == 2
															? state.currencyAsal
															: state.currencyTujuan
													}
												/>
											</FormLabel>
											{/* : null} */}
											<FormLabel
												label="Nominal yang akan diterima"
												style={{
													width: '-webkit-fill-available',
												}}
											>
												<mainComps.FieldDataInput
													elProps={{ disabled: true }}
													fieldName={
														'amount2'

													}
												/>
											</FormLabel>
										</div>
									) : null}
									<div
										style={{
											display: 'flex',
											width: '-webkit-fill-available',
										}}
									>
										<FormLabel
											label="Biaya Transfer"
											style={{
												width: '-webkit-fill-available',
											}}
										>
											<mainComps.FieldDataInput
												elProps={{ disabled: true }}
												fieldName="amount_fee"
											/>
										</FormLabel>
										<FormLabel label="&nbsp;">
											<Button
												style={{
													marginLeft: 10,
													width: 200,
												}}
												onClick={e => {
													handleCekBiaya(e)
												}}
											>
												Cek Biaya Transfer
											</Button>
										</FormLabel>
									</div>

									<FormLabel label="Jumlah">
										<mainComps.FieldDataInput
											elProps={{ disabled: true }}
											fieldName="amount_net"
										/>
									</FormLabel>
								</>
							) : null}

							<FormLabel label="Berita">
								<mainComps.FieldDataInput fieldName="description" />
							</FormLabel>

							<TransaksiTerjadwal
								handleTerjadwal={handleTerjadwal}
							/>


							{/* untuk rekening favorite */}
							<RekeningFavorite {...props} mainComps={mainComps} dsMainAction={dsMainAction} dsMainProxy={dsMainProxy} reference={favref} />


							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							>
								<Button
									onClick={() => {
										handleConfirm();
									}}
								>
									Konfirmasi
								</Button>
							</div>

							<div
								style={{
									display: state.isErr ? 'block' : 'none',
								}}
							>
								{state.errMessage}
							</div>
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
