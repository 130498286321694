import React from 'react';
import Button from '../../app_components/Button/Button';
import { useState } from 'react';
import './Upload.scss';

export default function Upload({
	onChange,
	size = 'small',
	id = '',
	name = '',
	currenImg = '',
	icon,
	reference,
	...otherProps
}) {
	return (
		<div className="dividers" style={{}}>
			<input
				ref={reference}
				type="file"
				name={'file' + (name ? name : null) + (id ? id : null)}
				id={'file' + (name ? name : null) + (id ? id : null)}
				className="inputfile"
				onChange={e => {
					onChange(e);
				}}
				{...otherProps}
			/>
			<label
				htmlFor={'file' + (name ? name : null) + (id ? id : null)}
				style={{ padding: 10, borderRadius: 2.5 }}
			>
				{icon} Choose a file
			</label>
			<label style={{ paddingLeft: 10 }}>
				{currenImg || 'Please upload file...'}
			</label>
		</div>
	);
}
