import React from 'react'; //--DAFReact: development
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: { Button, FormLabel, AlertComponent, ViewTransaksiTerjadwal, Dropdown },
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'pembelian.pln_prepaid_advice.pLNPrepaidAdviceMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isLoading: true,
					isErr: false,
					errMessage: '',
				});
				const [terjadwalState, seTerjadwal] = React.useState({});


				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.loadStore(
							props.data,
							'std',
							serverDataMapping,
							true,
						);

						if (state.isLoading == true) {
							try {
								await seTerjadwal(props.data.data[0].terjadwal)

								const response = await appAction.fetchResource(
									props._moduleId,
									'method',
									'createConfirmation',
									props._authToken,
									props.data,
									true,
								);

								dsMainAction.setFields(response.resp);
								setState({ ...state, isLoading: false });
								const transactions = response.resp.transactions;
								if (transactions) {
									let tmpTransactions = [{ value: '', name: 'Pilih Transaksi' }];
									for (var x of transactions) {
										tmpTransactions.push({
											value: x.amount.toString(),
											name: x.amount.toString(),
											amount:x.amount,
											account_no:x.account_no,
											b48:x.b48,
											ref_no:x.ref_no
										});
									}
		
									setState(prevState => ({
										...prevState,
										transactions: tmpTransactions,
									}));
								}
							} catch (error) {
								props.closeModal(false);
								AlertHandler(error.message, 'error');
							}
						}
					})();
				}, [dsMainAction, props.data]);

				const handleConfirm = async (action = false) => {
					try {
						const { fieldValidStates, fieldValidErrors } =
							dsMainProxy;
						const isInValid = Object.entries(
							fieldValidStates,
						).filter(([i, v]) => {
							return !v;
						});

						if (isInValid.length > 0)
							throw new Error(fieldValidErrors[isInValid[0][0]]);

						var dataUnload = dsMainProxy.unloadStore(
							serverDataMapping,
							{
								includeLoadedRows: false,
								includeDeletedRows: true,
							},
						);
						if (dataUnload.data.length > 0) {

							dataUnload.data[0]['terjadwal'] = terjadwalState
							dataUnload.data[0]['tx_code'] = 'BLP'
							dataUnload.data[0].isProcess = action;
							var resp = await appAction.postData(
								props._moduleId,
								'saveData',
								props._authToken,
								dataUnload,
							);

							if (resp.isErr) throw new Error(resp.isErr);

							props.closeModal(action);
							if (action)
								AlertHandler('Data berhasil disimpan', 'success');
						}
					} catch (err) {
						props.closeModal(false);
						AlertHandler(err.message, 'alert');
					}
				};
				const handlePilihTransaksi = (e, field, direct) => {
					let transactions = state.transactions
						.filter(key => key['value'].includes(e.target.value))
						.map(datafilter => {
							return {
								amount: datafilter.amount,
								account_no: datafilter.account_no,
								b48: datafilter.b48,
								ref_no: datafilter.ref_no
							};
						});
					dsMainAction.setFields({
						[field]: e.target.value,
						account_no: transactions[0].account_no,
						b48: transactions[0].b48,
						ref_no: transactions[0].ref_no
						// [name]: transactions[0],
					});
					setState({
						...state,
						[direct]: e.target.value,
					});
				};
				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};


				return (
					<div>
						{state.isLoading === false && !state.isErr && (
							<>
								<FormLabel label="Rekening Sumber">
									<b>
										<mainComps.FieldDataDisplay fieldName="account_no" />{' '}
										-{' '}
										<mainComps.FieldDataDisplay fieldName="account_name" />
									</b>
								</FormLabel>

								<FormLabel label="Nomor Pelanggan/Nomor Meter">
									<b>
										<mainComps.FieldDataDisplay fieldName="credit_account_no" /> -{' '}
										<mainComps.FieldDataDisplay fieldName="credit_account_name" />
									</b>
								</FormLabel>


								{terjadwalState &&
									terjadwalState.active === true && ( // need repair (Terjadwal)
										<ViewTransaksiTerjadwal terjadwalState={terjadwalState} />
									)}

								{/* <FormLabel label="Tagihan">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount" />
									</b>
								</FormLabel> */}
								<FormLabel label="Pilih Nominal">
									<Dropdown
										onChange={e => {
											handlePilihTransaksi(
												e,
												'amount',
												'selectTrans',
												'',
											);
										}}
										isSearchable={true}
										placeholder={'Pilih Transaksi'}
										value={state.selectTrans}
										item={state.transactions}
										width="-webkit-fill-available"
									/>
								</FormLabel>
								<FormLabel label="Biaya Admin">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_fee" />
									</b>
								</FormLabel>
								<FormLabel label="Total biaya yang harus dibayar">
									<b>
										<mainComps.FieldDataDisplay fieldName="amount_net" />
									</b>
								</FormLabel>
								{!state.selectTrans && (
									<p>Silakan pilih nilai transaksi</p>
								)}
								<div
									style={{
										marginTop: 20,
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<>
										<Button
											onClick={() => {
												handleConfirm(true);
											}}
											style={{
												marginRight: 10,
											}}
											disabled={state.isLoading || !state.selectTrans}
										>
											Konfirmasi
										</Button>
										<Button
											onClick={() => {
												handleConfirm(false);
											}}
											type="bordered"
											disabled={state.isLoading}
										>
											Batalkan
										</Button>
									</>
								</div>
							</>
						)}



						<div
							style={{
								display: state.isErr ? 'block' : 'none',
							}}
						>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
