export function ModuleDefinition() {
	async function getImports( React, globals ) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			staticComponents: {
				Validator: { isExist, isPhone },
			},
		} = globals;
		if ( !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !DataBrowser ) {
			throw new Error( 'One of required components (StdAppAction, AppFrameAction, appAction, jsdset, dswidget) not found in globals' );
		}

		if ( !_moduleId ) {
			throw new Error( '_moduleId not defined' );
		}

		function componentFactory( params ) {
			const metadata = {
				main: {
					fields: [
						{
							name: 'txdraft_ref_number',
							type: 'string',
							title: 'txdraft_ref_number',
						},
						{
							name: 'account_no',
							type: 'string',
							title: 'Rekening Sumber',
							length: 20,
							validator: ( value, fieldName ) => {
								let check = isExist( value, 'Rekening Sumber' );
								return check;
							},
						},
						{
							name: 'account_name',
							type: 'string',
							title: 'Nama Rekening Sumber',
							length: 100,
						},
						{
							name: 'tx_code',
							type: 'string',
							title: 'Kode Transaksi',
							length: 20,
						},
						{
							name: 'product_code',
							type: 'string',
							title: 'Kode Produk',
							length: 20,
						},
						{
							name: 'product_name',
							type: 'string',
							title: 'Nama Produk',
							length: 120,
						},
						{
							name: 'credit_account_no',
							type: 'string',
							title: 'Rekening Tujuan',
							length: 20,
							validator: ( value, fieldName ) => {
								let check = isExist( value, 'Nomor Pelanggan' );
								return check;
							},
						},
						{
							name: 'credit_account_name',
							type: 'string',
							title: 'Nama Rekening Tujuan',
							length: 100,
						},
						{
							name: 'amount',
							type: 'int',
							title: 'Jumlah',
							length: 20,
						},
						{
							name: 'amount_fee',
							type: 'int',
							title: 'Biaya',
							length: 20,
						},
						{
							name: 'amount_ex',
							type: 'int',
							title: 'Biaya Lain-lain',
							length: 20,
						},
						{
							name: 'amount_net',
							type: 'int',
							title: 'Biaya',
							length: 20,
							formula: 'amount + amount_fee',
						},
						{
							name: 'description',
							type: 'string',
							title: 'Berita',
							length: 160,
						},
						{
							name: 'favorite',
							type: 'string',
							title: 'favorite',
							length: 10,
						},
						{
							name: 'favorite_name',
							type: 'string',
							title: 'favorite_name',
							length: 50,
						},
						{
							name: 'tags',
							type: 'string',
							title: 'Tags',
							length: 20,
						},
						{
							name: 'tag_class',
							type: 'string',
							title: 'Kelas Kode',
							length: 50,
						},
						{
							name: 'tag_desc',
							type: 'string',
							title: 'Deskipsi',
							length: 50,
						},
					],

					indexes: [ 'txdraft_ref_number' ],
				},
			};

			const initialData = {
				'main:main': [],
			};

			const serverDataMapping = {
				data: {
					dset: 'main',
					type: 'main',
					fieldMapping: {
						'*': true,
					},
				},
			};

			const editUIData = {
				main: {
					fields: {
						amount: { thousand: true, decimal: 2, separator: '.' },
						amount_fee: {
							thousand: true,
							decimal: 2,
							separator: '.'
						},
						amount_net: {
							thousand: true,
							decimal: 2,
							separator: '.'
						},
						account_no: {
							readOnly: true,
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getRekeningSumber',
								selFields: [ 'account_no', 'account_name', 'currency' ],
								fieldMap: {
									account_no: 'account_no',
									account_name: 'account_name',
									currency: 'currency',
								},
								apiParameterF: ( fields, isValidation = false ) => {
									return {};
								},
							},
						},
						tags: {
							lookup: {
								style: {
									idleCheck: false,
									input: true,
									button: true,
								},
								dataId: 'getCorporateTags',
								selFields: [ 'tags', 'tag_class', 'tag_desc' ],
								fieldMap: {
									tags: 'tag_code',
									tag_class: 'tag_class',
									tag_desc: 'description',
								},
								apiParameterF: ( fields, isValidation = false ) => { },
							},
						},
						product_code: {
							inputType: 'select',
							dataSets: {
								'070001': 'Listrik Pascabayar',
							},
						},
					},
				},
			};

			return { metadata, initialData, serverDataMapping, editUIData };
		}

		return { componentFactory };
	}

	async function initModuleF( aReact, globals ) {
		return await getImports( aReact, globals );
	}

	return initModuleF;
}
