import React from 'react'; //--DAFReact: development
import Radio from '../../Radio/Radio';
import RadioIcon from '../../RadioButtonBordered/Radiobordered';
import './RadioGroups.scss';

export default function RadioGroup({
	data, //[value,label]
	name,
	type, // (icon OR null)
	icon, // FOR type 'icon'
	variant, // FOR type 'icon' ('bordered' OR 'block')
	withborder, // FOR variant 'block' (boolean)
	display,
	reference,
	onChange,
	initialValue = '',
	currentValue = '',
	readOnly = false,
	...otherProps
}) {
	var componentProps = {
		name,
		onChange,
		reference,
		...otherProps,
	};

	return (
		<div
			className={withborder === true ? 'border' : 'noborder'}
			style={{ display: display ? display : 'flex' }}
		>
			{data
				? data.map(([value, label], index) => {
						var key = '_rd-' + value + 'name';
						var spesificProps = {
							label,
							value,
							key,
							id: key,
							checked:
								currentValue === value ||
								(!currentValue && value === initialValue),
						};

						return type === 'icon' ? (
							<RadioIcon
								{...{ ...spesificProps, ...componentProps }}
								icon={icon}
								types={variant}
								disabled={readOnly}
							/>
						) : (
							<Radio
								{...{ ...spesificProps, ...componentProps }}
								disabled={readOnly}
							/>
						);
				  })
				: null}
		</div>
	);
}
