import React from "react";

import "./FormLabel.scss";

const FormLabel = ({ children, label, ...otherprops }) => {
  return (
    <div className="form-label" {...otherprops}>
      <div className="label">{label}</div>
      <div>{children}</div>
    </div>
  );
};

export default FormLabel;
