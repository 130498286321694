import React from 'react';
import * as fa from 'react-icons/fa';

import './ModalHeader.scss';

const ModalHeader = ({
	title: headerTitle,
	closeModalFunction,
	icon = null,
}) => {
	return (
		<div className="header-modal">
			{icon && typeof fa[icon] === 'function' && <div>{fa[icon]()}</div>}
			<div>{headerTitle}</div>
			<div>
				<fa.FaTimes onClick={closeModalFunction} />
			</div>
		</div>
	);
};

export default ModalHeader;
