/*
  DAFReact framework module - Application form (hook)
  appname: cMSWorkflow
  filename: cMSWorkflowList.js
  moduleid: cMSWorkflowList
  author: IK
*/

// 'use strict';

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			DataBrowser,
			NDataBrowser,
			staticComponents: {
				FormLabel,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				ReactIconFa:{FaRetweet},
				Dropdown,
			},
		} = globals;
		if (
			!_moduleId ||
			!StdAppAction ||
			!AppFrameAction ||
			!appAction ||
			!jsdset ||
			!dswidget ||
			!DataBrowser
		) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals',
			);
		}

		const { editUIData } =
		await appAction.fetchAndExecModule(
			'manajemen_akun.list_rekening.listRekeningMetadata',
		);

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
				});

				const dsetProvider = React.useMemo(
					() => jsdset.dsetEmptyProvider(),
					[],
				);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(
							_moduleId,
							'popup_menu',
							props._authToken,
						);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				var loadFunction;

				const ActionButtons = React.useCallback(clearFilter => {
					return [
						{
							type: 'bordered',
							name: 'Reload',
							onClick: () => clearFilter(),
							render: () => <FaRetweet />,
						},
					];
				}, []);

				const setLoadFunction = React.useCallback(
					loadDataClick => (loadFunction = loadDataClick),
					[],
				);

				return (
					<>
						{/* PageTitle */}
						<PageTitle
							title="List VA"
							subtitle="Manajemen VA"
							style={{ marginBottom: `1.5rem`,marginRight:'1rem' }}
						>
						</PageTitle>

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							resourceType="method"
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({
								sortFields,
								loadDataClick,
								clearFilter,
								changeParams,
								params,
							}) => {
								setLoadFunction(loadDataClick);

								return (
									<Filter>
										<FormLabel
											label="Urutkan"
											style={{ maxWidth: '200px' }}
										>
											<Dropdown
												onChange={e =>
													changeParams({
														sortField:
															e.target.value,
													})
												}
												item={sortFields}
												value={params.sortField}
												width="100%"
												style={{
													justifyContent: 'flex-end',
												}}
											/>
										</FormLabel>
										<FormLabel
											label="Cari Data"
										>
											<SearchBox
												style={{
													marginLeft: 5,
													width: '300px'
												}}
												handleSearch={src =>
													changeParams({ src })
												}
												srcText={params.src}
											/>
										</FormLabel>

										<FilterActionButtons
											buttons={ActionButtons(clearFilter)}
										/>
									</Filter>
								);
							}}
							popupComponent={state.popupComponent}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};

	// })()  //--DAFReact: deploy
} //--DAFReact: development
