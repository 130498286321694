export function ModuleDefinition() {
	async function getImports(React, globals) {
		const {
			_moduleId,
			StdAppAction,
			AppFrameAction,
			appAction,
			jsdset,
			dswidget,
			NDataBrowser,
			frameAction,
			staticComponents: {
				Button,
				Dropdown,
				FormLabel,
				PageTitle,
				SearchBox,
				Filter,
				FilterActionButtons,
				AlertComponent,
				ReactIconFa: { FaRetweet },
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !AppFrameAction || !appAction || !jsdset || !dswidget || !NDataBrowser) {
			throw new Error('One of required components (_moduleId, StdAppAction, AppFrameAction, appAction, jsdset, dswidget, _moduleId) not found in globals');
		}

		const { editUIData } = await appAction.fetchAndExecModule('manajemen_akun.rekening_favorite.rekeningFavoriteMetadata');

		function componentFactory(params) {
			function AppForm(props) {
				const [state, setState] = React.useState({
					popupComponent: undefined,
					service_type: [],
				});

				const initialFilter = {
					start_date: new Date(),
					end_date: new Date(),
					service_type: '',
				};

				const [filter, setFilter] = React.useState(initialFilter);

				const dsetProvider = React.useMemo(() => jsdset.dsetEmptyProvider(), []);

				React.useEffect(() => {
					(async function () {
						const popupComponent = await appAction.fetchMenu(_moduleId, 'popup_menu', props._authToken);
						setState({ popupComponent });
					})();
				}, [props._authToken]);

				const ActionButtons = React.useCallback(
					({ clearFilter, params }) => {
						return [
							{
								type: 'bordered',
								name: 'Reload',
								onClick: () => {
									setFilter(initialFilter);
									clearFilter();
								},
								render: () => <FaRetweet />,
							},
						];
					},
					[filter],
				);

				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => <AlertComponent {...props} title={msg} type={type} />,
						size: 'small',
					});
				};

				const confirmComponent = ({ message, closeModal }) => {
					return (
						<div>
							<div
								style={{
									paddingTop: '6rem',
									paddingBottom: '6rem',
									marginBottom: 15,
									fontSize: '1.2rem',
								}}
							>
								{message}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 30,
								}}
							>
								<Button
									type="bordered"
									style={{
										marginRight: 10,
									}}
									onClick={() => closeModal(false)}
								>
									Batal
								</Button>
								<Button onClick={() => closeModal(true)}>Proses</Button>
							</div>
						</div>
					);
				};

				return (
					<>
						<PageTitle title="Rekening Favorite" subtitle="Manajemen Akun" style={{ marginBottom: `1.5rem` }} />

						<NDataBrowser
							stdAppAction={appAction}
							moduleId={_moduleId}
							dataId="listSO"
							dataProvider={dsetProvider}
							authToken={props._authToken}
							dsetPath="main"
							uiData={editUIData}
							filterComponent={({ sortFields, loadDataClick, clearFilter, changeParams, params }) => {
								return (
									<Filter>
										<FormLabel
											label="Urutkan"
											style={{
												marginLeft: 5,
												maxWidth: '250px',
											}}
										>
											<Dropdown
												style={{
													width: '250px',
												}}
												onChange={e =>
													changeParams({
														sortField: e.target.value,
													})
												}
												item={sortFields}
												width="100%"
												value={params.sortField}
											/>
										</FormLabel>

										<FormLabel label="Cari">
											<SearchBox
												style={{
													marginLeft: 5,
													width: '250px',
												}}
												handleSearch={src =>
													changeParams({
														src: src,
													})
												}
												srcText={params.src}
											/>
										</FormLabel>

										<FilterActionButtons
											buttons={ActionButtons({
												clearFilter,
												params,
											})}
										/>
									</Filter>
								);
							}}
							popupComponent={p => <state.popupComponent {...p} confirmComponent={confirmComponent} AlertHandler={e => AlertHandler(e)} />}
						/>
					</>
				);
			}

			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	return async (aReact, globals) => {
		return await getImports(aReact, globals);
	};
}
