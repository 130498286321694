import { Metadata, DataStore } from './jsdset.js';

import React from 'react';
import {
	ContextProviderHook,
	ContextConnector,
	createActionComponent,
	useDispatchContext,
} from './appcontext.js';

function createDatasetVars(initMetadata, initData, uiData = {}) {
	var md = new Metadata(uiData);
	try {
		md.load(initMetadata);
	} catch (error) {
		console.error(error.message);
		throw error;
	}

	var store = new DataStore(md);
	try {
		store.load(initData);
	} catch (error) {
		console.error(error.message);
		throw error;
	}

	if (typeof uiData !== 'object') {
		throw new Error('uiData must be object');
	}

	return { md, store, uiData, stamp: 0 };
}

var datasetActions = {
	setField: (vars, { rowPath, fieldName, value, deferFormula }) => {
		vars.store.update(
			[
				{
					inst: 'set',
					row: rowPath,
					values: { [fieldName]: value },
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	setFields: (vars, { rowPath, values, deferFormula }) => {
		vars.store.update(
			[
				{
					inst: 'set',
					row: rowPath,
					values: values,
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	addRow: (
		vars,
		{ containerRowPath, dsetPath, values, beforeIndex, deferFormula },
	) => {
		// console.log(`addRow() invoked. stamp: ${vars.stamp}`)
		vars.store.update(
			[
				{
					inst: 'add',
					row: containerRowPath,
					dset: dsetPath,
					values: values,
					before_row: beforeIndex,
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	insertRowAtId: (
		vars,
		{ containerRowPath, dsetPath, values, rowId, deferFormula },
	) => {
		vars.store.update(
			[
				{
					inst: 'add',
					row: containerRowPath,
					dset: dsetPath,
					values: values,
					before_row_id: rowId,
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	deleteRow: (vars, { rowPath, deferFormula }) => {
		vars.store.update(
			[
				{
					inst: 'del',
					row: rowPath,
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	swapRow: (vars, { rowPath, directionUp }) => {
		if (vars.store.swapRow(rowPath, directionUp, vars.stamp))
			return { ...vars, stamp: vars.stamp + 1 };
	},

	clear: (vars, { containerRowPath, dsetPath, deferFormula }) => {
		vars.store.update(
			[
				{
					inst: 'clear',
					owner_row: containerRowPath,
					dset: dsetPath,
				},
			],
			{ deferFormula },
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	nav: (vars, { inst, containerRowPath, dsetPath }) => {
		// console.log(`navigation invoked ${inst} @${dsetPath} stamp: ${vars.stamp}`)
		if (vars.store.navigate(inst, containerRowPath, dsetPath, vars.stamp))
			return { ...vars, stamp: vars.stamp + 1 };
	},

	goto: (vars, { dsetPath, rowIndex, rowId, indexField, value }) => {
		if (
			vars.store.goto(
				dsetPath,
				rowIndex,
				rowId,
				indexField,
				value,
				vars.stamp,
			)
		)
			return { ...vars, stamp: vars.stamp + 1 };
	},

	load: (vars, { dsetPath, json, dataFormat, markLoadFlag }) => {
		if (
			vars.store.loadDataset(
				dsetPath,
				json,
				dataFormat,
				markLoadFlag,
				vars.stamp,
			)
		)
			return { ...vars, stamp: vars.stamp + 1 };
	},

	recalcFormulas: vars => {
		vars.store.recalcFormulas();
		return { ...vars, stamp: vars.stamp + 1 };
	},

	resetStore: vars => {
		vars.store.reset(vars.stamp);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	loadStore: (vars, { json, dataFormat, dataMapping, markLoadFlag }) => {
		vars.store.load(
			json,
			dataFormat,
			dataMapping,
			markLoadFlag,
			vars.stamp,
		);
		return { ...vars, stamp: vars.stamp + 1 };
	},

	resetAll: (vars, { initMetadata, initData, uiData }) => {
		const newVars = createDatasetVars(initMetadata, initData, uiData);
		return newVars;
	},
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const metaMapVarsToProps =
	(dsetPath, allRows = false) =>
	(vars, ownProps) => {
		var store = vars ? vars.store : undefined;
		var ds = store ? store.findDataset(dsetPath) : undefined;

		if (!ds) {
			return {
				dsetPath: dsetPath,
				fieldDefs: EMPTY_OBJECT,
				stamp: undefined,
				fields: EMPTY_OBJECT,
				fieldsArray: EMPTY_ARRAY,
				fieldValidStates: EMPTY_OBJECT,
				fieldValidErrors: EMPTY_OBJECT,
				rowValidState: true,
				rowValidError: '',
			};
		}

		if (!allRows) {
			var activeRow = ds ? ds.getActiveRow() : undefined;
			return {
				dsetPath: dsetPath,
				fieldDefs: ds ? ds.dataDef.allFieldDefs : EMPTY_OBJECT,
				stamp: activeRow ? activeRow.rowStamp : undefined,
				fields: activeRow ? activeRow.fields : EMPTY_OBJECT,
				fieldValidStates: activeRow
					? activeRow.fieldValidStates
					: EMPTY_OBJECT,
				fieldValidErrors: activeRow
					? activeRow.fieldValidErrors
					: EMPTY_OBJECT,
				rowValidState: activeRow ? activeRow.rowValidState : true,
				rowValidError: activeRow ? activeRow.rowValidError : '',
				uiData: vars.uiData ? vars.uiData[ds.typeName] || {} : {},
				hasNextRow: activeRow ? activeRow.hasNextRow() : false,
				hasPrevRow: activeRow ? activeRow.hasPrevRow() : false,
				hasFirstLastRow: activeRow ? activeRow.datasetHasRows() : false,
			};
		} else {
			return {
				dsetPath: dsetPath,
				fieldDefs: ds ? ds.dataDef.allFieldDefs : EMPTY_OBJECT,
				stamp: ds ? ds.rowsStamp : undefined,
				fieldsArray: ds ? ds.rowFields : EMPTY_ARRAY,
				uiData: vars.uiData ? vars.uiData[ds.typeName] || {} : {},
			};
		}
	};

const metaMapDispatchToProps = dsetPath => disp => ({
	setField: (fieldName, value, deferFormula = false) =>
		disp({
			type: 'setField',
			rowPath: dsetPath,
			fieldName,
			value,
			deferFormula,
		}),
	setFields: (fieldNameValues, deferFormula = false) =>
		disp({
			type: 'setFields',
			rowPath: dsetPath,
			values: fieldNameValues,
			deferFormula,
		}),
	next: () => disp({ type: 'nav', inst: 'next', dsetPath }),
	prev: () => disp({ type: 'nav', inst: 'prev', dsetPath }),
	first: () => disp({ type: 'nav', inst: 'first', dsetPath }),
	last: () => disp({ type: 'nav', inst: 'last', dsetPath }),
	addRow: (values, beforeIndex, deferFormula = false) =>
		disp({ type: 'addRow', dsetPath, values, beforeIndex, deferFormula }),
	insertRowAtId: (values, rowId, deferFormula = false) =>
		disp({ type: 'insertRowAtId', dsetPath, values, rowId, deferFormula }),
	deleteRow: (rowId, deferFormula = false) =>
		disp({
			type: 'deleteRow',
			rowPath: { dset: dsetPath, row: rowId },
			deferFormula,
		}),
	swapRow: (rowId, directionUp) =>
		disp({
			type: 'swapRow',
			rowPath: { dset: dsetPath, row: rowId },
			directionUp,
		}),
	clear: () => disp({ type: 'clear', dset: dsetPath }),
	goto: ({ rowIndex, rowId, fieldIndex, value }) =>
		disp({ type: 'goto', dsetPath, rowIndex, rowId, fieldIndex, value }),
	load: (json, dataFormat, markLoadFlag) =>
		disp({ type: 'load', dsetPath, json, dataFormat, markLoadFlag }),
	recalcFormulas: () => disp({ type: 'recalcFormulas' }),
	resetStore: () => disp({ type: 'resetStore' }),
	resetAll: (initMetadata, initData, uiData) =>
		disp({ type: 'resetAll', initMetadata, initData, uiData }),
	loadStore: (json, dataFormat, dataMapping = {}, markLoadFlag) =>
		disp({
			type: 'loadStore',
			json,
			dataFormat,
			dataMapping,
			markLoadFlag,
		}),
});

const dsetCreateContext = () => React.createContext({});
const dsetMetaProvider = (context, metadata, initData, uiData) => {
	var vars = createDatasetVars(metadata, initData, uiData);
	const ProviderComponent = ContextProviderHook(
		context,
		datasetActions,
		vars,
	);
	ProviderComponent.dataContext = context;
	return ProviderComponent;
};

const dsetEmptyProvider = () =>
	dsetMetaProvider(dsetCreateContext(), {}, {}, {});

const dsetMetaProviderEx = (context, metadata, initData, uiData) => {
	var vars = createDatasetVars(metadata, initData, uiData);
	const ProviderComponent = ContextProviderHook(
		context,
		datasetActions,
		vars,
	);
	ProviderComponent.dataContext = context;
	return [ProviderComponent, vars.store];
};

const dsetMetaConnector = (context, dataPath, allRows = false) =>
	ContextConnector(
		context,
		metaMapVarsToProps(dataPath, allRows),
		metaMapDispatchToProps(dataPath),
	);

function connectComponents({ context, dsetPath, allRows }, comps) {
	var connector = dsetMetaConnector(context, dsetPath, allRows);
	return Object.fromEntries(
		Object.entries(comps).map(([key, comp]) => [key, connector(comp)]),
	);
	// return [{}].concat(Object.entries(comps).map(
	//     (k_c) => [k_c[0], connection(k_c[1])]
	//   )).reduce(
	//     (v, k_cc) => ({
	//         ...v,
	//         [k_cc[0]]: k_cc[1]
	//     })
	//   )
}

const connect = connectComponents;

const ACTION_METHOD_NAMES = [
	'setField',
	'setFields',
	'next',
	'prev',
	'first',
	'last',
	'addRow',
	'insertRowAtId',
	'deleteRow',
	'swapRow',
	'clear',
	'goto',
	'load',
	'recalcFormulas',
	'resetStore',
	'resetAll',
	'loadStore',
];

class DSetAction_Base {
	constructor(disp, dsetPath) {
		this.disp = disp;
		this.dsetPath = dsetPath;
	}
	setField(fieldName, value, deferFormula = false) {
		this.disp({
			type: 'setField',
			rowPath: this.dsetPath,
			fieldName,
			value,
			deferFormula,
		});
	}
	setFields(fieldNameValues, deferFormula = false) {
		this.disp({
			type: 'setFields',
			rowPath: this.dsetPath,
			values: fieldNameValues,
			deferFormula,
		});
	}
	next() {
		this.disp({ type: 'nav', inst: 'next', dsetPath: this.dsetPath });
	}
	prev() {
		this.disp({ type: 'nav', inst: 'prev', dsetPath: this.dsetPath });
	}
	first() {
		this.disp({ type: 'nav', inst: 'first', dsetPath: this.dsetPath });
	}
	last() {
		this.disp({ type: 'nav', inst: 'last', dsetPath: this.dsetPath });
	}
	addRow(values, beforeIndex, deferFormula = false) {
		this.disp({
			type: 'addRow',
			dsetPath: this.dsetPath,
			values,
			beforeIndex,
			deferFormula,
		});
	}
	insertRowAtId(values, rowId, deferFormula = false) {
		this.disp({
			type: 'insertRowAtId',
			dsetPath: this.dsetPath,
			values,
			rowId,
			deferFormula,
		});
	}
	deleteRow(rowId, deferFormula = false) {
		this.disp({
			type: 'deleteRow',
			rowPath: { dset: this.dsetPath, row: rowId },
			deferFormula,
		});
	}
	swapRow(rowId, directionUp) {
		this.disp({
			type: 'swapRow',
			rowPath: { dset: this.dsetPath, row: rowId },
			directionUp,
		});
	}
	clear() {
		this.disp({ type: 'clear', dset: this.dsetPath });
	}
	goto({ rowIndex, rowId, fieldIndex, value }) {
		this.disp({ type: 'goto', rowIndex, rowId, fieldIndex, value });
	}
	load(json, dataFormat, markLoadFlag) {
		this.disp({
			type: 'load',
			dsetPath: this.dsetPath,
			json,
			dataFormat,
			markLoadFlag,
		});
	}
	recalcFormulas() {
		this.disp({ type: 'recalcFormulas' });
	}
	resetStore() {
		this.disp({ type: 'resetStore' });
	}
	resetAll(initMetadata, initData, uiData) {
		this.disp({ type: 'resetAll', initMetadata, initData, uiData });
	}
	loadStore(json, dataFormat, dataMapping = {}, markLoadFlag) {
		this.disp({
			type: 'loadStore',
			json,
			dataFormat,
			dataMapping,
			markLoadFlag,
		});
	}
}

class DSetAction extends React.PureComponent {
	constructor(props) {
		super(props);
		this.baseAction = new DSetAction_Base(undefined, this.props.dsetPath); // the disp property of baseAction will be set during render
		this.ActionComponent = createActionComponent(
			this.props.context,
			this,
			state => {
				const mapper = metaMapVarsToProps(
					this.props.dsetPath,
					this.props.multiRow,
				);
				const mp = mapper(state);
				this.dataStore = state.store;
				this.dsetPath = this.props.dsetPath;
				this.fieldDefs = mp.fieldDefs;
				this.rowStamp = mp.stamp;
				this.fields = mp.fields || {};
				this.fieldsArray = mp.fieldsArray || [];
				this.fieldValidStates = mp.fieldValidStates || {};
				this.fieldValidErrors = mp.fieldValidErrors || {};
				this.rowValidState = mp.rowValidState;
				this.rowValidError = mp.rowValidError || '';
			},
		);
		ACTION_METHOD_NAMES.forEach(v => {
			this[v] = this.baseAction[v];
		});
	}

	unloadStore(dataMapping, { includeLoadedRows, includeDeletedRows }) {
		return this.dataStore.unload('std', dataMapping, {
			includeLoadedRows,
			includeDeletedRows,
		});
	}

	render() {
		return <this.ActionComponent />;
	}
}

class DSetProxy extends DSetAction_Base {
	constructor(state, disp, dsetPath, multiRow) {
		super(disp);
		const mapper = metaMapVarsToProps(dsetPath, multiRow);
		const mp = mapper(state);
		this.dataStore = state.store;
		this.dsetPath = dsetPath;
		this.fieldDefs = mp.fieldDefs;
		this.rowStamp = mp.stamp;
		this.fields = mp.fields || {};
		this.fieldsArray = mp.fieldsArray || [];
		this.fieldValidStates = mp.fieldValidStates || {};
		this.fieldValidErrors = mp.fieldValidErrors || {};
		this.rowValidState = mp.rowValidState;
		this.rowValidError = mp.rowValidError || '';
	}

	unloadStore(dataMapping, { includeLoadedRows, includeDeletedRows }) {
		return this.dataStore.unload('std', dataMapping, {
			includeLoadedRows,
			includeDeletedRows,
		});
	}
}

function useDSetContext(dsetContext, dsetPath, multiRow = false) {
	const { state, dispatch } = React.useContext(dsetContext);
	const actionObject = React.useMemo(
		() => new DSetAction_Base(dispatch, dsetPath),
		[dispatch, dsetPath],
	);
	const objectProxy = new DSetProxy(state, dispatch, dsetPath, multiRow);
	return [state, actionObject, objectProxy];
}

export {
	dsetCreateContext,
	dsetMetaProvider,
	dsetEmptyProvider,
	dsetMetaProviderEx,
	dsetMetaConnector,
	connect,
	connectComponents,
	DSetAction,
	DSetProxy,
	useDSetContext,
};
