import React from 'react'; //--DAFReact: development
// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
	//--DAFReact: development

	// (function () { //--DAFReact: deploy

	async function getImports(React, globals) {
		// checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
		const {
			_moduleId,
			StdAppAction,
			appAction,
			jsdset,
			dswidget,
			ndswidget,
			frameAction,
			staticComponents: {
				PageTitle,
				Button,
				FormLabel,
				Dropdown,
				AlertComponent,
				TransaksiTerjadwal,
			},
		} = globals;
		if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
			throw new Error(
				'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
			);
		}

		/* other required modules are asynchronously loaded here */
		const { metadata, initialData, serverDataMapping, editUIData } =
			await appAction.fetchAndExecModule(
				'manajemen_akun.histori_transaksi.historiTransaksiMetadata',
			);

		const { PanelDataDisplay, PanelButton } = dswidget;
		const { FieldDataDisplay, FieldDataInput } = ndswidget;

		function componentFactory(params) {
			function AppForm(props) {
				const dataContext = React.useMemo(
					() => jsdset.dsetCreateContext(),
					[],
				);
				const DSetProvider = React.useMemo(() => {
					return jsdset.dsetMetaProvider(
						dataContext,
						metadata,
						initialData,
						editUIData,
					);
				}, []);
				return (
					<DSetProvider>
						<AppFormUI dataContext={dataContext} {...props} />
						{/* any other props will be passed down */}
					</DSetProvider>
				);
			}

			function AppFormUI(props) {
				const [state, setState] = React.useState({
					isErr: false,
					errMessage: '',
					rekening: [],
					selectRekening: '',
					selectCommerce: '',
					terjadwalState: {},
				});

				// bind controls to _moduleId and _authToken
				const vComps = React.useMemo(
					() =>
						appAction.connect(
							{
								PanelDataDisplay,
								FieldDataDisplay,
								PanelButton,
								FieldDataInput,
							},
							{ _moduleId, _getToken: () => props._authToken },
						),
					[],
				);

				// bind controls to datasets
				const [mainComps] = React.useMemo(
					() => [
						jsdset.connect(
							{ context: props.dataContext, dsetPath: 'main' },
							vComps,
						),
					],
					[props.dataContext, vComps],
				);

				// obtain action objects from data context
				const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
					props.dataContext,
					'main',
				);

				// load data function
				const loadData = React.useCallback(async () => {
					var prevState = state;
					setState({
						...prevState,
						isErr: false,
						errMessage: '',
					});
					try {
						var response = await appAction.fetchResource(
							_moduleId,
							'single_data',
							'dataSO',
							props._authToken,
							{ id_transaction: params.id_transaction },
						);
						dsMainAction.loadStore(
							response,
							'std',
							serverDataMapping,
							true,
						);

						console.log(response);
					} catch (err) {
						setState({
							...prevState,
							isErr: true,
							errMessage: err.message,
						});
						return;
					}
				}, [dsMainAction, props._authToken, props.id_tx_draft]);

				// set event on component mounting
				React.useEffect(() => {
					(async function () {
						dsMainAction.addRow({});
						loadData();
					})();
				}, [dsMainAction, loadData, props.uiMode]);



				const AlertHandler = async (msg, type) => {
					await frameAction.showModal({
						contentClass: props => (
							<AlertComponent
								{...props}
								title={msg}
								type={type}
							/>
						),
						size: 'small',
					});
				};





				return (
					<div>
						<div style={{ display: 'flex' }}>
							<FormLabel label="No. Transaksi" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.id_transaction && dsMainProxy.fields.id_transaction !== '' ? <mainComps.FieldDataDisplay fieldName="id_transaction" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Tanggal Input" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.tx_date && dsMainProxy.fields.tx_date !== '' ? <mainComps.FieldDataDisplay fieldName="tx_date" /> : '-'}</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Dari Rekening" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.account_no && dsMainProxy.fields.account_no !== '' ? <mainComps.FieldDataDisplay fieldName="account_no" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Nama Rekening" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.account_name && dsMainProxy.fields.account_name !== '' ? <mainComps.FieldDataDisplay fieldName="account_name" /> : '-'}</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Ke Rekening" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.credit_account_no && dsMainProxy.fields.credit_account_no !== '' ? <mainComps.FieldDataDisplay fieldName="credit_account_no" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="Nama Rekening Tujuan" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.credit_account_name && dsMainProxy.fields.credit_account_name !== '' ? <mainComps.FieldDataDisplay fieldName="credit_account_name" /> : '-'}</b>
							</FormLabel>
						</div>

						<div style={{ display: 'flex' }}>
							<FormLabel label="Jumlah" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.amount && dsMainProxy.fields.amount !== '' ? <mainComps.FieldDataDisplay fieldName="amount" /> : '-'}</b>
							</FormLabel>
							<FormLabel label="No. Referensi" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.ref_number && dsMainProxy.fields.ref_number !== '' ? <mainComps.FieldDataDisplay fieldName="ref_number" /> : '-'}</b>
							</FormLabel>
						</div>
						<div style={{ display: 'flex' }}>
							<FormLabel label="Status Eksekusi" style={{ width: '100%' }}>
								<b>
									{dsMainProxy.fields.exec_status && dsMainProxy.fields.exec_status !== '' ? <mainComps.FieldDataDisplay fieldName="exec_status" /> : '-'}</b>
							</FormLabel>
						</div>
						<div style={{ display: state.isErr ? 'block' : 'none' }}>
							{state.errMessage}
						</div>
					</div>
				);
			}

			// return value may be different depending on params
			return React.memo(AppForm);
		}

		return { componentFactory };
	}

	async function initModuleF(aReact, globals) {
		return await getImports(aReact, globals);
	}

	return initModuleF;
	// })()  //--DAFReact: deploy
} //--DAFReact: development
