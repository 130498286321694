

import React from 'react'; //--DAFReact: development

// 'use strict'; //--DAFReact: deploy

export function ModuleDefinition() {
    //--DAFReact: development

    // (function () { //--DAFReact: deploy

    async function getImports(React, globals) {
        // checking required libraries, may also include asynchronously loading other remote module, using appAction.loadModule
        const {
            _moduleId,
            StdAppAction,
            appAction,
            jsdset,
            dswidget,
            ndswidget,
            frameAction,
            NDataBrowser,
            staticComponents: {
                Input,
                AlertComponent,
                Table,
                PageTitle,
                Button,
                FormLabel,
                Breadcrumbs,
                Upload,
                ReactIconFa: { FaSearch, FaCloudDownloadAlt, FaCloudUploadAlt, FaEllipsisV, FaInfoCircle },
                RadioGroup,
                Dropdown,
                trxAndProductCode: { trx_code, product_code, metode, }

            },
        } = globals;
        if (!_moduleId || !StdAppAction || !appAction || !jsdset || !dswidget) {
            throw new Error(
                'One of required components (_moduleId, StdAppAction, appAction, jsdset, dswidget) not found in globals',
            );
        }

        /* other required modules are asynchronously loaded here */
        const { metadata, initialData, serverDataMapping, editUIData } = await appAction.fetchAndExecModule('transaksi_massal.transaksi_massal.detailTransaksiMassalMetadata');

        const componentClass = await appAction.fetchAndExecModule(
            'transaksi_massal.transaksi_massal.detailTransaksiMassalKonfirmasi',
        );

        const { PanelDataDisplay, PanelButton } = dswidget;
        const { FieldDataInput, FieldDataDisplay } = ndswidget;
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)session_id\s*\=\s*([^;]*).*$)|^.*$/, "$1");


        function componentFactory(params) {
            function AppForm(props) {
                const dataContext = React.useMemo(
                    () => jsdset.dsetCreateContext(),
                    [],
                );
                const DSetProvider = React.useMemo(() => {
                    return jsdset.dsetMetaProvider(
                        dataContext,
                        metadata,
                        initialData,
                        editUIData,
                    );
                }, []);
                return (
                    <DSetProvider>
                        <AppFormUI dataContext={dataContext} {...props} />
                        {/* any other props will be passed down */}
                    </DSetProvider>
                );
            }

            function AppFormUI(props) {
                const [state, setState] = React.useState({
                    popupComponent: undefined,
                    isErr: false,
                    errMessage: '',
                    rekening: [],
                });

                const ref_primary_cif_no = React.useRef(null);

                // bind controls to _moduleId and _authToken
                const vComps = React.useMemo(
                    () =>
                        appAction.connect(
                            {
                                PanelDataDisplay,
                                FieldDataDisplay,
                                PanelButton,
                                FieldDataInput,
                            },
                            { _moduleId, _getToken: () => props._authToken },
                        ),
                    [],
                );

                // bind controls to datasets
                const [mainComps] = React.useMemo(
                    () => [
                        jsdset.connect(
                            { context: props.dataContext, dsetPath: 'main' },
                            vComps,
                        ),
                    ],
                    [props.dataContext, vComps],
                );

                // obtain action objects from data context
                const [, dsMainAction, dsMainProxy] = jsdset.useDSetContext(
                    props.dataContext,
                    'main',
                );

                // load data function
                const loadData = React.useCallback(async () => {
                    setState(state => ({
                        ...state,
                        isErr: false,
                        errMessage: '',
                    }));

                    try {

                        const response = await appAction.fetchResource(
                            _moduleId,
                            'single_data',
                            'dataSO',
                            props._authToken,
                            { id_batch_detail: props.id_batch_detail },
                            true,
                        );


                        dsMainAction.loadStore(
                            response,
                            'std',
                            serverDataMapping,
                            true,
                        );

                        dsMainAction.recalcFormulas();

                    } catch (err) {
                        setState(prevState => ({
                            ...prevState,
                            isErr: true,
                            errMessage: err.message,
                        }));
                        return;
                    }
                }, [dsMainAction, props._authToken, props.id_batch_detail]);

                // set event on component mounting
                React.useEffect(() => {
                    (async function () {

                        if (props.uiMode == 'edit') {
                            await loadData();
                        } else {
                            dsMainAction.addRow({ id_tx_draft: props.id_tx_draft });
                        }
                    })();
                }, [dsMainAction, loadData, props.uiMode]);

                const dsetProvider = React.useMemo(
                    () => jsdset.dsetEmptyProvider(),
                    [],
                );


                const AlertHandler = async (msg, type) => {
                    await frameAction.showModal({
                        contentClass: props => (
                            <AlertComponent
                                {...props}
                                title={msg}
                                type={type}
                            />
                        ),
                        size: 'small',
                    });

                }

                const handleConfirm = async () => {
                    try {

                        const { fieldValidStates, fieldValidErrors } =
                            dsMainProxy;
                        const isInValid = Object.entries(
                            fieldValidStates,
                        ).filter(([i, v]) => {
                            if (props.product_code === '425' && (i === 'kode_bank_tujuan' && v == false)) {
                                return v
                            } else {
                                return !v;
                            }
                        });

                        if (isInValid.length > 0)
                            throw new Error(fieldValidErrors[isInValid[0][0]]);

                        if (componentClass) {
                            var dataUnload = dsMainProxy.unloadStore(
                                serverDataMapping,
                                {
                                    includeLoadedRows: false,
                                    includeDeletedRows: true,
                                },
                            );

                            if (props.uiMode == 'edit') {
                                dataUnload.data[0]['__loadFlag'] = 'U'
                            } else {
                                dataUnload.data[0]['__loadFlag'] = 'N'
                            }
                            dataUnload.data[0]['tx_ref_number'] = props.tx_ref_number
                            dataUnload.session_id = cookieValue;

                            await appAction.frameAction.closeModal();
                            const modalshow = await frameAction.showModalAsync({
                                headerProps: {
                                    title: 'Konfirmasi Detail Transaksi Massal',
                                    icon: 'FaInfoCircle',
                                },
                                contentClass: componentClass,
                                contentProps: {
                                    _moduleId,
                                    _authToken: props._authToken,
                                    uiMode: props.uiMode,
                                    data: dataUnload,
                                    tx_code: props.tx_code,
                                    product_code: props.product_code,
                                    tx_ref_number: props.tx_ref_number,
                                    session_id: cookieValue
                                },
                                size: 'large',
                            });

                            // console.log(modalshow, 'responsemodal')

                            if (modalshow) {
                                // appAction.frameAction.closeModal();
                                dsMainAction.deleteRow();
                                dsMainAction.addRow({});
                            }
                        }


                        // var dataUnload = dsMainProxy.unloadStore(
                        //     serverDataMapping,
                        //     {
                        //         includeLoadedRows: false,
                        //         includeDeletedRows: true,
                        //     },
                        // );

                        // if (props.uiMode == 'edit') {
                        //     dataUnload.data[0]['__loadFlag'] = 'U'
                        // } else {
                        //     dataUnload.data[0]['__loadFlag'] = 'N'
                        // }
                        // dataUnload.data[0]['tx_ref_number'] = props.tx_ref_number
                        // dataUnload.session_id = cookieValue;

                        // const response = await appAction.fetchResource(
                        //     _moduleId,
                        //     'method',
                        //     'saveData',
                        //     props._authToken,
                        //     dataUnload,
                        //     true,
                        // );

                        // appAction.frameAction.closeModal();
                        // AlertHandler('Data berhasil disimpan', 'success')

                    } catch (err) {
                        AlertHandler(err.message, 'alert')
                    }
                };



                return (

                    <div >
                        <div>

                            {/* <FormLabel label="Rekening Sumber">
                                <mainComps.FieldDataInput fieldName="account_no" />
                            </FormLabel> */}

                            {props.product_code === '425' ? null :
                                <FormLabel label={"Kode Bank"}>
                                    <mainComps.FieldDataInput fieldName="kode_bank_tujuan" />
                                    {/* <Dropdown onChange={(e) => { dsMainAction.setField('kode_bank_tujuan', e.target.value); setState({ ...state, selectBank: e.target.value }); }} value={state.selectBank} item={state.bank} width='-webkit-fill-available' /> */}
                                </FormLabel>}
                            <FormLabel label={props.trx_code === 'BLP' ? "Nomor Tujuan /Nomor Pelanggan" : "Nomor Rekening Tujuan"}>
                                <mainComps.FieldDataInput fieldName="credit_account_no" />
                            </FormLabel>
                            <FormLabel label={props.trx_code === 'BLP' ? "Nominal" : "Nominal yang dikirim"}>
                                <mainComps.FieldDataInput fieldName="amount" />
                            </FormLabel>

                            <FormLabel label={props.trx_code === 'BLP' ? "Keterangan" : "Berita"}>
                                <mainComps.FieldDataInput fieldName="detail_description" />
                            </FormLabel>
                        </div>
                        <Button
                            onClick={() => {
                                handleConfirm()
                            }}
                            style={{
                                marginRight: 10,
                            }}>
                            Konfirmasi
                        </Button>
                        <div
                            style={{
                                display: state.isErr ? 'block' : 'none',
                            }}
                        >
                            {state.errMessage}
                        </div>
                    </div>

                );
            }

            // return value may be different depending on params
            return React.memo(AppForm);
        }

        return { componentFactory };
    }

    async function initModuleF(aReact, globals) {
        return await getImports(aReact, globals);
    }

    return initModuleF;
    // })()  //--DAFReact: deploy
} //--DAFReact: development